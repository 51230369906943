.site-footer--bottom-bar {
    margin: 3rem 0 1rem 0;
    border-top: rem-calc(1) solid #e0e0e0;
    @include centered-flex-row(space-between);

    //// On small viewport, make room below bottom footer for the back-to-top-button to sit in
    @include breakpoint(small only) {
        flex-direction: column;
        align-items: flex-start;
        height: auto;
        padding-bottom: rem-calc(70);
    }

    &--link {
        background-color: transparent;
        padding: 0;
        line-height: rem-calc(60);
        color: var(--color-grayscale-9) !important;
        margin-left: 2rem;
        white-space: nowrap;
    }

    &--left {
        @include centered-flex-row(flex-start, $children-display: false);
    }

    &--right {
        @include centered-flex-row(flex-end);
    }

    .site-footer--logo {
        transform: translateY(4px);
        display: block;
        margin-right: 2rem;
        @include sized-svg(185, 50);
    }

    @include breakpoint(medium down) {
        display: block;
        text-align: center;

        &--link {
            line-height: 1.9rem;
            margin: 0;
        }

        &--right {
            margin-top: rem-calc(16);
            display: flex;
            flex-wrap: wrap;
            -webkit-box-pack: center;
            justify-content: space-evenly;
            -webkit-box-align: center;
            align-items: center;
        }
    }
}
