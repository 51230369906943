.global-search .global-search--results {
    transform: translateY(10px);
    background-color: var(--color-white);
    border-radius: rem-calc(6);
    box-shadow: 0 1px 7px 0 rgba(black, 0.15);
    width: rem-calc(340) !important;
    overflow: hidden;

    @media screen and (max-height: 690px) {
        overflow: auto;
        max-height: 70vh;
    }

    @include breakpoint(small only) {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        width: 100vw !important;
        margin-left: rem-calc(-16);
    }

    @include z-index(middle-header-search-results);

    .search-hint {
        position: relative;
        display: block;
        user-select: none;
        background-color: white;
        color: var(--color-grayscale-9);
        transition: filter 100ms ease-out;
        border-top: rem-calc(1) solid var(--color-grayscale-3);

        &:hover {
            text-decoration: none;
            filter: brightness(92%);
        }
    }

    .search-hint-product {
        height: rem-calc(70);
        @include centered-flex-row(flex-start);

        img {
            @include square(40);
            margin: 1rem;
        }

        .search-hint-content {
            width: calc(100% - 3rem - #{rem-calc(40)});

            .product-name {
                @include ellipsis();
                font-family: var(--font-family-primary);
                font-size: rem-calc(15);
                line-height: rem-calc(22);
            }

            .price-display {
                justify-content: flex-start;
            }
        }

        .single, .compound-price .new, .compound-price .old {
            font-weight: var(--font-weight-light);
            font-family: var(--font-family-primary);
            font-size: rem-calc(15);
            line-height: rem-calc(22);
        }
    }

    .search-hint-other {
        padding: 1rem;

        // .search-hint-other--heading
        &--heading {
            font-weight: bold;
        }

        &--heading, &--description {
            font-family: var(--font-family-primary);
            font-size: rem-calc(15);
            line-height: rem-calc(22);
            @include ellipsis();
        }

        h2 {
            margin: 0;
            color: var(--color-orange);
        }

        p {
            padding-top: 0;
            margin: 0;
        }
    }

    .search-all-hint {
        display: block;
        width: 100%;

        @include button-reset(var(--color-grayscale-9)) {
            font-size: rem-calc(15);
            font-family: var(--font-family-primary);
            line-height: rem-calc(40);
            color: var(--color-white);
            transition: none;

            &:hover {
                background-color: var(--color-black);
            }
        }
    }
}
