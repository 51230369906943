* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    ::selection {
        background-color: var(--color-text-selection-background);
    }
}

body {
    margin: 0 auto;
    touch-action:pan-y;

    &.dark-background {
        background-color: var(--color-grayscale-1);
    }
}

#main {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 0;
}

input[type=submit],
button,
.ui-slider-handle,
a {
    outline: none;
    text-decoration: none;
}

ul, ul ul {
    margin: 0;
    list-style: none;
}

// Override Foundation's default modal styles
.reveal {
    outline: none;
    min-height: 0;
    height: fit-content;
    padding: 2rem;
    @include z-index(modal-content);
}

.reveal-overlay {
    background-color: var(--color-modal-mask);
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    @include z-index(modal-mask);
}

.hidden {
    display: none !important;
}

// Foundation Modal styling
[data-reveal] {
    // Reveal modals in general
    width: 100%;
    box-sizing: border-box;
    box-shadow: none;

    // Add 'margin' to the modals. Foundation sets horizontal margins of 'auto', so we need a workaround:
    border: 1rem solid transparent;
    background-clip: padding-box;

    .shadow-box {
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.15);
    }

    .modal-content {
        overflow: hidden;
    }
}


span.link-style {
    color: $cement;
    font-weight: bold;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

// Product delivery time: for use with php class 'DeliveryTimeHelper'
.delivery-time {

    // New
    &--grey {
        color: var(--color-grayscale-6);
    }

    &--red {
        color: var(--color-red);
    }

    &--green {
        color: var(--color-green);
    }

    &--grey,
    &--red,
    &--green,
    &--interval
    {
        font-weight: 600;
    }
}

// todo remove duplicate selector (duplicated by hidden, L42)
.hide {
    display: none !important;
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    clip: rect(0 0 0 0);
    border: 0;
    padding: 0;
    overflow: hidden;
}

.error {
    label {
        color: #dd484b;

        &.error {
            color: #dd484b;
        }
    }
}

.inner-wrap {
    @include breakpoint(medium down) {
        margin-top: rem-calc(150); // mobile header height: 30 (USPs) + 60 (main) + 60 (search)
        padding-top: 1rem;
    }
}

// 3D Viewer Colijn error message
.cdk-overlay-container {
    z-index: 9999 !important;
}

// Do not show the default marker of the <details> element
summary {
    list-style-type: none;

    &::-webkit-details-marker {
        display: none;
    }
}
