.zoomist {
    &-container {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: -1;
        touch-action: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    &-wrapper {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        background-color: var(--color-white);
    }

    &-image {
        pointer-events: none;

        img {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            height: 100%;
            object-fit: contain;
            object-position: center;
        }
    }

    &-slider {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.8);
        border-radius: 0 0 5px 0;

        &-main {
            position: relative;
            display: flex;
            justify-content: flex-start;

            &:hover .zoomist-slider-bar {
                background-color: var(--color-grayscale-5);
            }

            &:hover .zoomist-slider-button::before {
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
            }

            &.zoomist-slider-horizontal {
                align-items: flex-start;
                width: 150px;
                padding: 20px 0;
                margin: 0 20px;

                .zoomist-slider-bar {
                    top: calc(50% - 1px);
                    left: 0;
                    width: 100%;
                    height: 2px;
                }
            }

            &.zoomist-slider-vertical {
                align-items: flex-end;
                height: 150px;
                padding: 0 20px;
                margin: 20px 0;

                .zoomist-slider-bar {
                    top: 0;
                    left: calc(50% - 1px);
                    width: 2px;
                    height: 100%;
                }
            }
        }
    }

    &-slider-bar {
        display: block;
        position: absolute;
        z-index: 0;
        border-radius: 1px;
        background-color: var(--color-grayscale-3);
        transition: background-color .3s;
    }

    &-slider-button {
        display: block;
        position: relative;
        z-index: 1;
        width: 0 !important;
        height: 0 !important;

        &::before {
            position: absolute;
            display: block;
            content: '';
            left: -5px;
            top: -5px;
            width: 10px;
            height: 10px;
            background-color: var(--color-white);
            border-radius: 50%;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
            transition: box-shadow .3s;
        }
    }

    &-zoomer {
        position: absolute;
        z-index: 2;
        top: 72px;
        right: 24px;
        @include breakpoint(small only) {
            right: 0;
            top: 0;
        }
        border-radius: 0 0 0 5px;
        overflow: hidden;
    }

    &-zoomer-reset {
        display: none;
    }

    &-zoomer-in, &-zoomer-out {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        position: relative;
        background-color: rgba(255, 255, 255, 0.8) !important;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        box-shadow: inset 0 -2px 0 0 var(--color-grayscale-3);
        margin: 0.5333333333rem 0;
        transition: all 0.1s ease-out;
        width: 50px;
        height: 50px;
        cursor: pointer;
        padding: 0;

        @include breakpoint(small only) {
            width: 40px;
            height: 40px;
        }

        &:hover {
            border-color: var(--color-grayscale-6);
            box-shadow: inset 0 -2px 0 0 var(--color-grayscale-6);
            background-color: rgba(255, 255, 255, 0.8);
            text-decoration: none;
        }

        &:active {
            background-color: rgba(255, 255, 255, 0.8);
        }

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 12px;
            height: 12px;
            fill: var(--color-black);
        }
    }

    &-zoomer-disabled {
        pointer-events: none;
        opacity: .4;
    }
}

.zoomist-image {
    position: relative;
    height: 100%;
}

.product-carousel .show {
    opacity: 1;
    z-index: 3;
}
