.stateful-button {

    position: relative;

    &__spinner {
        @include absolute-overlay();
        display: none;

        svg {
            display: block;
            fill: white;
            width: rem-calc(24);
            height: rem-calc(24);
        }
    }

    &__content {
        @include centered-flex-row()
    }

    &.large &__content {
        @include sized-svg(24) {
            fill: white;
            &:first-child {
                margin-right: 0.5rem;
            }
            &:last-child {
                margin-left: 0.5rem;
            }
        }
    }

    &.loading {
        cursor: wait !important;

        .stateful-button__spinner {
            @include centered-flex-row();
        }

        .stateful-button__content {
            visibility: hidden;
        }
    }
}
