.pagination-container {

    margin: 2rem 0;

    &--center {
        @include centered-flex-row($children-display: false);
    }
}

// ul.pagination:
.pagination {
    user-select: none;
    margin: rem-calc(-3);
    display: flex;

    &.pagination--wrap {
        flex-wrap: wrap;
    }

    li {
        flex: none;
        @include square(30);
        margin: rem-calc(3);

        a, span {
            text-align: center;
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 4px;
            background-color: var(--color-grayscale-2);
            line-height: rem-calc(30);
            font-weight: 600;
            font-size: rem-calc(13);
            color: var(--color-grayscale-9);

            &:hover,
            &:focus {
                text-decoration: none;
            }
        }

        &.disabled span {
            cursor: not-allowed;
            opacity: 0.3;
        }

        &:first-child,
        &:last-child,
        &.active {
            a, span {
                background-color: var(--color-grayscale-9);
                border-color: transparent;
                font-size: rem-calc(14);
                font-weight: bold;
                color: white;
                transition: background-color ease-out 0.1s;
            }
        }

        &:first-child,
        &:last-child {
            a, span {
                line-height: rem-calc(26);
                font-size: 1.6rem;
            }
        }

        &:hover {
            &:not(.disabled) {
                a, span {
                    background-color: var(--color-grayscale-7);
                }
            }
        }

        &.display {
            white-space: nowrap;
            width: auto;
            min-width: rem-calc(80);
            text-align: center;
            font-size: 90%;
            font-weight: bold;
            padding: 5px 10px;
        }
    }
}
