$border-light-gray: solid 1px var(--color-grayscale-2);

.help-modal {
    &__content {
        display: none;

        &.active {
            display: block;
        }
    }

    &__title {
        margin: 0 0 1rem 0;
    }

    &__text {
        margin: 0;

        &--bold {
            font-weight: bold;
        }
    }

    &__sub-title {
        font-weight: bold;
        margin-bottom: .5rem;
    }

    &__body {
        padding-top: 2rem;
        margin-top: 2rem;
        border-top: $border-light-gray;
    }

    &__footer {
        padding-top: 2rem;
        margin-top: 2rem;
        border-top: $border-light-gray;
    }

    &__fabrics,
    &__attachment,
    &__folds,
    &__layout,
    &__rings,
    &__size {

        display: flex;
        flex-direction: column;
        row-gap: rem-calc(24);

        &__image {
            border: $border-light-gray;
            width: 160px;
            height: 160px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__row {
            display: grid;
            grid-template-columns: 160px 1fr;
            grid-column-gap: 2rem;
            grid-row-gap: 1.5rem;

            @include breakpoint(small only) {
                display: flex;
                flex-direction: column-reverse;
            }
        }

    }

    &__rings {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 1.75rem;
        grid-row-gap: 1.5rem;

        &__ring {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &__image {
            width: 128px;
            height: 128px;
            margin-bottom: .5rem;
        }
    }
}
