.plt {
    border-top: 1px solid var(--color-grayscale-2);
    border-bottom: 1px solid var(--color-grayscale-2);
    padding: 1rem 0;

    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    grid-column-gap: rem-calc(20);

    .stick-to-header & {
        @include breakpoint(small only) {
            position: fixed;
            width: 100%;
            top: var(--mobile-header-height);
            left: 0;
            background: white;
            height: auto;
            @include z-index(product-list-toolbar);
            grid-row-gap: 0;
            padding-left: 1.3333333333rem;
            padding-right: 1.3333333333rem;

            .plt--count {
                display: none;
            }
        }
    }

    &--count {
        white-space: nowrap;
        font-weight: 600;
    }

    &--filter-toggle {
        display: none;
    }

    &--sorting-form {
        @include centered-flex-row(flex-end);

        label {
            margin-right: rem-calc(10);
        }

        select {
            width: rem-calc(150);
        }
    }

    @include breakpoint(medium down) {
        grid-template-columns: 1fr 1fr auto 2fr;

        &--filter-toggle {
            display: block;
        }
    }

    @include breakpoint(small only) {
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
                'filter sorting'
                'counts counts ';
        grid-row-gap: rem-calc(20);
        border-top: none;

        &--count {
            text-align: center;
        }

        &--pagination {
            display: none;
        }

        &--filter-toggle {
            grid-area: filter;
        }

        &--sorting-form {
            grid-area: sorting;

            [data-select-container] {
                width: 100%;
            }

            label {
                display: none;
            }

            select {
                width: 100%;
            }
        }

        &--count {
            grid-area: counts;
        }

        &--header.plt--header {
            border-top: 1px solid var(--color-grayscale-2);
            background: white;
            padding: 0.8rem;
            grid-template-areas: 'filter sorting';
        }
    }
}
