.vacancies-index {
    p{
        line-height: rem-calc(28);
    }

    .vacancy {
        border: 1px solid #eaeaea;
        position: relative;
        // Row .collapse is very strict..
        margin-top: rem-calc(40) !important;
        margin-bottom: rem-calc(40) !important;

        .details {
            padding: rem-calc(48 40);
        }

        .subtitle {
            color: var(--color-grayscale-6);
            font-weight: 400;
            margin-bottom: rem-calc(4);
        }

        .title {
            font-size: rem-calc(26);
            font-weight: 400;
            margin-bottom: rem-calc(18);
        }

        .body {
            $font-size: 15px;
            $line-height: 1.73333;
            $lines-to-show: 6;
            display: block; /* Fallback for non-webkit */
            display: -webkit-box;
            height: $font-size*$line-height*$lines-to-show; /* Fallback for non-webkit */
            font-size: $font-size;
            line-height: $line-height;
            -webkit-line-clamp: $lines-to-show;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: rem-calc(14);
        }

        .cover {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;

            @include breakpoint(large) {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;

            }
        }

        a {
            color: var(--color-orange);
        }
    }

}

@media screen and (max-width: 684px){
    .vacancies-index{
        margin-top: rem-calc(15);

        .dropdown-sidebar-navigation{
            margin-bottom: rem-calc(20);
        }
    }
}
