@import '../../partials/interactive-image';
@import '../../modals/notify-me-modal';

.inspiration-detail {
    padding: rem-calc(0 0 40 0);

    .previous-icon {
        white-space: nowrap;

        &:before {
            content: '';
            display: inline-block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 5px 5px 5px 0;
            border-color: transparent var(--color-grayscale-6) transparent transparent;
            margin-right: rem-calc(10);
        }

        @include breakpoint(small only) {
            &.hide-for-medium-up {
                display: inline-block !important;
            }
        }

        @include breakpoint(medium up) {
            &.show-for-medium-up {
                display: inline-block !important;
            }
        }
    }

    .next-icon {
        &:after {
            content: '';
            display: inline-block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 5px 0 5px 5px;
            border-color: transparent transparent transparent var(--color-grayscale-6);
            margin-left: rem-calc(10);
        }
    }

    .navigation {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid var(--color-grayscale-2);
        padding: rem-calc(20 0);

        a {
            text-decoration: none;
        }

        ul {
            display: inline-flex;

            li {
                color: rgba(66, 66, 66, 1);
                padding: rem-calc(0 15);

                &:not(:first-of-type) {
                    border-left: 1px solid var(--color-grayscale-2);
                }

                a[disabled] {
                    color: rgba(66, 66, 66, .5);
                    cursor: not-allowed;

                    &:after,
                    &:before {
                        opacity: 0.5;
                    }

                    &:hover {
                        text-decoration: none;
                    }
                }

                @include breakpoint(small only) {
                    white-space: nowrap;
                }
            }
        }
    }

    .page-title {
        font-size: rem-calc(32);
        font-weight: 400;
        margin: rem-calc(0 0 30 0);
    }

    .header-image {
        width: 100%;
    }

    .social-buttons {
        span {
            position: relative;
            top: 5px;
            margin-right: 6px;
        }
    }
}

.inspiration-navigation {
    border-top: 1px solid var(--color-grayscale-3);
    padding: rem-calc(14 0);

    .back-button {
        float: left;
    }
}

.inspiration-arrow {
    path {
        fill: var(--color-grayscale-6);
    }

    &.inspiration-arrow-left {
        margin-right: 6px;
    }

    &.inspiration-arrow-right {
        margin-left: 6px;
    }
}

.inspiration-navigation-next-prev {
    float: right;
    display: flex;

    li {
        padding: 0 rem-calc(10);
        border-right: 1px solid var(--color-grayscale-3);

        &:last-child {
            border: 0;
        }
    }
}


.inspiration-content {
    margin: rem-calc(30) auto;
    padding: rem-calc(15);
}

.related-articles {
    margin-top: rem-calc(50);

    .related-title {
        text-align: center;
        margin-bottom: rem-calc(20);
    }
}

