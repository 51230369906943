.customer-service {

    &--title {
        text-align: center;
        margin: rem-calc(30 0);
        font-size: rem-calc(32);
    }

    &--heading {
        text-align: center;
        margin: rem-calc(30 0);
        font-size: rem-calc(26);
    }

    &--heading-small {
        font-size: rem-calc(22);
    }

    // ------------------------------------------------------------------------------
    //      Top section: Search & Top FAQs
    // ------------------------------------------------------------------------------

    &--top {
        background-color: var(--color-grayscale-1);
        border: 1px solid var(--color-grayscale-2);
        padding: rem-calc(40);

        display: flex;

        @include breakpoint(medium down) {
            display: block;
        }

        margin-bottom: 4rem;
    }

    &--search,
    &--top-faqs {
        width: 50%;
        flex: none;
        position: relative;
    }

    &--search {
        padding-right: rem-calc(40);
        border-right: 1px solid var(--color-grayscale-2);

        @include breakpoint(medium down) {
            width: 100%;
            padding-right: 0;
            border-right: none;
        }

        &--sticky {
            // JS?
        }

        & &--form {
            margin-top: 1rem;
            display: flex;

            input {
                flex: auto;
            }

            .button.medium {
                margin-left: 0.5rem;
                letter-spacing: normal;
                flex: none;
            }
        }
    }

    // ------------------------------------------------------------------------------
    //      Search results
    // ------------------------------------------------------------------------------

    &--search-results {

        .results-title {
            padding: 1rem 0;
            color: var(--color-grayscale-9);

            span {
                font-weight: bold;
            }
        }

        .search-result {
            border-top: 1px solid #EBEBEB;
            height: 50px;
            line-height: 50px;
            font-weight: bold;
            @include centered-flex-row(space-between);

            &--text {
                flex: none;
                @include ellipsis();
                width: calc(100% - 36px);
                margin-right: 20px;
            }

            &--icon {
                flex: none;
                @include sized-svg(16) {
                    fill: var(--color-grayscale-6);
                }
            }
        }
    }

    // ------------------------------------------------------------------------------
    //      Top 5 FAQ
    // ------------------------------------------------------------------------------

    &--top-faqs {
        margin-bottom: 0;
        padding-left: rem-calc(40);
        border-left: 1px solid var(--color-grayscale-2);
        margin-left: -1px;

        @include breakpoint(medium down) {
            width: 100%;
            padding-left: 0;
            margin-left: 0;
            border-left: none;
            margin-top: rem-calc(40);
            border-top: 1px solid var(--color-grayscale-2);
            padding-top: rem-calc(40);
        }

        &--sticky {
            // JS?
        }

        // Todo: cleanup (this is copied from old stylesheet)
        .accordion.questions {
            margin: 1rem 0 0;

            .accordion-navigation {
                padding: rem-calc(8 0);
                border-bottom: none;

                &:first-child {
                    border-top: none;
                }
            }
            .answer, .accordion .accordion-navigation > .content {
                padding-top: rem-calc(16);
            }
        }
    }

    // ------------------------------------------------------------------------------
    //      Categories: Blocks with detail links
    // ------------------------------------------------------------------------------

    &--categories-container {
        background-color: var(--color-grayscale-1);
        padding-bottom: 2rem;
    }

    &--categories {
        display: flex;
        flex-wrap: wrap;
        margin: rem-calc(-15);
    }

    &--category {
        width: 1/3 * 100%;
        flex: none;
        padding: rem-calc(15);

        @include breakpoint(medium down) {
            width: 50%;
        }

        @include breakpoint(small only) {
            width: 100%;
        }

        &--content {
            background-color: white;
            padding: 2rem;
            height: 100%;

            @include breakpoint(medium down) {
                padding: rem-calc(20);
            }
        }

        &--heading {
            user-select: none;
            font-size: rem-calc(22);
            margin-bottom: 2rem;

            @include centered-flex-row(flex-start, flex-start);
            @include sized-svg(32) {
                fill: var(--color-grayscale-7);
                margin-right: 1rem;

                @include breakpoint(medium down) {
                    margin-top: 4px;
                    @include square(24);
                }
            }
        }

        &--link {
            @include centered-flex-row(flex-start, flex-start);
            @include sized-svg(20) {
                flex: none;
                fill: var(--color-grayscale-6);
                margin: rem-calc(2 8 0 0);
                transition: transform .1s ease-out;
            }

            margin: rem-calc(5 0 5 4);
            position: relative; // Prevent margin-collapse
            transition: background-color .1s ease-out;

            &:hover {
                svg {
                    transform: translateX(3px);
                }
            }

            &:focus {
                text-decoration: none;
                background-color: var(--color-link-focus-background);
                svg {
                    transform: translateX(3px);
                }
            }
        }
    }

    // ------------------------------------------------------------------------------
    //      Contact options
    // ------------------------------------------------------------------------------

    &--contact {
        margin: rem-calc(12) 0 3rem;
    }

    &--contact-block-pairs {
        display: flex;
        margin: rem-calc(32 -12 16);

        @media (max-width: 1380px) {
            display: block;
        }
    }

    &--contact-block-pair {
        width: 50%;
        flex: none;

        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        @media (max-width: 1380px) {
            width: 100%;
        }

        .contact-block {
            flex: none;
            width: 50%;
            padding: rem-calc(12);

            @media (max-width: 1380px) {
                max-width: rem-calc(350);
            }

            @include breakpoint(small only) {
                width: 100%;
            }

            &--content {
                height: 100%;
                border: 1px solid var(--color-grayscale-3);
                border-bottom-width: 2px;
                padding: rem-calc(24);
                display: flex;

                &:hover, &:focus {
                    text-decoration: none;
                    background-color: var(--color-grayscale-0);
                }

                @include breakpoint(small only) {
                    padding: 1rem;
                }
            }

            &--svg {
                flex: none;
                @include square(24);
                @include sized-svg(24) {
                    fill: var(--color-grayscale-6);
                }
                margin-right: 1rem;
            }

            &--text {
                line-height: rem-calc(24);
                flex: auto;
            }

            &--main {
                font-size: rem-calc(17);
                font-weight: bold;
                color: var(--color-grayscale-9);

                @include breakpoint(medium down) {
                    font-size: rem-calc(15);
                }
            }

            &--sub {
                font-weight: 300;
                color: var(--color-grayscale-6);

                @include breakpoint(medium down) {
                    font-size: rem-calc(13);
                }
            }
        }
    }

    &--generic-message {
        margin-top: rem-calc(12);
        @include centered-flex-row;

        .generic-message {
            max-width: 680px;
        }
    }

}
