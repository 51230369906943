.webatelier-selection-slider {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: 1px;

    > .swiper-slide {
        width: auto;
        flex: none;
    }

    &__wrapper {
        height: 100%;
        flex: 1;
        overflow: hidden;
    }

    &__slide {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: rem-calc(30);
        grid-row-gap: rem-calc(20);
        flex: 0 0 auto;

        @media screen and (max-width: 1370px) {
            grid-template-columns: repeat(5, 1fr);
            grid-column-gap: rem-calc(20);
        }

        @include breakpoint(medium down) {
            grid-column-gap: rem-calc(10);
            grid-row-gap: rem-calc(16);
        }

        &__container {
            display: flex;
            flex-direction: column-reverse;
            justify-content: space-between;
            align-items: center;
            height: 100%;
            cursor: pointer;

            .info-button {
                svg {
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                    fill: var(--color-grayscale-6);
                }
            }

            @include breakpoint(medium down) {
                row-gap: rem-calc(10);
            }
        }
    }
}
