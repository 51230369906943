.return-service-index {
  .orange{
    color: var(--color-orange);
  }
}
.return-service__icon {
  svg {
    fill: $cement;
    height: rem-calc(38);
  }
}
.return-service__block {
  .return-service-content {
    font-size: rem-calc(15);
  }
}
