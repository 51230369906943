// Foundation by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

// ---------------------------------

// Table of Contents
// Foundation Settings

// a. Base
// b. Grid
// c. Global
// d. Breakpoints
// e. Typography
// 01. Breadcrumbs
// 02. Buttons
// 03. Dropdown
// 04. Forms
// 05. Reveal
// 06. Tabs

// Custom variables
// - - - - - - - - - - - - - - - - - - - - - - - - -
$normal-transition-speed: .1s;
$normal-transition-type: ease-out;


// a. Base
// - - - - - - - - - - - - - - - - - - - - - - - - -

// The default font-size is set to 100% of the browser style sheet (usually 16px)
// for compatibility with browser-based text zoom or user-set defaults.

// Since the typical default browser font-size is 16px, that makes the calculation for grid size.
// If you want your base font-size to be different and not have it affect the grid breakpoints,
// set $rem-base to $base-font-size and make sure $base-font-size is a px value.
$base-font-size: 15px; // 100%;

// This is the default html and body font-size for the base rem value.
$rem-base: 15px;

// We use this to control whether or not CSS classes come through in the gem files.
$include-html-classes: true;
$include-html-global-classes: $include-html-classes;


// b. Grid
// - - - - - - - - - - - - - - - - - - - - - - - - -

$row-width: rem-calc(1410);
$total-columns: 12;
$grid-column-gutter: rem-calc(40);
$global-right: rem-calc(1410);

// 1. Global
// ---------

$form-label-font-color: #fff;
$body-background: var(--color-white);
$body-font-color:  var(--color-black);
$body-safe-padding: false;
$body-antialiased: true;
$global-font-size: 95%;
$global-margin: 1rem;
$global-padding: 1rem;
$global-position: 1rem;
$global-radius: 0;
$global-menu-padding: 0.7rem 1rem;
$global-menu-nested-margin: 1rem;
$global-flexbox: false;
$global-prototype-breakpoints: false;
$global-button-cursor: auto;
$global-color-pick-contrast-tolerance: 0;
$print-transparent-backgrounds: true;
$print-hrefs: true;
$anchor-color-hover: var(--color-grayscale-9);
$anchor-color: var(--color-grayscale-9);

// c. Global
// - - - - - - - - - - - - - - - - - - - - - - - - -

// We use these to define default font stacks
$font-family-sans-serif: "Oxygen", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;

// We use these to define default font weights
$font-weight-normal: 400;
$font-weight-bold: 600;

// Images path
$images-path: "/images/";
$sprite-size: rem-calc(50);
$sprite-size1: rem-calc(100);
$sprite-size2: rem-calc(100);

// We use these as default colors throughout
$primary-color: var(--color-grayscale-9);

// We use these to control various global styles
$body-font-color: $primary-color;
$body-font-family: $font-family-sans-serif;
$header-font-family: $font-family-sans-serif;

// We use this to control font-smoothing
$font-smoothing: antialiased;


// d. Breakpoints
// - - - - - - - - - - - - - - - - - - - - - - - - -

$small-breakpoint: 640px;
$medium-breakpoint: 1024px;
$large-breakpoint: 1440px;
$xlarge-breakpoint: 1920px;

// e. Typography
// - - - - - - - - - - - - - - - - - - - - - - - - -

// We use these to control header font styles
$header-font-weight: 400;
$header-font-color: $primary-color;

// We use these to style paragraphs
$paragraph-font-size: rem-calc(15);

// We use these to style anchors
$anchor-text-decoration-hover: underline;
$anchor-font-color: $primary-color;
$anchor-font-color-hover: $primary-color; // scale-color($anchor-font-color, $lightness: -14%);

// We use these to style lists
$list-font-size: $base-font-size;


// 01. Breadcrumbs
// - - - - - - - - - - - - - - - - - - - - - - - - -

// We use this to set the background color for the breadcrumb container.
$crumb-bg: transparent; // scale-color($secondary-color, $lightness: 55%);

// We use these to set the padding around the breadcrumbs.
$crumb-padding: rem-calc(20 0); // rem-calc(9 14 9);

// We use these to control border styles.
$crumb-border-size: 0; // 1px;

// We use these to set various text styles for breadcrumbs.
$crumb-font-size: $base-font-size;
$crumb-font-color: $primary-color;
$crumb-font-transform: none; // uppercase;

// We use these to control the slash between breadcrumbs
$crumb-slash: " ";

$breadcrumbs-item-color: var(--color-grayscale-9);
$breadcrumbs-item-font-size: 1rem;
$breadcrumbs-item-uppercase: false;
$breadcrumbs-item-separator: false;

// 02. Buttons
// - - - - - - - - - - - - - - - - - - - - - - - - -

// We use these to build padding for buttons.
$button-tny: rem-calc(10);
$button-sml: rem-calc(13);
$button-med: rem-calc(15);
$button-lrg: rem-calc(17);
$button-background: #424242; // grayscale-8
$button-background-hover: #292929; // grayscale-9


// 03. Dropdown
// - - - - - - - - - - - - - - - - - - - - - - - - -

// Used for bottom position
$f-dropdown-margin-top: 10px; // 2px;

// We use these to style the triangle pip.
$f-dropdown-triangle-size: 10px; // 6px;
$f-dropdown-triangle-side-offset: 50%; // 10px;

// We use these to control styles for the list elements.
$f-dropdown-list-padding: rem-calc(8 20);
$f-dropdown-list-hover-bg: transparent; // $smoke;


// 04. Forms
// - - - - - - - - - - - - - - - - - - - - - - - - -

// We use these to style the labels in different ways
$input-font-family: inherit;
$input-font-color: var(--color-grayscale-7);
$input-font-size: 1rem;
$input-bg-color: var(--color-white);
$input-focus-bg-color: white;
$input-border-color: var(--color-grayscale-3);
$input-focus-border-color: $focus-border-color;
$input-border-style: solid;
$input-border-width: 1px;
$input-border-radius: 0;
$input-disabled-bg: var(--color-grayscale-1);
$input-disabled-cursor: not-allowed;
$input-box-shadow: none;
$input-include-glowing-effect: false;

// We use this to style the transition when inputs are focused and when the glowing effect is disabled.
$input-transition-fade-time: 0.1s;
$input-transition-fade-timing-function: ease-out;

// Select variables
$select-bg-color: white;
$select-hover-bg-color: scale-color($select-bg-color, $lightness: -3%);


// 05. Reveal
// - - - - - - - - - - - - - - - - - - - - - - - - -

// We use these to control the style of the modal itself.
$reveal-box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.15);

// We use this to set the default radius used throughout the core.
$reveal-radius: 0; //$global-radius;

// We use these to control the modal border
$reveal-border-width: 0; //1px;


// 06. Tabs
// - - - - - - - - - - - - - - - - - - - - - - - - -

$tabs-navigation-padding: 0;
$tabs-navigation-bg-color: transparent; // $silver;
$tabs-navigation-active-bg-color: var(--color-grayscale-2);
$tabs-navigation-font-color: $primary-color; //$jet;
$tabs-navigation-font-size: rem-calc(17);
