.wishlist-toggle {
    --wt-background: white;
    --wt-hover-background: #{var(--color-grayscale-1)};
    --wt-button-size: 40px;
    --wt-button-radius: 50%;
    --wt-svg-size: 20px;

    width: var(--wt-button-size);
    height: var(--wt-button-size);
    border-radius: var(--wt-button-radius);
    overflow: hidden;
    background-color: var(--wt-background);
    transition: background-color 100ms ease-out;

    &--button {
        @include button-reset;
        @include centered-flex-row;
        width: var(--wt-button-size);
        height: var(--wt-button-size);

        svg {
            display: block;
            width: var(--wt-svg-size);
            height: var(--wt-svg-size);

            &.svg-heart-outline {
                fill: var(--color-grayscale-8);
            }

            &.svg-heart {
                fill: var(--color-red);
            }
        }
    }

    &:hover {
        background-color: var(--wt-hover-background);
    }
}
