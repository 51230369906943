#at-home-index {
    .page-title {
        text-align: center;
        font-size: rem-calc(32);
        font-weight: 400;
        margin-top: rem-calc(30);

        &-secondary {
            font-size: rem-calc(17);
            margin-top: unset;
            margin-bottom: rem-calc(30);
            line-height: rem-calc(28);

            @include breakpoint(large) {
                margin: 0 auto rem-calc(30) auto;
                max-width: rem-calc(880);
            }

            span {
                font-weight: bold;
            }
        }
    }

    #flowbox__at-home {
        margin-bottom: rem-calc(40);
    }

    .flow__tags {
        display: grid;
        grid-template-columns: repeat(11, 1fr);
        overflow-x: auto;
        white-space: nowrap;
        padding-bottom: rem-calc(28);

        span {
            border-bottom: var(--color-grayscale-3) 1px solid;
            padding: rem-calc(8) rem-calc(16);
        }

        .tag {
            background-color: unset;
            border-bottom: var(--color-grayscale-3) 1px solid;
            color: var(--color-grayscale-9);
            font-size: rem-calc(15);
            padding: rem-calc(8) rem-calc(16);
            border-radius: 0;

            &.active {
                border-top-left-radius: rem-calc(4);
                border-top-right-radius: rem-calc(4);
            }

            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .active {
            border-bottom: unset;
            border-left: var(--color-grayscale-3) 1px solid;
            border-top: var(--color-grayscale-3) 1px solid;
            border-right: var(--color-grayscale-3) 1px solid;
        }
    }
}
