.contact-page {

    margin-bottom: 4rem;

    .stretch-row {
        display: flex;
        flex-wrap: wrap;

        .column,
        .columns
        {
            flex: none;
            margin-bottom: 1rem;
        }
    }

    &--title {
        margin: rem-calc(30 0);
        width: 100%;
        text-align: center;
    }

    // <img> element
    &--hero {
        margin-top: 2rem;
        display: block;
        width: 100%;
        height: rem-calc(260);
        background: no-repeat center / cover;
        position: relative;
    }

    &--content {
        margin-top: rem-calc(-30);
    }

    .content-block {
        // As an easy, lightweight css only solution:
        // This min-height will 'in most cases' align multiple content blocks nicely
        min-height: rem-calc(400);
        height: 100%;
    }
}
