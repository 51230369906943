.order-compact-link {
    display: block;

    &:hover {
        text-decoration: none;
        @include tooltip-box-shadow;
    }
}

.order-list {
    position: relative;

    .order-compact-link {
        margin-bottom: rem-calc(30);
    }
}

.order-refresh-notification {
    padding: rem-calc(12);
    background-color: white;
    margin-bottom: rem-calc(30);
    @include centered-flex-row(flex-start);
    @include sized-svg(20) {
        fill: var(--color-grayscale-9);
        margin-right: 1rem;
    }

    font-weight: 600;

    &:not(.active) {
        display: none;
    }
}
