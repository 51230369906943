.social-media {
    display: inline-flex;
    align-items: center;
    margin: -3px;

    .social-medium {
        @include square(32);
        @include centered-flex-row();
        padding: 0;
        margin: 3px;
        border-radius: 4px;
        border: 1px solid var(--color-grayscale-3);
        border-bottom-width: 2px;

        @include sized-svg(20) {
            fill: var(--color-grayscale-9);
            transform: translateY(1px);
        }

        &:hover {
            border-color: var(--color-grayscale-6);
        }
    }
}
