.gci { // .gift-card-index
    &--header {
        position: relative;
    }

    &--hero-image {
        display: block;
        width: 100%;
        height: rem-calc(320);
        filter: brightness(70%);
        object-fit: cover;
        object-position: center;

        @include breakpoint(small only) {
            display: none;
        }
    }

    &--hero-content {
        padding-top: 2rem;
        padding-bottom: 2rem;

        @include breakpoint(medium up) {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }

    &--title {
        text-align: center;
        max-width: rem-calc(675);
        margin: 0 auto rem-calc(8);

        @include breakpoint(medium up) {
            color: white;
        }
    }

    &--subtitle {
        text-align: center;
        font-size: rem-calc(17);
        max-width: rem-calc(675);
        margin: 0 auto rem-calc(30);

        @include breakpoint(medium up) {
            color: white;
        }
    }

    &--heading {
        font-size: rem-calc(26);

        @include breakpoint(small only) {
            font-size: rem-calc(22);
        }
    }

    &--order-blocks {
        position: relative;
        margin-top: rem-calc(-24);
        margin-bottom: rem-calc(32);

        @include breakpoint(medium up) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: rem-calc(30);
        }

        @include breakpoint(large) {
            margin-left: rem-calc(80);
            margin-right: rem-calc(80);
            margin-bottom: rem-calc(64);

        }
    }

    &--order-block {
        display: grid;
        grid-template-rows: 1fr auto;
        background-color: white;
        padding: rem-calc(40 30);

        &:first-child {
            @include breakpoint(small only) {
                padding-bottom: rem-calc(20);
                border-bottom: 1px solid var(--color-grayscale-2);
                margin-bottom: rem-calc(20);
            }
        }

        &--top {
            @include centered-flex-column(flex-start);
        }

        .gci--heading {
            margin-top: 1rem;
            text-align: center;
        }

        &--description {
            text-align: center;
            max-width: rem-calc(320);
        }

        &--bottom {
            @include centered-flex-row;
        }
    }

    &--gift-card-image {
        @include breakpoint(medium down) {
            width: 145px;
            height: 96px;
        }
    }


    &--balance-section {
        background-color: white;
        padding: rem-calc(64 0);


        &--content {
            background-color: var(--color-grayscale-1);
            border: 1px solid var(--color-grayscale-2);
            padding: rem-calc(24 16 24 16);


            @include breakpoint(large) {
                padding: rem-calc(40);
                display: grid;
                grid-template-columns: 1fr 1fr;
                margin-left: rem-calc(80);
                margin-right: rem-calc(80);
            }

        }

        &--balance-card {
            position: relative;

            img {
                @include breakpoint(large) {
                    width: 165px;
                    height: 104px;
                }
            }
        }

        &--current-balance {
            background-color: #ae9a64;
            @include square(48);
            border-radius: 50%;
            line-height: rem-calc(48);
            text-align: center;
            color: white;

            position: absolute;
            top: -24px;
            right: -24px;

            font-family: var(secondary-font-family);
            font-weight: 700;
            font-style: normal;
            font-size: 14px;
        }

        &--left {
            @include breakpoint(large) {
                padding-right: rem-calc(50);
                border-bottom: unset;
                margin-bottom: unset;
            }
            border-bottom: 1px solid #e0e0e0;
            margin-bottom: rem-calc(40);
        }

        &--right {
            @include breakpoint(large) {
                padding-left: rem-calc(40);
                border-left: 1px solid #e0e0e0;
            }

            display: flex;
            flex-direction: column;
            align-items: center;
            gap: rem-calc(25);
        }
    }

    &--balance-form {
        width: 100%;
        max-width: 380px;

        @include breakpoint(large) {
            margin: 0 auto;
            padding: 0;
        }

        &--heading {
            @include centered-flex-row(space-between);
            margin-bottom: 0.5rem;

            &--left {
                @include centered-flex-row();
            }

            .info-icon {
                margin-left: 0.5rem;
            }
        }

        &--reset {
            @include square(16);
            @include button-reset();
            @include sized-svg(16) {
                display: block;
                fill: var(--color-grayscale-6);
                transition: fill 150ms ease-out;
            }

            &:hover, &:focus {
                background-color: transparent;
            }

            &:hover svg {
                fill: var(--color-grayscale-9);
            }
        }

        &--inputs {
            display: grid;
            grid-template-columns: 1fr auto 1fr auto 1fr auto 1fr;
            grid-column-gap: rem-calc(5);
            margin-bottom: 1rem;

            .dash {
                line-height: rem-calc(40);
            }
        }

        input {
            text-align: center;
        }

        &--submit {
            width: 100%;
        }

        .grecaptcha-badge {
            display: none;
        }

        &--result {
            &--image {
                display: block;
                margin-top: 2rem;
                margin-bottom: rem-calc(20);
                width: rem-calc(68);
                height: rem-calc(96);
                object-fit: contain;
                object-position: center;
                box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.15);
            }
        }

        &--errors {
            position: relative;
            margin: 0 0 24px;
            padding: 1rem 1rem 1rem rem-calc(40);
            border-radius: 4px;
            border: solid 1px var(--color-grayscale-9);
            display: flex;
            flex-direction: column;
            align-items: center;

            &:not(.active) {
                display: none;
            }

            @include sized-svg(16) {
                fill: var(--color-grayscale-9);
                position: absolute;
                top: rem-calc(18);
                left: rem-calc(15);
            }

            &--main {
                color: var(--color-grayscale-9);
                font-weight: bold;
                line-height: 1.4;
                text-align: center;
            }

            &--sub {
                margin-top: rem-calc(5);
                color: var(--color-grayscale-9);
                font-size: rem-calc(13);
                line-height: 1.4;
                text-align: center;
            }

            svg {
                position: unset;
                margin-top: rem-calc(4);
                margin-bottom: rem-calc(8);
            }
        }
    }

    &--scroll-anchor {
        position: relative;
        bottom: 110px;
    }
}
