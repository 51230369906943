.customer-ratings {
    display: flex;
    text-align: center;
    justify-content: center;

    .title {
        font-size: rem-calc(32);
        font-weight: 400;

        span {
            font-weight: bold;
        }
    }

    &__cooperation {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: rem-calc(16);

        .review-cooperation {
            span {
                height: rem-calc(44);
                display: flex;
                align-items: flex-end;
                gap: rem-calc(8);
                white-space: nowrap;
                flex-wrap: wrap;
                justify-content: center;
            }

            img {
                height: rem-calc(32);
            }

            &--kiyoh {
                span {
                    gap: rem-calc(4);
                }

                img {
                    height: rem-calc(44);
                }
            }
        }

        @include breakpoint(medium up) {
            gap: rem-calc(4);
            flex-direction: row;
        }

        @media screen and (max-width: 320px) {
            gap: rem-calc(40px);
        }
    }

    .review {
        display: flex !important;
        flex-direction: column;
        align-items: center;
        padding: rem-calc(0 40);
        position: relative;

        .description {
            $descr-line-height: rem-calc(27);

            line-height: $descr-line-height;
            height: 2 * $descr-line-height;

            overflow: hidden;
            max-width: 100%;

            @include breakpoint(small only) {
                max-height: rem-calc(80);
                overflow: hidden;
            }
        }

        .title {
            font-size: rem-calc(17);
            font-weight: bold;
            margin: rem-calc(0 0 5 0);
        }

        .name {
            margin: 0;
        }

        .name,
        .location {
            $about-line-height: rem-calc(22);
            height: $about-line-height;
            line-height: $about-line-height;
            color: var(--color-grayscale-6);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
        }
    }

    .reviews-slider-wrap {
        display: flex;
        margin-top: rem-calc(20);
        position: relative;
        align-items: center;
        justify-content: space-between;

        #reviews-prev,
        #reviews-next {
            position: relative;
            flex-shrink: 0;
            padding: 0;
            font-size: 0;
            line-height: 0;
            width: 20px;
            height: 20px;
            cursor: pointer;
            margin: 0;
            border-top: 3px solid var(--color-grayscale-2);
            border-left: none;
            border-bottom: none;
            background-color: transparent;
            transition: border-color $normal-transition-speed $normal-transition-type;

            &:hover {
                border-color: var(--color-grayscale-6);
            }
        }

        #reviews-prev {
            border-left: 3px solid var(--color-grayscale-2);
            transform: rotate(-45deg);
            margin-right: 5px;
            left: 5px;
        }

        #reviews-next {
            border-right: 3px solid var(--color-grayscale-2);
            transform: rotate(45deg);
            margin-left: 5px;
            left: -5px;
        }
    }

    .reviews-slider {
        display: flex;
        width: calc(100% - 90px);

        .swiper-slide {
            outline: none;
            cursor: pointer;
        }

        .swiper-slide-active .thumb {
            border-color: $cement;

            &:before {
                opacity: 1;
            }
        }

        .swiper-wrapper {
            display: block;
            flex-grow: 1;
            width: 100%;
        }
    }
}