.auth-page {

    margin-bottom: 4rem;

    &--title {
        margin: rem-calc(30 0);
        width: 100%;
        text-align: center;
    }

    // <img> element
    &--hero {
        display: block;
        width: 100%;
        height: rem-calc(260);
        background: no-repeat center / cover;
        position: relative;

        @include breakpoint(small only) {
            height: rem-calc(160);
        }
    }

    &--content {

        @include breakpoint(large) {
            margin-top: rem-calc(-30);
        }
    }

    .content-block {

        margin: rem-calc(10 0);

        @include breakpoint(medium up) {

            // As an easy, lightweight css only solution:
            // This min-height will 'in most cases' align multiple content blocks nicely

            min-height: rem-calc(420);
        }
    }

    #loginForm .content-block--footer {

        @media screen and (max-width: 900px) {
            flex-direction: column-reverse;
            align-items: stretch;
        }
    }

    #forgot-password-link {
        @media screen and (max-width: 900px) {
            margin-top: 1rem;
        }
    }

    fieldset {
        @include breakpoint(medium down) {
            margin: 16px 0;
        }
    }

    label {
        font-size: 15px;
    }
}
