$border-gray: solid 1px var(--color-grayscale-3);
$border-light-gray: solid 1px var(--color-grayscale-2);

.square-image-preview {
    width: rem-calc(88) !important;
    height: rem-calc(88) !important;
    border-radius: rem-calc(4);
    border: 1px solid var(--color-grayscale-3);
    padding: rem-calc(4);
    background-color: var(--color-white);
    cursor: pointer;

    &--dark-border {
        border: solid 1px var(--color-grayscale-9);
    }

    &--disabled {
        cursor: initial;

        &:hover {
            box-shadow: unset;
        }
    }

    img {
        height: 100% !important;
        width: 100% !important;
        object-fit: cover;
    }
}

.order-indicator {
    width: rem-calc(24);
    height: rem-calc(24);
    line-height: rem-calc(24);
    background-color: var(--color-grayscale-9);
    border-radius: 50%;
    color: var(--color-white);
    text-align: center;
    font-size: rem-calc(12);
    font-weight: bold;

    @include breakpoint(medium down){
        width: rem-calc(28);
        height: rem-calc(28);
        line-height: rem-calc(28);
        font-size: rem-calc(14);
    }
}

.square-image-container {
    width: rem-calc(128);
    height: rem-calc(128);
    border: $border-light-gray;
    border-radius: rem-calc(8);
    @include centered-flex-row;
    @include breakpoint(medium down) {
        width: rem-calc(80);
        height: rem-calc(80);
    }

    &__image {
        display: block;
        width: rem-calc(75);
        height: rem-calc(75);
        transform: translate3d(0,0,0);

        @include breakpoint(medium down) {
            width: rem-calc(50);
            height: rem-calc(50);
        }
    }
}

.custom-knob-control.square-image-button {
    --sib-size: 160px;

    .custom-knob-control--input:checked + .custom-knob-control--label {
        box-shadow: inset 0 -1px 0 0 var(--color-grayscale-9);
        border: solid 1px var(--color-grayscale-9);
    }

    .custom-knob-control--label {
        width: var(--sib-size);
        height: var(--sib-size);
        border: $border-gray;
        border-radius: rem-calc(8);

        &::before, &::after {
            top: 23px;
            left: 12px;
            z-index: 1;
        }

        &:hover {
            background-color: white !important;
        }
    }

    @include breakpoint(medium down) {
        --sib-size: 120px;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;

    &--light-border {
        border: $border-light-gray;
    }

    &--small {
        width: rem-calc(128);
        height: rem-calc(128);

        @include breakpoint(medium down) {
            width: rem-calc(80);
            height: rem-calc(80);
        }
    }

    .square-image-button__image {
        width: rem-calc(96);
        height: rem-calc(96);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        margin-left: auto;
        margin-right: auto;

        @include breakpoint(medium down) {
            width: rem-calc(72);
            height: rem-calc(72);
        }

        &--small {
            width: rem-calc(75);
            height: rem-calc(75);

            @include breakpoint(medium down) {
                width: rem-calc(50);
                height: rem-calc(50);
            }
        }
    }

    .square-image-button__additional-price {
        position: absolute;
        top: -1px;
        right: -1px;
        color: var(--color-white);
        background-color: var(--color-green);
        border-radius: rem-calc(4 8 4 8);
        font-size: rem-calc(15);
        text-align: center;
        line-height: rem-calc(20);
        padding: rem-calc(2 12 2 12);
        font-weight: 600;
    }
}

.wa-price-display {
    .price-display {
        --pd-price-font-size: 32px;
        --pd-unit-font-size: 17px;
        --pd-flex-justify: flex-start;

        &--unit {
            font-weight: bold;
        }

        &--strike-through {
            --pd-price-font-size: 20px;
        }
    }

    .total-price-row {
        @include centered-flex-row(flex-start);

        .price-display {
            margin-right: 0.5rem;
        }

        table {
            width: 100%;
            border-collapse: collapse;

            th {
                text-align: left;
                padding-right: 5px;
            }

            td {
                text-align: right;
                padding-left: 5px;
            }

            tr.space-bottom-large {
                th, td {
                    padding-bottom: 10px;
                }
            }

            tr.space-bottom {
                th, td {
                    padding-bottom: 5px;
                }
            }

            tr.space-top {
                th, td {
                    padding-top: 5px;
                }
            }

            tr.border-top {
                th, td {
                    border-top: 1px solid rgba(white, 0.5);
                }
            }
        }
    }

    &--delivery-time {
        margin-top: 5px;
        font-weight: bold;
        font-size: 13px;
    }

    &--description {
        margin-top: 24px;
        position: relative;
        padding: 16px 24px;
        font-size: 13px;
        border: 1px solid var(--color-grayscale-2);

        @include absolute-block-pseudo(before) {
            width: 16px;
            height: 16px;
            background-color: white;
            border-top: 1px solid var(--color-grayscale-2);
            border-left: 1px solid var(--color-grayscale-2);
            transform: rotate(45deg);
            top: -8px;
            left: 20px;
        }
    }
}
