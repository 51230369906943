.site-header {
    &--checkout {
        @include centered-flex-row();
        text-align: center;

        img {
            width: 80%;
            max-width: 280px;
            margin-top: 20px;
            margin-bottom: -20px;
        }

        @include breakpoint(medium up) {
            img {
                width: 95%;
                max-width: 350px;
            }
        }
    }

    @media(min-width: 1025px) {
        margin-bottom: 2rem;
    }
}

:root {
    --header-top-height: #{rem-calc(40)};
    --header-middle-height: #{rem-calc(100)};
    --header-bottom-height: #{rem-calc(40)};
    --header-sticky-height: #{rem-calc(60)};
    --header-sticky-transition: transform .2s ease-out;
    --header-height: calc(var(--header-top-height) + var(--header-middle-height) + var(--header-bottom-height));
}


@import "top";
@import "middle";
@import "desktop-navigation";
@import "mobile-menu";
@import "mobile-header";
@import "global-search";
@import "search-hint";
@import "header-notification";
@import "pull-downs/cart-pull-down";
@import "pull-downs/user-pull-down";
@import "category-list";
