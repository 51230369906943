.buyable-quantity-select {
    width: rem-calc(60);
    margin: 0;
    flex: none;
    font-weight: 400;

    &.large {
        @include breakpoint(medium up) {
            font-size: rem-calc(17);
            width: rem-calc(96);
            height: rem-calc(48);
        }
    }
}
