// <table> element
.expenses-table {

    $cell-border-spacing: 1rem;
    $cell-height: rem-calc(30);
    border-collapse: collapse;
    width: 100%;

    &--separator {
        margin-top: 1rem;
        padding-top: 1rem;
        border-top: rem-calc(1) solid var(--color-grayscale-2);
        width: 100%;
        flex: none;
    }

    &--row {
        @include centered-flex-row(space-between);
    }

    &--heading {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
    }

    &--data {
        flex: none;
        font-weight: bold;
    }

    // Style modifiers

    &--heading,
    &--data
    {
        line-height: $cell-height;

        &.bold { font-weight: bold }
        &.soft { color: var(--color-grayscale-6) }
        &.green { color: var(--color-green) }
        &.orange { color: var(--color-orange) }
        &.indent { padding-left: 1rem }

        &.strike-through {

            color: var(--color-grayscale-6);

            @include absolute-block-pseudo('after') {
                top: 50%;
                left: -0.5rem;
                width: calc(100% + 1rem);
                height: rem-calc(1);
                transform: rotate(-7deg);
                background-color: var(--color-grayscale-6);
            }
        }
    }

    .code {
        display: inline-block;
        white-space: nowrap;
        font-weight: bold;
        font-size: rem-calc(12);
        line-height: rem-calc(20);
        padding: 0 0.5rem;
        color: var(--color-grayscale-6);
        border: rem-calc(1) solid var(--color-grayscale-6);
        border-radius: rem-calc(2);
        margin-left: 0.5rem;
    }

    &--warning {
        @include centered-flex-row(flex-start, flex-start);
        margin: 2rem 0;
        max-width: rem-calc(350);
        line-height: rem-calc(22);
        font-size: rem-calc(13);
        font-weight: normal;
        color: var(--color-grayscale-7);

        svg {
            display: block;
            fill: var(--color-orange);
            width: rem-calc(20);
            height: rem-calc(20);
            margin: rem-calc(1 15 1 0);
            flex: none;
        }
    }
}
