// Before slick is activated
.slider:not(.slick-initialized) {
    .slide {
        display: none;

        &:first-of-type {
            display: inherit;
        }
    }
}
