.inspirations-wrapper {
    display: flex;
    flex-flow: row wrap;
}

#inspiration-index,
.inspiration-detail {
    padding: rem-calc(0 0 40);

    .page-title {
        font-size: rem-calc(32);
        font-weight: 400;
        margin: rem-calc(0 0 30 0);
    }

    .info {
        font-size: rem-calc(20);
        text-align: center;
    }
}

#inspiration-index {
    .page-title {
        margin: 2rem 0;
        text-align: center;
    }

    .row {
        &:nth-of-type(2) {
            .column {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
                gap: 40px 30px;
                justify-items: center;

                @include breakpoint(medium down) {
                    gap: 24px;
                }

                @include breakpoint(medium down) {
                    grid-template-columns: repeat(auto-fill, minmax(288px, 1fr));
                }

                div.item {
                    width: 100%;
                    transition: .1s ease-in-out all;
                    max-width: 440px;

                    @include breakpoint(medium down) {
                        max-width: 400px;
                    }

                    &:hover {
                        box-shadow: 0 0 16px -2px #00000033;
                    }

                    a {
                        text-decoration: none;

                        .meta {
                            height: 152px;
                            padding: 32px 40px;
                            background-color: var(--color-white);

                            @include breakpoint(medium down) {
                                height: 112px;
                                padding: 16px 24px;
                            }

                            p {
                                margin: 0;

                                &:nth-of-type(1) {
                                    font-size: clamp(18px, 1.5vw, 22px);
                                    margin-bottom: 8px;
                                    line-height: 1.27;
                                }

                                &:nth-of-type(2) {
                                    font-size: rem-calc(15);
                                    text-transform: lowercase;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
