.shopping-list-grid {

    $product-spacing: rem-calc(20);
    $image-container-size: rem-calc(60);

    @include list-reset;
    @include centered-flex-row(flex-start, stretch, $wrap: wrap);

    margin: rem-calc(-10);

    &--item {

        margin: rem-calc(10);

        display: block;
        height: 100%;

        flex: none;
        border: 1px solid transparent;
        width: rem-calc(180);
        padding: rem-calc(10);

        transition: border-color .1s ease-out;

        .item-image {
            width: rem-calc(160);
            height: rem-calc(160);
            position: relative;

            img {
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 100%;
            }
        }

        .item-primary-name,
        .item-secondary-name
        {
            display: block;
            text-align: center;
        }

        .item-primary-name {
            font-weight: bold;
        }

        .item-secondary-name {
            font-size: rem-calc(13);
            color: var(--color-grayscale-6);
        }

        &:hover,
        &:focus {
            text-decoration: none;
            border-color: var(--color-grayscale-3);
        }
    }
}
