.gcs { // .gift-card-status

    @include centered-flex-column();

    margin-bottom: 1rem;

    &--image {
        display: block;
        margin-bottom: rem-calc(20);
        width: rem-calc(68);
        height: rem-calc(96);
        object-fit: contain;
        object-position: center;
        box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.15);
    }

    &--balance {
        font-size: rem-calc(17);
        font-weight: bold;
    }

    &--initial-value {
        text-align: center;
        color: #838383;
    }

    &--transaction-history-link {
        user-select: none;
        cursor: pointer;

        @include centered-flex-row(flex-start, flex-start);
        @include sized-svg(20) {
            flex: none;
            fill: var(--color-grayscale-6);
            margin: rem-calc(2 8 0 0);
            transition: transform .1s ease-out;
        }

        margin: rem-calc(5 0 5 4);
        position: relative; // Prevent margin-collapse
        transition: background-color .1s ease-out;

        &:hover {
            svg {
                transform: translateX(3px);
            }
        }

        &:focus {
            text-decoration: none;
            background-color: var(--color-link-focus-background);
            svg {
                transform: translateX(3px);
            }
        }
    }
}
