$search-height: rem-calc(40);

*:focus {
    outline: none;
}

.global-search-container .global-search {
    position: relative;
    display: flex;

    #fake-focus {
        position: absolute;
        left: 2.5rem;
        top: 0;
        height: 100%;
        border: none;
        background: transparent;
        display: none;
        box-shadow: none;

        @include breakpoint(small only) {
            display: block;
        }

        &:focus {
            outline: none;
        }
    }

    @include absolute-block-pseudo(after) {
        top: 0;
        right: 65px;
        width: $search-height;
        height: $search-height;
        background: url('/images/loading.svg') center/24px 24px no-repeat;
        opacity: 0;
    }

    &.loading::after {
        opacity: 1;
    }

    &--field {
        border-radius: 0.5 * $search-height;
        background-color: white;
        width: rem-calc(442);
        border: 1px solid var(--color-grayscale-3);
        height: $search-height;
        font-size: rem-calc(15);
        font-weight: 400;
        box-shadow: none;
        transition: none;
        padding: 0 rem-calc(20);
        @include placeholder-color(var(--color-grayscale-6));

        &.small {
            width: rem-calc(320);
        }

        &:focus {
            background-color: white;
            border-color: var(--color-grayscale-6);
            box-shadow: 0 0 0 3px var(--color-grayscale-2);
        }
    }

    &--results {
        position: absolute;
        top: 100%;
        left: 0;
        background-color: white;
        border-radius: rem-calc(6);
        box-shadow: 0 1px 7px 0 rgba(black, 0.15);
        width: rem-calc(340) !important;
        overflow: hidden;
        @include z-index(middle-header-search-results);
        display: none;
    }

    &--submit {
        @include centered-flex-row;
        // 2px margin on all sides makes square 40.
        @include square(36);
        @include sized-svg(16) {
            fill: var(--color-grayscale-9);
        }
        // Stay clear from the input border:
        @include button-reset {
            margin: 2px;
        }

        border-radius: 50%;

        // Overlay the input field:
        position: absolute;
        top: 0;
        right: 0;

        &:hover,
        &:focus {
            background-color: var(--color-grayscale-2);
            svg {
                fill: var(--color-grayscale-9);
            }
        }
    }

    &.dropdown-active .global-search--results {
        display: block;
    }

    &--clear {
        @include centered-flex-row;
        // 2px margin on all sides makes square 40.
        @include square(24);
        @include sized-svg(12) {
            fill: var(--color-grayscale-9);
        }
        // Stay clear from the input border:
        @include button-reset {
            margin: 2px;
        }

        border-radius: 50%;

        // Overlay the input field:
        position: absolute;
        top: 6px;
        right: 36px;

        &:hover,
        &:focus {
            background-color: var(--color-grayscale-2);
            svg {
                fill: var(--color-grayscale-9);
            }
        }

        @include breakpoint(large) {
            display: none;
        }
    }
}
