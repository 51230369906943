.mobile-menu {
    // ------------------------------------------------------------------------------
    //      Header buttons
    // ------------------------------------------------------------------------------

    &--prev,
    &--close {
        @include square(24);
        @include centered-flex-row;
        @include sized-svg(16) {
            transition: fill 100ms ease-out;
            fill: var(--color-grayscale-9);
            will-change: fill;
        }

        @include button-reset {
            &:hover,
            &:focus {
                background-color: transparent;

                svg {
                    fill: var(--color-grayscale-9);
                }
            }
        }
    }

    &--prev {
        transition: opacity 150ms ease-out;
        will-change: opacity;

        &:not(.active) {
            opacity: 0;
            pointer-events: none;
        }
    }

    // ------------------------------------------------------------------------------
    //      Menu items
    // ------------------------------------------------------------------------------

    &--item {
        @include centered-flex-row(space-between);
        background: var(--color-white);

        @media(min-width: 1025px) {
            background: transparent;
        }

        @media(max-width: 1024px) {
            height: rem-calc(50);
            line-height: rem-calc(50);

            &:hover,
            &:focus {
                text-decoration: none;
                background-color: var(--color-grayscale-0);
            }
        }

        &--content {
            @media(max-width: 1024px) {
                @include centered-flex-row;
                justify-content: flex-start;
                padding-left: rem-calc(20);
            }

            .icon-container {
                --color: var(--color-grayscale-9);

                @include square(24);
                @include centered-flex-row;
                margin-right: 1rem;

                @include sized-svg(24) {
                    fill: var(--color);
                }
            }
        }

        &--arrow {
            @media(max-width: 1024px) {
                padding-right: rem-calc(20);
            }

            @include sized-svg(11) {
                fill: var(--color-grayscale-9);
            }
        }
    }
}

@media(max-width: 1024px) {

    .custom-desktop-heading {
        display: none;
    }

    .sub-category-heading {
        display: flex;
    }

    // ------------------------------------------------------------------------------
    //      Show and hiding mobile menu
    // ------------------------------------------------------------------------------

    html:has(#toggle-mobile-menu:checked) {
        overflow: hidden;

        .nav-mask-mobile {
            opacity: 1;
            pointer-events: auto;
            visibility: visible;
        }

        .site-header--bottom--content {
            transform: translateX(0%);
        }
    }

    .nav-mask-mobile {
        position: fixed;
        top: 0;
        z-index: 9999;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, .25);
        transition: opacity 0.3s ease-in-out,
                    visibility 0.3s ease-in-out;
        will-change: opacity, visibility;
        opacity: 0;
        pointer-events: none;
        visibility: hidden;

        &.active {
            opacity: 1;
            pointer-events: auto;
            visibility: visible;
        }
    }

    .site-header--bottom--content {
        transform: translateX(-100%);
        transition: transform ease-in-out .3s;
        will-change: transform;

        &.active {
            transform: translateX(0%);
        }
    }

    // ------------------------------------------------------------------------------
    //      Mobile menu styling and functioning
    // ------------------------------------------------------------------------------

    #nav-root {
        position: relative;
        margin-top: 1rem;

        .mobile-heading,
        > li a {
            font-weight: var(--font-weight-bold);
        }
    }

    nav.site-header--bottom--content {
        position: fixed;
        inset: 0;
        z-index: 9999;
        width: 80vw;
        height: 100%;
        overflow-x: hidden;
        background-color: var(--color-grayscale-1);

        li.mobile-heading {
            font-size: rem-calc(22);
            color: var(--color-grayscale-9);
            padding: 24px rem-calc(20) 1rem;
            background: var(--color-white);
            border-bottom: rem-calc(1) solid var(--color-grayscale-2);
        }

        // Sub-menu styling
        > ul ul:not(.mobile-menu-secondary) {
            position: absolute;
            inset: 0;
            margin: 0;
            transform: translateX(100%);
            opacity: 0;
            visibility: hidden;
            height: 100vh;
            overflow-y: unset;
            background-color: var(--color-grayscale-1);

            // Sub-menu line
            &::before {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 1px;
                height: 100%;
            }
        }

        // Behaviour of navigating (css only)
        &.no-js {
            > ul ul:not(.mobile-menu-secondary) {
                &:target,
                &:has(:target) {
                    transform: translateX(0%);
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

        .mobile-menu-secondary {
            margin-top: 1rem;
        }

        #nav-root,
        #nav-root ul {
            transition: transform 0.3s ease-in-out,
                        opacity 0.3s ease-in-out,
                        visibility 0.3s ease-in-out;
            will-change: transform, opacity, visibility;

            &.active,
            &.shifted {
                opacity: 1;
                visibility: visible;
            }

            &.active {
                transform: translateX(100%);
            }

            &.shifted {
                transform: translateX(0%);
            }

            &.scrollable {
                overflow-x: hidden;
                overflow-y: auto;
                padding-bottom: 40vh;
            }
        }

        #nav-root {
            transition: transform .3s ease-in-out;
            will-change: transform;

            &.shifted {
                transform: translateX(-100%) !important;
            }
        }

        li:not(:last-of-type) a {
            border-bottom: rem-calc(1) solid var(--color-grayscale-2);
        }
    }

    .mobile-menu {
        &--top,
        &--account-link {
            position: relative;
            z-index: 25;
            background-color: var(--color-white);
        }

        &--top {
            @include centered-flex-row(space-between);
            padding: rem-calc(18);
        }

        &--account-link {
            @include centered-flex-row;
            position: absolute;
            inset: 0;
            top: auto;
            line-height: rem-calc(60);
            padding-bottom: env(safe-area-inset-bottom);
            box-shadow: rem-calc(0 0 8 0) rgba(black, 0.15);
            font-weight: var(--font-weight-bold);

            @include sized-svg(24) {
                fill: var(--color-grayscale-9);
                margin-right: rem-calc(10);
            }
        }
    }
}
