.content-block {

    $large-block-padding: 2rem;
    $small-block-padding: 1rem;

    background-color: white;
    position: relative;

    @include centered-flex-column(space-between, stretch);

    &--title {
        font-size: rem-calc(26);
        line-height: rem-calc(36);
        padding-bottom: 1rem;
        border-bottom: rem-calc(1) solid var(--color-grayscale-2);
        margin-top: 0;
        margin-bottom: 1rem;
    }

    &--content {
        flex: 1 0 auto;
    }

    &--footer {
        flex: none;
        background-color: var(--color-grayscale-2);

        &--flex-start {
            @include centered-flex-row(flex-start, $wrap: wrap, $children-display: false);
        }

        &--flex-end {
            @include centered-flex-row(flex-end, $wrap: wrap, $children-display: false);
        }

        &--space-between {
            @include centered-flex-row(space-between, $wrap: wrap, $children-display: false);
        }
    }

    // ------------------------------------------------------------------------------
    //      Sizes
    // ------------------------------------------------------------------------------

    &--small {
        .content-block--content,
        .content-block--footer {
            padding: 1rem;
        }
    }

    &--large {
        .content-block--content {
            padding: 2rem;
        }

        .content-block--footer {
            padding: 1rem 2rem;
        }

        @include breakpoint(small only) {
            .content-block--content,
            .content-block--footer {
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }
    }
}
