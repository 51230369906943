.fabric {

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 20px;
    }

    &__title {
        margin-bottom: rem-calc(24);
    }

    &__cta-title {
        margin: rem-calc(32 0 40 0);
        text-align: center;
    }

    > .product-list {
        margin: rem-calc(0 0 32 0);
    }
}

@include breakpoint(large) {
    .fabric__cta-title {
        margin: rem-calc(32 0 0 0);
    }
}
