.order-compact {
    padding: rem-calc(12);
    background-color: white;

    @include centered-flex-row(space-between, flex-start, $wrap: wrap);

    &--heading {
        font-weight: 600;
    }

    &--info,
    &--status {
        span {
            display: block;
        }

        padding: rem-calc(12);
    }

    &--transfer-info {
        font-size: .9rem;
        margin-top: .5rem;
        color: var(--color-grayscale-7);
    }

    &--status {
        @include centered-flex-row;
        @include sized-svg(20) {
            flex: none;
            fill: var(--color-emphasis-blue);
            margin-right: 1rem;
        }
    }
}
