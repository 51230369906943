.order-manifest-table {
    --background-color: white;
    --cell-color: #{var(--color-grayscale-1)};
    --cell-hover-color: #{var(--color-grayscale-2)};
    --cell-spacing: 6px;
    --image-size: 50px;

    background-color: var(--background-color);
    overflow: scroll;
    display: grid;
    grid-gap: var(--cell-spacing);
    grid-template-columns: 1fr repeat(3, 90px);

    .article-cell {
        min-width: 165px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: rem-calc(10);

        .review-link {
            text-decoration: underline;
        }
    }
}

.omt {

    &--headings {
        font-weight: 600;
    }

    &--headings,
    &--entry,
    &--footer {
        display: contents;
        grid-column-gap: var(--cell-spacing);
        margin-bottom: var(--cell-spacing);
    }

    &--cell {
        background-color: var(--cell-color);
        padding: rem-calc(5 10);
        border-radius: 4px;
        vertical-align: middle;
        font-size: rem-calc(13);
        @include centered-flex-row;

        &--text {
            width: 100%;

            &.green { color: var(--color-green) }
            &.grey { color: var(--color-grayscale-6) }
        }

        &.align-right {
            text-align: right;
        }

        &.bold {
            font-weight: 600;
        }

        &.empty {
            opacity: 0;
        }

        &.border-top {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-top: 2px solid var(--color-grayscale-9);
        }
    }

    &--image-description {
        width: 100%;
        @include centered-flex-row(flex-start);
    }

    &--image {
        flex: none;
        filter: brightness(96%);
        @include square(50);
        @include centered-contain-background;
        margin-right: 1rem;
    }

    &--description {
        flex: auto;
    }
}

a.omt--entry {
    border-radius: 4px;

    .omt--cell {
        transition: background-color 100ms ease-out;
    }

    .omt--image {
        transition: filter 100ms ease-out;
    }

    &:hover, &:focus {
        text-decoration: none;

        .omt--image {
            filter: brightness(92%);
        }

        .omt--cell {
            background-color: var(--cell-hover-color);
        }
    }
}

