$rem-base: 15px;

@import 'foundation-sites/scss/foundation';

// ------------------------------------------------------------------------------
//      Colors
// ------------------------------------------------------------------------------

$cement: #58626c;
$cement-dark: darken($cement, 5%);
$green: #30a980;
$orange: #ffa200;
$red: #f74243;

$focus-border-color: #1c8299;
$focus-fill-color: #cce2e6;

// ------------------------------------------------------------------------------
//      Z-indices map
// ------------------------------------------------------------------------------

$z-indices: (

    // Modals are positioned on top
    modal:                        5000,
    modal-content:                5000,
    modal-mask:                   4900,

    middle-header-search-results: 4850,
    shopping-list-notification:   5001,

    // Middle header navigation with pull-down overlays
    middle-header-pull-down-link: 4820,
    middle-header-pull-down-mask: 4810,
    middle-header-pull-down:      4800,

    // Top header is positioned below the middle header
    // to improve middle-header sticky transitions.
    top-header:                   4700,


    sticky-elements:              4000,

    // Bottom header drop-downs appear below any sticky elements,
    // including the middle header segment
    bottom-header-dropdown:       3500,

    payment-widgets-separator:    1001,
    payment-widgets:              1000,

    product-card-hover:           110,
    scroll-up-button:             111,

    product-list-toolbar:         105,

    product-card:                 100,

    // DEPRECATED:
    // Catalog product boxes expand on hover. these indices
    // make sure the hovered box is shown on top.
    catalog-product-box-hover:     100,
    catalog-product-box:            90,
);

// ------------------------------------------------------------------------------
//      Checkout inputs
// ------------------------------------------------------------------------------

$input-height: rem-calc(40);
$input-text-padding: rem-calc(8);
$input-bottom-margin: 1rem;
$input-font-size: 1rem;

$mobile-header-height: rem-calc(60);

// ------------------------------------------------------------------------------
//      CSS variables
// ------------------------------------------------------------------------------

:root {

    /**
     * Colors
     */
    --color-grayscale-0: #fafafa;
    --color-grayscale-1: #f6f6f6;
    --color-grayscale-2: #ebebeb;
    --color-grayscale-3: #d0d0d0;
    --color-grayscale-4: #c3c3c3;
    --color-grayscale-5: #bcbcbc;
    --color-grayscale-6: #919191;
    --color-grayscale-7: #5c5c5c;
    --color-grayscale-8: #424242;
    --color-grayscale-9: #292929;
    --color-grayscale-10: #e9e5df;

    --color-link-focus-background: #ecf8ff;
    --color-emphasis-blue: #3d5c7a;

    --color-cement: #{$cement};
    --color-cement-dark: #{$cement-dark};

    --color-black: #000000;
    --color-white: #ffffff;

    --color-green: #{$green};
    --color-orange: #{$orange};
    --color-orange-dark: #ff9600;
    --color-orange-darker: #e49200;
    --color-red: #{$red};
    --color-sale-red: #f74243;

    --color-focus-border: #{$focus-border-color};
    --color-focus-fill: #{$focus-fill-color};
    --color-text-selection-background: #d0ecf1;

    --color-modal-mask: rgb(0 0 0 / 7%);

    /**
     * Checkout inputs
     */
    --input-height: #{$input-height};
    --input-text-padding: #{$input-text-padding};
    --input-bottom-margin: #{$input-bottom-margin};
    --input-font-size: #{$input-font-size};

    /**
     * Transitions
     */
    --transition-fast-timing: 100ms ease-out;
    --transition-medium-timing: 150ms ease-out;

    /**
     * Shadows
     */
    --box-shadow-general: 0 0 8px 0 rgba(0, 0, 0, 0.15);

    /**
     * Typography
     */
    --font-family-primary: 'Oxygen', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
    --font-weight-light: 300;
    --font-weight-normal: 400;
    --font-weight-bold: 700;

    /**
     * Header
     */
    --mobile-header-height: #{$mobile-header-height};
}
