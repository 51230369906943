.site-header--middle {

    // ------------------------------------------------------------------------------
    //      Config
    // ------------------------------------------------------------------------------

    $navigation-item-spacing: rem-calc(20);
    $navigation-item-height: rem-calc(40);

    $counter-background-color: var(--color-red);
    $counter-text-color: white;
    $counter-font-size: rem-calc(10);

    $pull-down-border-color: var(--color-grayscale-6);

    // Z-indices
    $z-index-link: 3;
    $z-index-shadow-mask: 2;

    $z-index-pull-down: 1;

    // ------------------------------------------------------------------------------
    //      Box model
    // ------------------------------------------------------------------------------

    background-color: white;

    &,
    &--inner,
    &--content
    {
        height: var(--header-middle-height);

        @include breakpoint(small only) {
            height: rem-calc(50);
        }
    }

    &--inner {
        @include z-index(sticky-elements);
        position: relative;
        background-color: white;

        transition: var(--header-sticky-transition);

        .shopping-list-notification-column {
            transition: var(--header-sticky-transition);
        }

        &.sticky {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            transition: var(--header-sticky-transition),
            box-shadow .2s ease-out;

            transform: translateY(calc(-1 * (var(--header-middle-height) - var(--header-sticky-height))));

            &:not(.no-shadow) {
                @include modal-box-shadow;
            }

            .shopping-list-notification-column {
                transform: translateY(calc(0.5 * (var(--header-middle-height) - var(--header-sticky-height))));
            }
        }
    }

    &--content {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        transition: var(--header-sticky-transition);
        pointer-events: none;

        > * {
            pointer-events: all;
        }

        .sticky & {
            transform: translateY(calc(0.5 * (var(--header-middle-height) - var(--header-sticky-height))));
        }
    }

    .company-logo {
        width: rem-calc(250);
        height: rem-calc(60);
        transition: transform .2s ease-out;
        @include centered-flex-row();

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    // ------------------------------------------------------------------------------
    //      Content sections
    // ------------------------------------------------------------------------------

    &--left {
        @include centered-flex-row(flex-start);
    }

    &--center {
        @include centered-flex-row;
    }

    &--right {
        @include centered-flex-row(flex-end);
    }

    // ------------------------------------------------------------------------------
    //      Links
    // ------------------------------------------------------------------------------

    &--link {
        color: var(--color-grayscale-7);
        position: relative;
        border-radius: 50%;
        @include z-index(middle-header-pull-down-link);
        @include centered-flex-row();
        @include square(48);
        @include sized-svg(24) {
            fill: var(--color-grayscale-9);
        }

        transition: background-color 200ms ease-out;

        &.cart-link {
            @include sized-svg(27) {
                transform: translateY(-1px);
            }
        }

        &--counter {
            position: absolute;
            display: block;
            @include square(20);
            border-radius: 50%;
            border: rem-calc(2) solid white;
            background-color: $counter-background-color;
            color: $counter-text-color;
            text-align: center;
            font-size: $counter-font-size;
            line-height: rem-calc(16);
            font-weight: 600;
            transition: border-color 200ms ease-out;
            top: 3px;
            right: 3px;

            &.hidden {
                display: none;
            }
        }

        &:hover,
        &:focus {
            background-color: var(--color-grayscale-2);

            .site-header--middle--link--counter {
                border-color: var(--color-grayscale-2);
            }
        }
    }

    &--list-item {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-left: 1rem;
        width: 64px;

        &.with-pull-down {
            position: relative;
            @include z-index(middle-header-pull-down);

            @mixin __focus-hover-styles() {
                .site-header--middle--pull-down {
                    display: block;

                    &#cart-pull-down {
                        display: flex;
                    }
                }
            }

            &:hover,
                // Fallback for IE / Edge (through JS):
            &.focus-within {
                @include __focus-hover-styles;
            }

            // This must be a separate selector from the one above to not break the :hover selector in IE / Edge
            &:focus-within {
                @include __focus-hover-styles;
            }
        }
    }

    &--pull-down {
        @include z-index(middle-header-pull-down);
        @include centered-flex-column(space-between, stretch);
        border-radius: rem-calc(6);
        box-shadow: 0 1px 7px 0 rgba(black, 0.15);
        display: none;
        min-width: rem-calc(200);
        position: absolute;
        top: 100%;
        right: 0;
        background-color: white;

        &--content {
            overflow-y: auto;
            flex: 1 1 auto;
            padding: 1rem;
        }

        &--footer {
            @include centered-flex-row(space-between);
            flex: none;
            padding: 1rem;
            background-color: var(--color-grayscale-2);

            // Todo clean this up
            > * {
                margin: 0;
            }
        }
    }

    // ------------------------------------------------------------------------------
    //      Link-name
    // ------------------------------------------------------------------------------
    &--link-name{
        font-size: 12px;
        line-height: 12px;
        white-space: nowrap;
        color: var(--color-grayscale-9);
    }

    // ------------------------------------------------------------------------------
    //      Sticky state overrides
    // ------------------------------------------------------------------------------

    .sticky { // Class is set on .site-header--middle--inner
        .company-logo {
            transform: scale(0.7);
        }

        .site-header--middle {
            &--link-name {
                display: none;
            }

            &--list-item {
                margin-left: unset;
                transition: 0.5s;
            }
        }

        .center-content .back-to-top {
            opacity: 1;
            pointer-events: auto !important;
        }
    }
}
