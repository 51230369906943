#browser-support-modal {
    span {
        font-size: rem-calc(24);

        @include breakpoint(medium up) {
            font-size: rem-calc(28);
        }
    }

    .browser-list {
        margin: rem-calc(0 -20);
        @include centered-flex-row(center, center, wrap);
    }

    .browser-list-item {
        margin: rem-calc(20);
        text-align: center;

        img {
            display: block;
            width: rem-calc(60);
            height: rem-calc(60);
            object-fit: contain;
            margin: 0 auto 1rem;
        }

        a {
            color: var(--color-azure);
        }
    }

    .browser-name {
        color: var(--color-grayscale-7);
        font-weight: 600;
    }

    .modal--footer {
        @include centered-flex-row(space-between);

        p {
            margin: 0 1rem 0 0;
        }
    }
}
