// Override Foundation breadcrumbs to show custom divider
.breadcrumbs {
    padding-top: 0;
    padding-bottom: rem-calc(30);

    > *:not(:first-child)::before {
        content: '';
        background-image: url('/images/breadcrumb-arrow.png');
        background-repeat: no-repeat;
        background-size: rem-calc(10 10);
        width: rem-calc(10);
        height: rem-calc(10);
        display: inline-block;
        margin: 0 0.8rem;
    }

    li {
        margin-right: 0;
    }
}
