.quantity-control {
    $control-button-size: rem-calc(40);

    @include centered-flex-row;

    // '&' bumps are to override foundation stuff

    & &--input,
    & &--button {
        margin: 0;
        height: $control-button-size;
        flex: none;
        text-align: center;
        line-height: $control-button-size - rem-calc(2);
    }

    & &--input {
        width: 1.25 * $control-button-size;
        border: rem-calc(1) solid var(--color-grayscale-3);
        border-left: none;
        border-right: none;
        border-radius: 0;

        &:disabled {
            background-color: transparent;
            border: none;
            cursor: default;
        }

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    & &--button {
        width: $control-button-size;
        background-color: transparent;
        padding: 0;
        font-weight: bold;
        font-size: rem-calc(30);
        transition: background-color var(--transition-fast-timing),
                    border-color var(--transition-fast-timing);

        @include centered-flex-row;

        @include sized-svg(20) {
            fill: var(--color-grayscale-9);
            transition: fill var(--transition-fast-timing);
        }

        &,
        &:hover,
        &:disabled
        {
            border: rem-calc(1) solid var(--color-grayscale-3);
        }

        &:focus {
            @include focus-style-through-border;
        }

        &:disabled {
            opacity: 1;
            background-color: var(--color-grayscale-1);
            cursor: not-allowed;

            svg {
                fill: var(--color-grayscale-5);
            }

            &:hover {
                background-color: var(--color-grayscale-1);
            }
        }

        &:not(:disabled):hover {
            border-color: var(--color-grayscale-6);
        }

        &[data-action="decrease-quantity"] {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }

        &[data-action="increase-quantity"] {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }
}
