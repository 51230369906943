@import '../partials/interactive-image';

@media (max-width: 640px) {
    .content-layout-node.hide-on-mobile {
        display: none !important;
    }
}

// The width to use as the maximum displayable 'column' width when the full width option is enabled.
// Full-width is not literal here, but indicates the highest sensible column width for a block.
$column-maximum-width: 1920px;

$content-layout-block-bottom-margin-desktop: 4.3rem;
$content-layout-block-bottom-margin-mobile: 3rem;

.content-layout-tree {
    .content-layout-node {
        margin-bottom: $content-layout-block-bottom-margin-desktop;

        @media only screen and (max-width: 640px) {
            margin-bottom: $content-layout-block-bottom-margin-mobile;
        }
    }

    .content-layout-type-author-title {
        padding: 0 1rem;
        margin-top: 0;
        margin-left: auto;
        margin-right: auto;

        .content-layout-smaller-column {
            margin: 0 auto;
        }

        .page-title {
            margin: 2px 0 10px;
        }

        .cms-markup {
            margin-bottom: 1.133rem;

            &.quote {
                border-left: #e0e0e0 2px solid;
                padding-left: 1.333rem;
            }
        }

        .author {
            display: flex;
            margin: 0 -2.133rem;

            img {
                margin-right: 1rem;
                width: 64px;
                height: 64px;
                border-radius: 90px;
            }

            .author-info {
                margin-top: 0.733rem;

                &.without-image {
                    margin-left: 2.133rem;
                }
            }

            .name {
                font-family: var(--font-family-serif);
                font-size: 1.2rem;
                font-style: italic;
                line-height: 1.33;
                color: var(--color-grayscale-9);
            }

            .description {
                font-weight: bold;
            }
        }

        @media only screen and (max-width: 640px) {
            padding: 0;

            margin-top: 0;
            margin-left: auto;
            margin-right: auto;

            h1 {
                font-size: 1.8rem;
            }

            .author {
                margin: 0;
                .author-info.without-image {
                    margin-left: 0;
                    margin-top: 0
                }
                img {
                    margin-right: 1.5rem;
                }

                .info {
                    margin-top: 0.56rem;
                }
            }


            .page-title {
                margin: 2px 0 20px;
            }

            .cms-markup {
                margin-bottom: 2.133rem;
            }
        }
    }

    .content-layout-type-title {
        .page-title {
            text-align: center;
            margin: 2px 0 10px;
        }

        @media only screen and (max-width: 640px) {
            .page-title {
                margin: 2px 0 20px;
            }

            h1 {
                font-size: 1.8rem;
            }
        }
    }

    .content-layout-type-text {
        display: flex;
        flex-direction: column;

        padding: 0 10px;

        margin-top: 0;
        margin-left: auto;
        margin-right: auto;

        // Align it exactly with the normal desktop image edges.
        // The margins seem pretty inconsistent.
        > .column.wide-text {
            padding-left: 10px;
            padding-right: 10px;
        }

        .content-layout-smaller-column {
            margin: 0 auto;

            p, blockquote {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .text, .quote {
            margin-bottom: 1.4rem;
        }

        .quote {
            border-left: #e0e0e0 2px solid;
            padding-left: 1.333rem;
        }

        .button {
            padding: 0 1.4rem;
        }

        h1 {
            font-size: 2.1333333333rem;
        }

        h2 {
            font-size: 1.8rem;
        }

        h3 {
            font-size: 1.3333333333rem;
        }

        h4, h5, h6 {
            font-size: 1rem;
        }

        @media only screen and (max-width: 640px) {
            .content-layout-smaller-column {
                padding: 0;

                h1, h2, h3, h4, h5, h6 {
                    margin-top: 0;
                }

                h1 {
                    font-size: 1.8rem;
                }

                h2 {
                    font-size: 1.3333333333rem;
                }

                h3 {
                    font-size: 1.1rem;
                }

                h4, h5, h6 {
                    font-size: 1rem;
                }
            }
        }
    }

    .content-layout-type-image {
        &.row.full-width {
            max-width: 100%;
            text-align: center;

            img {
                max-width: $column-maximum-width;

                @include breakpoint(medium down) {
                    min-width: calc(100% + 2 * 1.333333rem);
                    margin-left: -1.333333rem;
                }
            }
            .image-gradient {
                max-width: $column-maximum-width;
                margin: 0 auto;
            }
        }

        // Rules to ensure that images that are uploaded smaller than 1920 pixels are shown centered and at
        // their uploaded size, instead of getting up-scaled.
        > .column {
            text-align: center;

            > picture {
                margin-left: auto;
                margin-right: auto;

                > img {
                    width: auto;
                }
            }
        }

        .row.overlay {
            position: relative;
            margin: 0 auto;
            max-width: 94rem;
            width: 100%;
        }

        .header-image {
            object-fit: cover;
        }

        .image-gradient {
            background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.64));
            position: absolute;
            top: 50%;
            right: 1.3333333333rem;
            left: 1.3333333333rem;
            height: 50%;
        }

        .image-overlay {
            position: absolute;
            bottom: 63px;
            left: 84px;

            .title {
                @extend h3;
                color: white;
                text-shadow: 0 0 10px black;

                &.has-button {
                    margin: 0 0 22px;
                }
            }

            .button {
                padding: 0 16px;
                line-height: 2.433333rem;
                float: left;
                background-color: white;
            }
        }

        @media only screen and (max-width: 640px) {
            margin-bottom: 2.5rem;

            .column {
                padding: 0;
            }

            .image-overlay {
                bottom: 24px;
                left: 25px;

                .button.medium {
                    font-size: 0.8666666667rem;
                    padding: 0 1rem;
                    height: 2.2666666667rem;
                    line-height: 2.1333333333rem;
                }
            }
        }
    }

    .content-layout-type-product-slider {
        display: flex;
        grid-column-gap: 0;

        .product-list {
            display: flex;
            position: relative;
            grid-column-gap: 0;
            padding: 0;
            margin: 0;
            transition: transform 0.4s;

            .recommendation {
                @include breakpoint(medium down) {
                    padding-bottom: 0;
                }

                &:not(:first-child) {
                    border: 0;
                }
            }
        }

        .column.product-slider {
            padding: 0;

            .swiper-container {
                padding: 0 rem-calc(24) rem-calc(24) rem-calc(24);
            }

            .swiper-pagination {
                bottom: 0;
            }

            .swiper-slide {
                height: auto;
            }
        }

        h2 {
            text-align: center;
            margin: 0 0 34px 0;
        }

        #product-slider {
            position: relative;
            overflow: hidden;
        }

        .recommendation--image-container {
            margin-bottom: 13px;
        }

        .recommendations-list .recommendation--image-square {
            margin: 0 auto;
            width: 10.4rem;
        }

        .recommendation--price-and-action {
            margin-top: 12px;
        }

        .carousel {
            &--canvas {
                position: relative;
            }
        }

        @media only screen and (max-width: 1450px) {
            padding-left: 1.3333333333rem;
            padding-right: 1.3333333333rem;
        }

        @media only screen and (max-width: 640px) {
            h2 {
                font-size: 1.3333333333rem;
            }
        }
    }

    .content-layout-type-three-image, .content-layout-type-two-image {

        .column {
            display: flex;
        }

        &.full-width {
            max-width: $column-maximum-width;
        }

        .column > .tile {
            flex: 1;
            margin: 0 15px;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
                margin-bottom: 0;
            }

            .image {
                position: relative;
            }

            .header-image {
                object-fit: cover;
            }
        }

        .row.overlay {
            position: relative;
            margin: 0 auto;
            max-width: 94rem;
            width: 100%;
        }

        .image-gradient {
            background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.64));
            position: absolute;
            top: 50%;
            right: 0;
            left: 0;
            height: 50%;
        }

        .image-overlay {
            position: absolute;
            bottom: 39px;
            left: 39px;

            .title {
                @extend h2;
                color: white;
                text-shadow: 0 0 10px black;

                &.has-button {
                    margin: 0 0 22px;
                }
            }

            .button {
                padding: 0 16px;
                line-height: 2.433333rem;
                float: left;
                background-color: white;
            }
        }

        @media only screen and (max-width: 640px) {
            .column {
                display: block;
                padding: 0;
            }

            .column > .tile {
                margin: 0 0 33px 0;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }

            .image-overlay {
                bottom: 24px;
                left: 25px;

                .title {
                    font-size: 1.466666667rem;

                    &.has-button {
                        margin: 0 0 1rem;
                    }
                }

                .button.button.medium {
                    font-size: 0.8666666667rem;
                    padding: 0 1rem;
                    height: 2.2666666667rem;
                    line-height: 2.1333333333rem;
                }
            }
        }

        @media only screen and (min-width: 641px) and (max-width: 1024px) {
            .image-overlay {
                left: 5%;
                right: 5%;

                .title {
                    font-size: 1.433333rem;
                }

                .button.button.medium {
                    font-size: 0.9333333rem;
                    padding: 0.4rem 0.5rem;
                    height: fit-content;
                    white-space: break-spaces;
                    line-height: 1.333333333rem;
                }
            }
        }
    }
}

.content-layout-node.content-layout-type-inspiration-pages {
    padding: 0;

    .column.title {
        border-top: 1px solid var(--color-grayscale-2);
    }

    h2 {
        text-align: left;
        margin: 47px 0 32px 0;
    }

    h3 {
        text-align: left;
        margin: 47px 0 32px 0;
    }

    .related-articles-container {
        display: grid;
        grid-auto-columns: minmax(0, 1fr);
        grid-auto-flow: column;
        padding: 0;
        &.row {
            &:after {
                content: none;
            }
        }
    }

    .tile {
        margin: 0 15px;
        display: flex;
        flex-direction: column;

        &:first-of-type {
            margin-left: 0;
        }

        &:last-of-type {
            margin-right: 0;
            margin-bottom: 0;
        }

        .content {
            height: 100%;
            display: flex;
            flex-direction: column;

            .title {
                margin: 20px 0 5px;
                word-wrap: break-word;
                font-size: 18px;
                @include line-clamp(2);
            }
            .text {
                margin-top: auto;
                margin-bottom: 18px;
                @include line-clamp(3);
            }

            .proceed-button {
                width: fit-content;
            }

            .button.button.return-button::after, .button.button.proceed-button::after {
                border-left: 5px solid var(--color-grayscale-9);
            }
        }

        img {
            max-height: 275px;
            min-height: 275px;
            object-fit: cover;
            object-position: center;
        }
    }

    @media only screen and (max-width: 1450px) {
        padding-left: 1.3333333333rem;
        padding-right: 1.3333333333rem;
    }

    @media only screen and (max-width: 640px) {

        .column.title {
            padding: 0;
        }

        h2 {
            margin: 25px 0 15px 0;
            font-size: 1.3333333333rem;
        }

        .related-articles-container {
            grid-auto-flow: row;
        }

        .tile {
            margin: 0 0 25px 0;

            &:last-of-type {
                margin-bottom: 0;
            }

            .content {
                .title {
                    margin: 20px 0 7px;
                }
                .text {
                    margin-bottom: 7px;
                }
                .button.button.medium:not(.toggle-button):not(.icon-button), .button.button.large:not(.toggle-button):not(.icon-button) {
                    padding-left: 0;
                }
            }
        }
    }
}

.content-layout-node.content-layout-type-location-address {
    a {
        color: #ffa200;
    }

    section {
        margin-bottom: 2rem;
    }

    &--title {
        margin-bottom: 2rem;
    }

    &--subtitle {
        font-size: 1rem;
        font-weight: bold;
    }

    &--image {
        @include square-pseudo(100%);
        @include centered-cover-background;
    }

    &--contact,
    &--extra {
        @include list-reset;
    }

    @include breakpoint(medium up) {
        .medium-6.columns {
            padding-right: 2rem;
        }
    }

    table.opening-hours {
        width: 100%;
        table-layout: fixed;

        tr:not(:last-child) th,
        tr:not(:last-child) td {
            border-bottom: 1px solid var(--color-grayscale-2);
        }

        th, td {
            padding: rem-calc(5 0);
        }

        th {
            font-weight: 400;
            text-align: left;
        }
    }
}
