.tips-index{
  .title{
    font-size: rem-calc(32);
    line-height: rem-calc(28);
    margin-bottom: rem-calc(20);
  }

  p{
    line-height: rem-calc(28);
  }

  .dropdown-sidebar-navigation{
    margin-bottom: rem-calc(20);
  }
}

@media screen and (max-width: 684px){
  .tips-index{
    .title{
      line-height: rem-calc(38);
    }
  }
}