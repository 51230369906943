%label {
    color: var(--color-grayscale-9);
    cursor: initial;
    display: block;
    font-size: rem-calc(14);
    font-weight: 400;
    line-height: rem-calc(40);
    margin-bottom: 0;
}

label {
    @extend %label;
}

// ------------------------------------------------------------------------------
//      These styles are foundation overrides, and align the global style of
//      legacy input implementations with the input-wrapper component styles.
// ------------------------------------------------------------------------------

input:not([type]),
input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="color"],
textarea,
select
{
    margin: 0;
    color: var(--color-grayscale-8);
    border-radius: 4px;

    &:not(textarea) {
        line-height: rem-calc(38);
        height: rem-calc(40);
        font-weight: 600;
        padding-top: 0;
        padding-bottom: 0;
    }

    @include placeholder-styles;

    &:focus {
        @include focus-style-through-border();
        background-color: white;
    }

    &:disabled {
        background-color: var(--color-grayscale-1);
        border-color: transparent;
        cursor: not-allowed;
        background-image: none;
    }
}

select {
    border-bottom-width: 2px;
    transition: .1s ease-in-out all;

    &:not(:disabled) {
        &:hover {
            cursor: pointer;
            border-color: var(--color-grayscale-6);
        }
    }
}

.error-message {
    display: inline-block;
    color: white;
    background-color: var(--color-red);
    padding: 0 0.5rem;
}

/*
 * Inputs
 */
.form-control {
    &.input {
        height: rem-calc(40);
        background-color: var(--color-white);
        border: 1px solid var(--color-grayscale-2);
        box-shadow: none;
        transition: all 0.3s ease-in-out;
    }

    &.input--no-margin{
        margin-bottom: 0;
    }

    &.readonly{
        background-color: var(--color-grayscale-2) !important;
        border: none;

        &:hover{
            border: none;
        }
    }
}

textarea{
    &.textarea{
        height: rem-calc(160);
        background-color: var(--color-white);
        border: 1px solid var(--color-grayscale-2);
        box-shadow: none;
        transition: all 0.3s ease-in-out;
    }
}


/*
 * Buttons
 */

.submit-button {
    height: rem-calc(40);
    background-color: var(--color-cement);
    color: var(--color-white);
    padding: 0 rem-calc(20);
    border: none;
}

.submit-button--no-margin{
    margin: 0;
}

fieldset {
    margin: 0;
    border: none;
    padding: 0;

    legend {
        float: none;
        font-weight: 400;
        font-size: rem-calc(26);
        margin-bottom: 2rem;
    }

    &.subSet legend {
        @extend %label;
    }
}

.valid input {
    border-color: var(--color-green);
}

// Let's have some fun overriding foundation's CSS rules :|
form,
form[data-abide] {
    margin: 0;

    &,
    .error {
        small.error.bl-error {
            background: transparent;
            color: var(--color-red);
            margin: 0;
            font-weight: 600 !important;
            font-style: normal !important;
            padding-left: 0;
            padding-right: 0;
        }
    }
}

label.error {
    color: scale-color($form-label-font-color, $lightness: 15%);
}
