.error-page {

    padding-bottom: 4rem;

    &--intro {
        max-width: rem-calc(700);
        margin: 2rem auto;
        text-align: center;
    }

    &--search {
        margin: 2rem auto 4rem;
        max-width: rem-calc(320);
    }


    // ------------------------------------------------------------------------------
    //      Grid layout
    // ------------------------------------------------------------------------------

    &--grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, auto);
        grid-column-gap: 40px;
        grid-row-gap: 40px;
    }

    &--block {
        background-color: var(--color-grayscale-2);
        @include centered-cover-background;

        &.block-1 { grid-column: 1 / span 2 }
        &.block-2 { grid-column: 3 }
        &.block-3 { grid-column: 4 }

        &.block-4 {
            grid-column: 1;
            grid-row-start: 2;
        }

        &.block-5 {
            grid-column: 2;
            grid-row-start: 2;
        }

        &.block-6 {
            grid-column: 3 / span 2;
            grid-row-start: 2;
        }

        &.square {
            @include square-pseudo(before);
        }

    }

    @include breakpoint(medium down) {

        &--grid {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(4, auto);
        }

        .block-1 { grid-column: 1; grid-row: 1 }
        .block-2 { grid-column: 2; grid-row: 1 }
        .block-3 { grid-column: 1; grid-row: 2 }
        .block-4 { grid-column: 2; grid-row: 2 }
        .block-5 { grid-column: 1; grid-row: 3 }
        .block-6 { grid-column: 2; grid-row: 3; background-position: right; }
    }
}

