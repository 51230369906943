.category-list-slider-wrapper-mobile {
    display: inline-block;
    width: 100%;

    .subcategory-list-mobile {

        .swiper-slide {
            width: fit-content;
        }

        &--item {
            display: flex;
            border-radius: 22px;
            border: 1px solid #0000002e;
            padding: 8px 25px;
            width: fit-content;

            &:not(:first-child) {
                margin-left: 0.5rem;
            }

            &.selected {
                border: 1px solid var(--color-grayscale-9);
                font-weight: 600;
                order: -1;
            }

            &--text {
                line-height: 1.2rem;
                font-size: 0.95rem;
                white-space: nowrap;
            }
        }
    }
}

@include breakpoint(medium up) {
    .category-list-slider-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;

        .subcategory-list {
            .swiper-slide {
                display: flex;
                flex-direction: column;
                align-items: center;

                .subcategory-list--item-wrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    &--text {
                        font-size: 0.85rem;
                        line-height: 1.2rem;
                        text-align: center;
                        padding-top: 0.73rem;
                    }

                    &--image {
                        object-fit: contain;
                        min-width: 7rem;
                        max-height: 5.6rem;
                        height: 5.6rem;
                    }
                }
            }
        }

        .swiper-container {
            .swiper-wrapper {
                margin: auto;
            }
        }

        .swiper-button {
            display: block;
            padding: 0.8rem;
            width: 3rem;
            height: 3rem;
            border-radius: 50%;
            box-shadow: 0 1px 0 0 #d0d0d0;
            border: solid 1px #d0d0d0;
            cursor: pointer;

            svg {
                width: 100%;
                height: 100%;
            }

            &.swiper-button-disabled {
                display: none;
            }
        }
    }
}