.inspi-grid {
    // Small screen ratios:

    $small-screen-ratios: (
        half: 0.5,
        double: 2
    );

    @each $ratio-alias, $multiplier in $small-screen-ratios {

        %small-height-#{$ratio-alias} {
            @include breakpoint(small only) {
                &::before {
                    display: block;
                    content: '';
                    width: 100%;
                    padding-top: $multiplier * 100%;
                }
            }
        }

        %always-height-#{$ratio-alias} {
            &::before {
                display: block;
                content: '';
                width: 100%;
                padding-top: $multiplier * 100%;
            }
        }

    }

    %square {
        &::before {
            display: block;
            content: '';
            width: 100%;
            padding-top: 100%;
        }
    }

    .no-before::before {
        display: none!important;
    }

    .at-home-form {
        box-sizing: border-box;
        padding: $grid-column-gutter / 2;
        width: 100%;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @include breakpoint(small only) {
            justify-content: flex-start;
        }

        > * {
            max-width: 100%;
        }

        p {

            text-align: center;
        }

        .button {
            width: auto;
            height: auto;
            display: block !important;
            margin: 0;
        }
    }

    display: flex;

    @include breakpoint(medium down) {
        flex-wrap: wrap;
    }

    @include breakpoint(small only) {
        display: block;
    }

    .tile {
        position: relative;
        padding: $grid-column-gutter / 2;
        background-clip:content-box;
        flex: 0 0 auto;

        @include breakpoint(small only) {
            padding: $grid-column-gutter / 4;
            width: 100% !important;
        }

        .background-layer
        {
            position: absolute;
            background-size: cover;
            background-position: center center;
            inset: $grid-column-gutter / 2;

            width: calc(100% - #{$grid-column-gutter});
            height: calc(100% - #{$grid-column-gutter});

            @include breakpoint(small only) {
                inset: $grid-column-gutter / 4;

                width: calc(100% - #{$grid-column-gutter / 2});
                height: calc(100% - #{$grid-column-gutter /2});
            }

            display: flex;
        }

        a {
            display: block;
            position: relative;

            width: 100%;
            height: 100%;

            .title {
                pointer-events: none;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                box-sizing: border-box;
                text-align: center;
                font-size: rem-calc(20);
                padding: rem-calc(8 0);
                background: var(--color-grayscale-2);
                margin: 0;

                @include breakpoint(medium down) {
                    font-size: rem-calc(17);
                    font-weight: 600;
                }
            }

            &:hover .title {
                opacity: 1;
            }
        }
    }

    &.center-grid {
        .tile {
            width: 25%;
            @extend %square;

            @include breakpoint(medium only) {
                width: 50%;
            }
        }

        &.wrapping-grid {
            flex-wrap: wrap;
        }
    }

    &.top-grid,
    &.bottom-grid {

        .square-medium {
            @extend %square;
            width: 50%;

            @include breakpoint(medium down) {
                width: 100%;
            }
        }

        .tilewrap {
            width: 50%;

            @include breakpoint(medium down) {
                width: 100%;
            }

            .tiny-squares,
            .horizontal-medium {
                @extend %always-height-half;

                height: 50%;

                @include breakpoint(medium down) {
                    height: auto;
                }
            }

            .horizontal-medium {
                &::before {
                    @include breakpoint(medium down) {
                        display: block;
                        content: '';
                        width: 100%;
                        padding-top: 100%;
                    }
                }
            }

            .tiny-squares {
                display: flex;

                @include breakpoint(small only) {
                    display: block;

                    &::before {
                        display: none;
                    }
                }

                .square-tiny { // tile
                    @extend %square;
                    width: 50%;
                }
            }
        }
    }
}
