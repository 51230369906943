.sold-out-tooltip {
    width: 100%;
    height: rem-calc(48);
    background-color: var(--color-white);
    box-shadow: 0px 0px 10px 0px #d3d3d3;
    border-radius: 4px;
    text-align: left;
    margin-bottom: rem-calc(24);
    position: relative;
    padding: rem-calc(9);

    &.plp {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        z-index: 1;
        max-width: rem-calc(282);
        margin: 1px auto;
    }

    &.pdp {
        position: absolute;
        left: rem-calc(16);
        top: rem-calc(224);
        z-index: 1;
        max-width: rem-calc(282);
        margin: 0 auto;

        @include breakpoint(small up) {
            max-width: 100%;
            top: rem-calc(178);
            left: rem-calc(8);
        }

        @include breakpoint(medium only) {
            max-width: rem-calc(209);
            top: rem-calc(147);
            left: 0;
        }

        @include breakpoint(large) {
            max-width: rem-calc(201);
            top: rem-calc(216);
            left: rem-calc(47);
        }
    }

    &:after {
        content: "";
        width: 0px;
        height: 0px;
        position: absolute;
        left: 42%;
        bottom: -10px;
        right: 0;
        border: 10px solid transparent;
        border-bottom: none;
        border-top-color: var(--color-white);
        filter: drop-shadow(0 2px 1px rgba(59, 59, 59, 0.1));
    }

    &.active {
        .progress-bar--indicator {
            animation: progress-bar-loading 4s 0s ease-in-out;
        }
    }

    .progress-bar {
        height: rem-calc(4);
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        border-radius: 4px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        background-color: rgb(255, 162, 0, .48);

        .progress-bar--indicator {
            height: 100%;
            width: 0%;
            background-color: #FFA200;
        }

        @keyframes progress-bar-loading {
            from {
                width: 100%;
            }

            to {
                width: 0%;
            }
        }
    }

    p {
        font-size: rem-calc(13);
        font-weight: 600;
        line-height: rem-calc(14);
        color: var(--color-grayscale-9);
    }
}
