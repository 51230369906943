@media(min-width: 1025px) {

    @mixin subMenuHover($childIndex) {
        &:has(> li:nth-child(#{$childIndex}) > a:hover),
        &:has(> li:nth-child(#{$childIndex}) > ul:hover),
        &:has(> li:nth-child(#{$childIndex}) > ul *:hover) {
            > li:nth-child(#{$childIndex}) > a {
                border-bottom: 3px solid black;

                svg {
                    transform: rotate(270deg);
                }
            }
        }
    }

    @mixin highlightSubCategory($childIndex) {
        &:has(> li > ul > li:nth-child(#{$childIndex}) > ul:hover) {
            > li > ul > li:nth-child(#{$childIndex}) > a {
                display: flex;
                background: var(--color-white);
                color: var(--color-grayscale-9);

                svg {
                    opacity: 1;
                }
            }
        }
    }

    .site-header--bottom {
        background-color: var(--color-white);

        #nav-root {
            gap: 2rem;

            @for $i from 1 through 20 {
                @include subMenuHover($i);
                @include highlightSubCategory($i);
            }

            li ul li a:not([href]) {
                pointer-events: none;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        nav {
            --nav-side-width: 250px;
            position: sticky;
            top: 0;
            z-index: 101;

            ul {
                margin: 0;
                padding: 0;
            }

            > ul {
                display: flex;
            }

            // Parent categories
            > ul > li > a {
                display: flex;
                align-items: center;
                gap: .5rem;
                padding-bottom: .5rem;
                transition: border-bottom-color .2s ease-in-out;
                border-bottom: 3px solid transparent;
                cursor: pointer;

                &:has(+ ul) {
                    cursor: default;
                }

                &:hover {
                    border-bottom-color: #000;
                    text-decoration: none;
                }

                svg {
                    transition: transform .2s ease-in-out;
                    will-change: transform;
                    transform: rotate(90deg);
                }
            }

            // Sub-menu
            > ul > li > ul {
                display: block;
                position: absolute;
                inset-inline: 0;
                min-height: min(100vh, 1000px);
                border-radius: rem-calc(6);
                box-shadow: 0 4px 7px 0 rgba(black, 0.15);
                background: var(--color-grayscale-1);
                visibility: hidden;
                opacity: 0;
                transition: opacity .2s ease-in-out .3s,
                            visibility .2s ease-in-out .3s;
                will-change: opacity, visibility;

                // Layout when has no sub-sub categories
                &:has(> li > a.desktop-layout-small-list:hover) {
                    width: max-content;
                    min-height: auto;
                }
            }

            // Expand sub-menu on hover (anchor or sub-menu itself)
            > ul > li > a:hover:not(:target) + ul,
            > ul > li > ul:hover {
                visibility: visible;
                opacity: 1;
            }

            > ul > li > ul > li {
                display: flex;

                // Sub-categories menu (left side)
                &:nth-of-type(3) {
                    padding-top: 26px;
                }

                > a {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    padding: rem-calc(14) rem-calc(32);
                    width: var(--nav-side-width);
                    white-space: nowrap;
                    background: var(--color-grayscale-1);
                    color: var(--color-grayscale-7);

                    svg {
                        opacity: 0;
                        transition: transform .2s ease-in-out;
                        will-change: transform;
                    }
                }

                &:hover > a,
                > a:hover {
                    background-color: var(--color-white);
                    text-decoration: none;
                    color: var(--color-grayscale-9);
                    font-weight: var(--font-weight-bold);

                    svg {
                        opacity: 1;
                        transform: translateX(.5rem);
                    }
                }

                > ul {
                    display: none;
                    position: absolute;
                    left: var(--nav-side-width);
                    top: 0;
                    bottom: 0;
                    width: calc(100% - var(--nav-side-width));
                    background: var(--color-white);
                    border-bottom-right-radius: 8px;

                    > li > ul li {
                        margin-top: rem-calc(8);
                    }
                }

                // Show sub-sub categories menu
                &:hover > ul,
                > a:hover + ul,
                > ul:hover {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    grid-template-rows: min-content;
                    gap: rem-calc(32) 0;
                    padding: rem-calc(40) rem-calc(30);
                    overflow: auto;

                    &.desktop-layout-list-of-sub-children {
                        display: flex;
                        flex-direction: column;
                        gap: 0;

                        li {
                            margin-top: rem-calc(8);

                            a {
                                pointer-events: auto;
                            }
                        }
                    }
                }
            }

            // Normal list of items in stead of big sub navigation structure layout
            li.desktop-layout-list {
                position: relative;

                ul {
                    min-height: 100%;
                    width: min-content;

                    li:not(.nav-back, .mobile-heading) {
                        display: flex;
                        width: max-content;

                        a {
                            border-radius: .4rem;
                        }

                        ul {
                            height: min-content;
                            border-radius: 0.4rem;
                            box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.15);
                            padding: rem-calc(14) rem-calc(32);
                        }
                    }
                }
            }
        }

        // Highlight sub category when there is no hover over sub-sub categories (default)
        nav > ul > li {
            &:not(.desktop-layout-list) > ul {
                &:not(:has(:hover)) > li:nth-of-type(3) {
                    display: grid;

                    > a {
                        display: flex;
                        background: var(--color-white);
                        color: var(--color-grayscale-9);
                        font-weight: var(--font-weight-bold);

                        svg {
                            opacity: 1;
                        }
                    }

                    > ul {
                        display: grid;
                        grid-template-columns: repeat(3, 1fr);
                        grid-template-rows: min-content;
                        gap: rem-calc(32) 0;
                        padding: rem-calc(40) rem-calc(30);
                        overflow: auto;

                        &.desktop-layout-list-of-sub-children {
                            display: flex;
                            flex-direction: column;
                            gap: 0;

                            li {
                                margin-top: rem-calc(8);

                                a {
                                    pointer-events: auto;
                                }
                            }
                        }
                    }
                }
            }
        }

        .custom-desktop-heading,
        .category-heading {
            font-weight: var(--font-weight-bold);
        }

        .mobile-menu-secondary,
        li.mobile-heading,
        li.nav-back {
            display: none;
        }

        .mobile-menu {
            &--top,
            &--account-link {
                display: none;
            }
        }
    }
}
