.faq-index {
    h1 {
        margin: rem-calc(19 0 10);
        padding-bottom: rem-calc(7);
    }
}

.customer-service-detail {
    .questions {
        .accordion-navigation {
            &:first-child{
                border-top: none;
            }
        }
    }
}

@media screen and (max-width: 684px){
    .faq-index{
        margin-top: rem-calc(15);

        .hero{
            &:after{
                padding-top: 46%;
            }
        }

        .accordion-navigation{
            padding: rem-calc(28 0);
        }

        .question{
            .answer{
                padding-top: rem-calc(20);
            }
        }
    }
}
