.product-discount-badge {
    @include centered-flex-row;
    @include square(54);

    border-radius: 50%;
    background-color: var(--color-red);
    color: var(--color-white);
    font-weight: bold;
    text-decoration: none !important;

    &.hidden {
        display: none;
    }

    @include breakpoint(small only) {
        font-size: rem-calc(12);
        @include square(40);
    }
}
