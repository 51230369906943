.locations-index {
    margin-top: rem-calc(40);

    p {
        margin-bottom: 0;
        margin-top: 1rem;
    }

    .page-title {
        font-size: rem-calc(32);
        font-weight: 400;
        text-align: center;
    }

    .title {
        font-size: rem-calc(28);
        font-weight: 400;
        margin-bottom: rem-calc(15);
    }

    .photo {
        background-color: #EBEBEB;
        background-size: cover;
        background-repeat: no-repeat;
        height: 100%;

        &:after {
            content: '';
            display: block;
            padding-top: 90%;
        }
    }
}

.basiclabel-locations {
    @include list-reset;
}

.basiclabel-location {
    padding: 2rem;
    border-radius: 3px;
    background-color: white;
    margin: rem-calc(40 0);


    section {
        margin-bottom: 2rem;
    }

    &--title {
        margin-bottom: 2rem;
    }

    &--subtitle {
        font-size: 1rem;
        font-weight: bold;
    }

    &--image {
        @include square-pseudo(100%);
        @include centered-cover-background;
    }

    &--contact,
    &--extra {
        @include list-reset;
    }

    @include breakpoint(medium up) {
        .medium-6.columns {
            padding-right: 2rem;
        }
    }
}


.location-block {
    padding: 2rem;
    border-radius: 3px;
    background-color: white;
    margin: rem-calc(40 0);
}

.locations-index,
.location-info-list {

    a {
        color: #ffa200;
    }

    table.opening-hours {
        width: 100%;
        table-layout: fixed;

        tr:not(:last-child) th,
        tr:not(:last-child) td {
            border-bottom: 1px solid var(--color-grayscale-2);
        }

        th, td {
            padding: rem-calc(5 0);
        }

        th {
            font-weight: 400;
            text-align: left;
        }
    }
}

.contact-location-wrapper {
    display: flex;
    flex-direction: column;
    gap: rem-calc(12);
    margin-top: rem-calc(15);

    h2 {
        margin: 0;
    }
}
