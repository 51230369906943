.brochures-detail {
    .title{
        font-size: rem-calc(32);
        margin-bottom: rem-calc(18);
    }

    .issuu-viewer {
        position: relative;

        &:before {
            content: '';
            display: block;
            padding-top: 50%;
        }

        > iframe {
            position: absolute;
            top: 0;
            left: 0;

            // copyright
            span {
                display: none;
            }
        }
    }

    .social-media{
        margin-top: rem-calc(30);
    }
}

@media screen and (max-width: 684px){
    .brochures-detail{
        .issuu-viewer{
            height: rem-calc(400);
        }

        .dropdown-sidebar-navigation{
            margin-bottom: rem-calc(20);
        }
    }
}
