// ------------------------------------------------------------------------------
// Focus styles
// ------------------------------------------------------------------------------

@mixin focus-box-shadow {
    box-shadow: 0 0 0 3px rgba(black, 0.08);
}

@mixin focus-style-through-outline() {
    outline: 1px solid var(--color-grayscale-6);
    @include focus-box-shadow;
}

@mixin focus-style-through-border() {
    outline: none;
    border-color: var(--color-grayscale-6);
    @include focus-box-shadow;
}

// ------------------------------------------------------------------------------
//      Box sizing
// ------------------------------------------------------------------------------

@mixin square($size) {
    width: rem-calc($size);
    height: rem-calc($size);
}

@mixin circle($size) {
    width: rem-calc($size);
    height: rem-calc($size);
    border-radius: 50%;
}

@mixin min-square($size) {
    min-width: rem-calc($size);
    min-height: rem-calc($size);
}

@mixin max-square($size) {
    max-width: rem-calc($size);
    max-height: rem-calc($size);
}

// ------------------------------------------------------------------------------
//      Placeholder styles
// ------------------------------------------------------------------------------

@mixin placeholder-styles {
    $placeholder-color: var(--color-grayscale-5);
    // Firefox
    &::-moz-placeholder {
        font-weight: 400;
        color: $placeholder-color;
        opacity: 1; // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526
        @content;
    }

    &:-ms-input-placeholder { // Internet Explorer 10+
        font-weight: 400;
        color: $placeholder-color;
        @content;
    }

    &::-webkit-input-placeholder { // Safari and Chrome
        font-weight: 400;
        color: $placeholder-color;
        @content;
    }
}

// ------------------------------------------------------------------------------
//      Box shadows
// ------------------------------------------------------------------------------

@mixin modal-box-shadow {
    $box-shadow-color: #08090a;
    box-shadow: rem-calc(1 1 25 0) rgba($box-shadow-color, 0.2);
}

@mixin tooltip-box-shadow {
    $box-shadow-color: black;
    box-shadow: rem-calc(0 2 5 0) rgba($box-shadow-color, 0.15);
}

// ------------------------------------------------------------------------------
//      Resets
// ------------------------------------------------------------------------------

@mixin list-reset {
    list-style: none;
    margin: 0;
    padding: 0;
}

@mixin button-reset($background-color: transparent) {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    font-size: inherit;
    color: inherit;
    font-family: inherit;

    &, &:hover, &:focus {
        background-color: $background-color;
    }

    @content;
}

// ------------------------------------------------------------------------------
//      Ellipsis animation
// ------------------------------------------------------------------------------


@keyframes ellipsis {
    to {
        width: 1em;
    }
}

@-webkit-keyframes ellipsis {
    to {
        width: 1em;
    }
}

@mixin ellipsis-animation() {

    &::after {
        overflow: hidden;
        display: inline-block;
        vertical-align: bottom;
        -webkit-animation: ellipsis steps(4,end) 1900ms infinite;
        animation: ellipsis steps(4, end) 1900ms infinite;
        content: "\2026"; /* ascii code for the ellipsis character */
        width: 0;
    }
}

// ------------------------------------------------------------------------------
// Misc.
// ------------------------------------------------------------------------------

@mixin clearfix {
    &::after {
        content: '';
        width: 0;
        height: 0;
        display: table;
        clear: both;
    }
}

@mixin sized-svg($sizeOrWith, $height: $sizeOrWith) {
    svg {
        display: inline-block;
        width: rem-calc($sizeOrWith);
        height: rem-calc($height);
        @content;
    }
}

@mixin column-gutters($include-vertical: false, $use-margin: false) {
    @if $use-margin {
        margin-left: $grid-column-gutter / 2;
        margin-right: $grid-column-gutter / 2;

        @if $include-vertical {

            // Prevent margin-collapse:
            position: relative;

            margin-top: $grid-column-gutter / 2;
            margin-bottom: $grid-column-gutter / 2;
        }
    } @else {
        padding-left: $grid-column-gutter / 2;
        padding-right: $grid-column-gutter / 2;

        @if $include-vertical {
            padding-top: $grid-column-gutter / 2;
            padding-bottom: $grid-column-gutter / 2;
        }
    }
}

@mixin fixed-overlay($z-index: null) {

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @if $z-index != null {
        z-index: $z-index;
    }
}

@mixin line-clamp($numberOfLines) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $numberOfLines;
    line-clamp: $numberOfLines;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}
