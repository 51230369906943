.read-more {
    display: inline-flex;
    margin: rem-calc(15 0 0 0);
    align-items: center;

    @include sized-svg(16) {
        fill: var(--color-grayscale-6);
        margin-left: rem-calc(8);
        transition: all .1s ease-out;
    }

    &:hover {
        text-decoration: none;

        span {
            text-decoration: underline;
        }
        svg {
            transform: translateX(2px);
        }
    }
}
