.gift-card-digital {
    .cover {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        margin-bottom: rem-calc(40);

        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        svg {
            fill: #D9D9D9;
            z-index: -1;
        }

        &:before {
            content: '';
            display: inline-block;
            padding-top: 20%;
        }
    }

    .navigation {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .previous-icon {
            &:before {
                content: '';
                display: inline-block;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 5px 5px 5px 0;
                border-color: transparent var(--color-grayscale-6) transparent transparent;
                margin-right: rem-calc(10);
            }
        }
    }

    li {
        padding-top: rem-calc(20);

        @include breakpoint(small only) {
            padding-right: rem-calc(30);
        }

        transition: box-shadow .15s ease-out;
        box-shadow: 0 0 8px 0 rgba(0,0,0,0);

        .gift-card-title {
            font-size: rem-calc(15);
        }

        .gift-card-value {
            font-size: rem-calc(20);
        }

    }
    .page-title {
        margin: rem-calc(0 0 20 0);
        text-align: center;
    }

    .gift-cards {
        display: flex;
        gap: 6rem;
        flex-wrap: wrap;
        justify-content: center;
        margin: rem-calc(40 0 30 0);
    }

    .total_amount {
        font-size: rem-calc(18);
        line-height: rem-calc(30);
        font-weight: bold;
        padding-bottom: rem-calc(40);
    }

    .product-preview.small:hover:before {
        bottom: -6rem;
    }

    .details {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .gift-card-footer {
        margin-top: rem-calc(40);
        padding-top: rem-calc(30);
        border-top: 1px solid var(--color-grayscale-2);
    }

    .form-section {
        padding-top: rem-calc(30);
        border-top: 1px solid var(--color-grayscale-2);
    }

    .form-title {
        font-size: rem-calc(22);
        line-height: rem-calc(30);
        margin-bottom: rem-calc(20);
    }

    #giftcardMessage {
        height: rem-calc(150);
        resize: none;
        box-shadow: none;
    }

    .form-notice,
    label.form-notice {
        color: var(--color-grayscale-6);
        font-size: rem-calc(14);
    }

    label[for=giftcardMessage] {
        margin-bottom: rem-calc(40);
    }

    .inputWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: rem-calc(16);

        @include breakpoint(small only) {
            display: block;
        }

        label {
            display: inline-block;
            width: rem-calc(150);

            flex: 0 0 auto;
        }

        input {
            flex: 1 0 auto;
            width: auto;
            margin: 0;
            box-shadow: none;
        }

        .formGroup {
            input {
                width: rem-calc(300);
                @include breakpoint(small only) {
                    width: 100%;
                }
            }
        }
    }

    .example-block {
        @include breakpoint(small only) {
            margin-top: 1rem;
        }

        background-color: var(--color-grayscale-2);
        padding: rem-calc(40);

        @include breakpoint(large) {
            display: flex;
        }

        .image-column {
            flex: 0 0 auto;
            margin-right: rem-calc(20);
        }

        .text-column {
            flex: 1 0 0;

            .preview-navigation {
                @include centered-flex-row(flex-start, flex-start);
                @include sized-svg(20) {
                    flex: none;
                    fill: var(--color-grayscale-6);
                    margin: rem-calc(2 8 0 0);
                    transition: transform .1s ease-out;
                }
            }
        }

    }

    .custom-radio {
        margin-bottom: rem-calc(16);
        input[type=radio] {
            display: none;
        }
        span {
            position: relative;
            display: block;
            padding-left: rem-calc(30);

            &:before {
                content: "";
                display: block;
                width: rem-calc(22);
                height: rem-calc(22);
                border-radius: 50%;
                border: 1px solid var(--color-grayscale-9);
                box-shadow: 0 1px 0 0 var(--color-grayscale-9);
                position: absolute;
                left: 0;
                top: 10px;
            }

            &:after {
                content: "";
                display: block;
                width: rem-calc(12);
                height: rem-calc(12);
                border-radius: 50%;
                position: absolute;
                left: rem-calc(5);
                top: rem-calc(15);
                background-color: var(--color-grayscale-9);
                opacity: 0;
                transition: all 300ms;
            }
        }

        input[type=radio]:checked + span {
            &:after {
                opacity: 1;
            }
        }
    }

    .custom-time {
        margin-bottom: rem-calc(10);
        position: relative;
        z-index: 5;
        width: rem-calc(88);
    }

    .customDateWrapper {
        padding-left: rem-calc(20);
        .inputs {
            margin-top: rem-calc(15);

            display: flex;
            align-items: center;

            span {
                width: rem-calc(30);
                text-align: center;
                flex: 0 0 auto;
            }

            input {
                width: 80px;
                text-align: center;
                margin-bottom: 0;
            }
        }

        .form-notice {
            margin-top: rem-calc(16);
        }
    }

    select[name="cutom-time"] {
        width: rem-calc(200) !important;
        height: rem-calc(37) !important;
    }
}
