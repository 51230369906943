#preview-giftcard-modal {
    max-width: rem-calc(720);
    background-color: #fafafa;

    @include breakpoint(small only) {
        padding-left: 0;
        padding-right: 0;
    }

    #giftcard-mail-preview-container {
        background-color: white;
        padding-left: 2rem;
        padding-right: 2rem;
        border: 1px solid #eee;
        text-align: center;
    }

    .placeholder {
        color: var(--color-grayscale-5);
        font-weight: bold;
        cursor: pointer;

        &:hover {
            color: var(--color-grayscale-6);

            .tooltip {
                display: block;
            }
        }

        position: relative;

        .tooltip {
            display: none;
            position: absolute;
            bottom: 100%;
            left: 50%;
            transform: translateX(-50%);
            width: rem-calc(200);
            background-color: $cement;
            color: white;
            font-weight: bold;
            font-size: rem-calc(13);
            text-align: center;
            box-shadow: 2px 2px 3px 0 rgba(black, 0.3);
            padding: rem-calc(10);

            &::after {
                content: '';
                display: block;
                position: absolute;
                top: 100%;
                left: 50%;
                transform: translateX(-#{rem-calc(5)});
                width: 0;
                height: 0;
                border-top: rem-calc(5) solid $cement;
                border-left: rem-calc(5) solid transparent;
                border-right: rem-calc(5) solid transparent;
            }
        }
    }

    .email-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include breakpoint(medium up) {
            margin-bottom: 2rem;
        }

        .logo,
        .navigation {
            @include breakpoint(medium up) {
                width: 50%;
                flex: 0 0 auto;
            }
        }

        .logo svg {
            max-width: 100%;
        }

        .navigation {
            @include breakpoint(small only) {
                display: none;
            }

            display: flex;
            align-items: center;
            justify-content: flex-end;

            span {
                padding: 0 1rem;
                border-right: 1px solid var(--color-grayscale-2);

                &:last-child {
                    border-right: none;
                }
            }
        }
    }

    .intro-text {
        margin-bottom: 2rem;
    }

    .salutation,
    .tagline,
    .additional-info {
        text-align: center;
    }

    .salutation,
    .tagline {
        font-size: rem-calc(22);
    }

    .salutation {
        font-weight: bold;
    }

    .additional-info {
        color: var(--color-grayscale-9);
    }

    .card-preview {
        margin-bottom: 2rem;
        justify-content: center;

        .price-overlay {
            color: var(--color-grayscale-9);
            text-align: center;
            font-size: rem-calc(32);
            font-weight: bold;
            margin-bottom: 2rem;

        }
    }

    .download-instructions {
        text-align: center;
    }

    .custom-message {
        margin-bottom: 2rem;
    }

    .download-instructions {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
}
