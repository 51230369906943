.usp-bar {
    --usp-height: 40px;

    margin: 0;

    &--list {
        height: var(--usp-height);
        overflow: hidden;
    }

    &--item {
        flex: none;
        padding: 0;
        line-height: var(--usp-height);

        // Default: JS shows current item.
        display: none;

        // Flex setup for activated items
        justify-content: center;
        align-items: center;

        a {
            color: white;
        }
    }

    &--icon {
        margin-right: rem-calc(10);
        width: rem-calc(16);
        height: rem-calc(16);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
}
