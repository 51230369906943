.site-footer--contact-links {
    @include list-reset;

    &--item {
        padding: rem-calc(3);
        margin: 0.5rem 0;
        text-decoration-color: white;
        border-radius: rem-calc(4);
        transition: background-color var(--transition-fast-timing);
        display: flex;
        align-items: center;

        &:hover {
            text-decoration-color: black;
        }

        &:focus {
            text-decoration: none;
        }
    }

    &--item-icon {
        flex: none;
        margin-right: 1rem;
        width: rem-calc(54) - 1rem;
        height: rem-calc(54) - 1rem;
        @include centered-flex-row;

        @include sized-svg(24) {
            fill: var(--color-grayscale-9);
        }
    }

    &--item-content,
    &--item-content--main,
    &--item-content--sub {
        display: block;
        line-height: rem-calc(24);
    }

    &--item-content {
        flex: 1 1 0;
    }

    &--item-content--main {
        font-size: rem-calc(17);
        font-weight: 600;
    }
}
