.webatelier-order-detail {

    h1 {
        @include centered-flex-row(flex-start);
        margin-bottom: 2rem;

        > span {
            margin-right: 1rem;
        }
    }

    h2 {
        font-size: 20px;
        font-weight: bold;
    }

    .order-number-highlight,
    .order-item-highlight,
    .index-highlight
    {
        display: inline-block;
        position: relative;

        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 3px;
            bottom: 0;
            left: 0;
        }
    }

    .order-number-highlight::after {
        background-color: aqua;
    }
    .order-item-highlight::after {
        background-color: #ffa600;
    }
    .index-highlight::after {
        background-color: lawngreen;
    }

    // This warning is shown if multiple statuses were returned from the API.
    // This should never happen because we strive to give each order-line
    // a unique identifier, but it is technically a possibility.
    .multiple-statuses-warning {
        background-color: var(--color-orange);
        color: white;
        font-size: 13px;
        font-weight: bold;
        padding: 8px;
        border-radius: 3px;
        margin-top: 10px;
        margin-bottom: 0;

        @include centered-flex-row(flex-start, $display: inline-flex);
        @include sized-svg(16) {
            fill: white;
            margin-right: 10px;
        }
    }

    .status-heading {
        font-weight: bold;
        margin: 2rem 0 0;
    }

    .status-tables {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -1rem;
    }

    .status-table-wrapper {
        margin: 1rem;
        padding: 1rem;
        background-color: white;
        box-shadow: 0 0 8px 0 rgb(0 0 0 / 15%);
    }

    table {
        th, td {
            padding: 5px;
            text-align: left;

            &.monospace {
                font-family: monospace;
            }
        }

        tr:not(:last-child) th,
        tr:not(:last-child) td {
            border-bottom: 1px solid var(--color-grayscale-2);
        }
    }
}
