.site-header--top {
    position: relative;
    @include z-index(top-header);
    height:  var(--header-top-height);
    line-height: var(--header-top-height);
    background-color: var(--color-grayscale-9);

    &--content {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }

    &--left {
        margin-left: -1rem;
        @include centered-flex-row(flex-start);
    }

    &--center {
        @include centered-flex-row;
    }

    &--right {
        margin-right: -1rem;
        @include centered-flex-row(flex-end);
    }

    &--link {
        display: block;
        margin: 0 1rem;
        height: var(--header-top-height);
        line-height: var(--header-top-height);
        color: var(--color-white);

        &:hover {
            color: var(--color-white);
            text-decoration: underline;
        }
    }
}
