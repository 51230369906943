.product-card {

    // Visual padding of the card container.
    $card-padding: rem-calc(24);

    $card-content-min-width: rem-calc(240);

    // Max-width for the main content of the card. Main content
    // includes the image, name, rating and price.
    $card-content-max-width: rem-calc(325);

    // The maximum square size of the actual image. The image
    // container makes sure the image is positioned correctly.
    $image-max-size: rem-calc(270);

    // The height of the rating  container is used to overlay
    // the price through transform while the rating is invisible.
    $rating-container-height: rem-calc(30);

    // Square size of the variant switch thumbnails, rendered when
    // a variant has multiple thumbnails.
    $variant-thumb-size: rem-calc(50);

    // Flyout container overflow by direction.
    $flyout-west-overflow: $variant-thumb-size + $card-padding;
    $flyout-south-overflow: rem-calc(130) + $card-padding;

    position: relative;
    background-color: white;

    @include breakpoint(large) {
        min-width: 2 * $card-padding + $card-content-min-width;
        max-width: 2 * $card-padding + $card-content-max-width;
    }

    &--content {
        position: relative;
        padding: $card-padding;

        @include breakpoint(small only) {
            padding: rem-calc(12);
        }
    }

    .wishlist-toggle {
        z-index: 2;
        position: absolute;
        top: 1rem;
        right: 1rem;
    }

    // ------------------------------------------------------------------------------
    //      Image & container layout
    // ------------------------------------------------------------------------------

    &--image-container {
        display: block;
        position: relative;
        width: 100%;
        @include square-pseudo(100%);

        .product-card--discount {
            position: absolute;
            bottom: 0;
            left: 0;
            display: flex;
        }

        .product-card--badges {
            position: absolute;
            bottom: 0;
            right: 0;
            display: flex;
        }

        .product-discount-badge,
        .product-badge {
            margin: 0.5rem;
        }
    }

    &--image-overlay {
        @include centered-flex-row;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        width: 100%;
        height: 100%;
        max-width: $image-max-size;
        max-height: $image-max-size;

        .main-image, .aux-image {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        .main-image {
            z-index: 0;
        }

        .aux-image {
            z-index: 1;
            opacity: 0;
            transition: opacity 200ms ease-out;
        }
    }

    // ------------------------------------------------------------------------------
    //      Statically positioned content
    // ------------------------------------------------------------------------------

    &--title { // <a>
        position: relative;
        display: block;
        margin-top: 2rem;
        margin-bottom: rem-calc(8);
        font-size: 1rem;
        line-height: 1.7;
        color: inherit;
        min-height: 5.1em; // 3 lines * 1.7
        overflow: hidden;

        @include breakpoint(medium down) {
            height: 5.6em; // 4 lines * 1.4
            line-height: 1.4;
            font-weight: 600;
            margin-top: 0;
        }

        @include breakpoint(small only) {
            font-size: rem-calc(13);
        }

        @include breakpoint(large) {
            text-align: center;
        }
    }

    &--rating-container {

        // For now:
        text-align: center;
        color: var(--color-grayscale-6);
        span { font-weight: bold; }
        /// For now

        margin: 0;
        height: $rating-container-height;

        @include breakpoint(medium down) {
            display: none;
        }
    }

    &--price-container {
        user-select: none;
        font-size: rem-calc(20);

        @include breakpoint(medium down) {
            display: inline-block; // To align left
        }

        @include breakpoint(small only) {
            font-size: 1rem;
        }
    }

    &--exclusive {
        font-weight: 600;
        color: #30a980;
        font-size: 13px;
        line-height: 20px;
    }

    // ------------------------------------------------------------------------------
    //      Flyout container structure
    // ------------------------------------------------------------------------------

    &--flyout-container {

        padding: 0 $card-padding $card-padding;
        background-color: white;

        @include breakpoint(small only) {
            padding: rem-calc(0 12 12);
        }

        @include breakpoint(large) {
            padding: $card-padding;
            width: 100%;
            height: calc(100% + #{$flyout-south-overflow});
            position: absolute;
            top: 0;
            right: 0;
            min-height: 100%;
        }

        .product-card.with-variant-switch & {
            width: calc(100% + #{$flyout-west-overflow});
        }
    }

    &--flyout-west,
    &--flyout-south {
        @include breakpoint(large) {
            margin: 1px;
            position: absolute;
        }
    }

    &:hover &--flyout-west,
    &:hover &--flyout-south {
        pointer-events: all;
    }

    &--flyout-west {
        padding: $card-padding 0 $card-padding $card-padding;
        @include breakpoint(large) {
            top: 0;
            left: 0;
            width: $flyout-west-overflow;
            height: calc(100% - #{$flyout-south-overflow});
        }
    }

    &--flyout-south {
        @include breakpoint(large) {
            padding: 0 $card-padding $card-padding;
            bottom: 0;
            right: 0;

            width: 100%;
            height: $flyout-south-overflow;
        }

        .product-card.with-variant-switch & {
            width: calc(100% - #{$flyout-west-overflow});
        }
    }

    &--delivery-info {
        user-select: none;
        padding: rem-calc(10 0);
        font-size: rem-calc(13);
        line-height: 1.4;

        @include breakpoint(large) {
            text-align: center;
            border-top: 1px solid var(--color-grayscale-2);

            .delivery-promise-addition {
                display: block;
            }
        }

        .green {
            font-weight: 600;
            color: var(--color-green);
        }

        .delivery-time--appendix {
            display: inline-block;
            color: var(--color-grayscale-6);
        }
    }

    &--actions {
        @include breakpoint(medium down) {
            display: none;
        }

        @include centered-flex-row(space-between);

        .cart-form {
            flex: 1 0 auto;
        }

        .sold-out {
            width: 100%;
            cursor: not-allowed;
            @include centered-flex-row;
            height: rem-calc(40);
            background-color: var(--color-grayscale-2);
            border-radius: 4px;
            @include sized-svg(20) {
                fill: var(--color-grayscale-6);
            }
        }

        .favorites-form {
            margin-left: rem-calc(10);
            flex: none;
        }

        .button {
            width: 100%;
            min-width: rem-calc(40);
            padding-left: rem-calc(16) !important;
            padding-right: rem-calc(16) !important;
        }
    }

    // ------------------------------------------------------------------------------
    //      z-stacking
    // ------------------------------------------------------------------------------

    &--content {
        position: relative;
        z-index: 1;
    }

    &--flyout-container {
        z-index: 0;
    }
}
