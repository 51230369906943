.product-list-empty {
    margin: 6rem 2rem;
    @include centered-flex-column();
}

.product-list {

    display: flex;
    flex-wrap: wrap;

    &:not(.loading) {
        transition: opacity 150ms ease-out;
    }

    &.loading {
        opacity: 0.2;
        pointer-events: none;
    }

    @include breakpoint(large) {
        margin: 2rem 0 8rem;
    }

    @include breakpoint(medium down) {
        margin-left: -20px;
        margin-right: -20px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }

    @include breakpoint(small only) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 320px) {
        grid-template-columns: 1fr;
    }

    .product-card {
        margin-bottom: 1rem;
        flex: 1 1 0;

        @include breakpoint(medium down) {
            margin-bottom: 0;
            border-bottom: 1px solid var(--color-grayscale-2);
        }

        @include breakpoint(medium only) {
            &:not(:nth-child(3n)) {
                border-right: 1px solid var(--color-grayscale-2);
            }
        }

        @include breakpoint(small only) {
            &:nth-child(odd) {
                border-right: 1px solid var(--color-grayscale-2);
            }
        }
    }
}
