.sidebar {
    .title {
        font-size: rem-calc(22);
    }

    li {
        padding: rem-calc(4 0);
    }

    a.active {
        font-weight: bold;
    }

    ul ul {
        margin: rem-calc(8 0);
        padding-left: rem-calc(20);
        border-left: 2px solid #EBEBEB;
    }
}

.simple-page-content {

    .hero {
        margin-bottom: rem-calc(30);
        background-repeat: no-repeat;
        background-size: cover;
        background-color: #F9F9F9;
        background-position: center;

        &:after {
            content: '';
            display: block;
            padding-top: 30%;
            transition: all $normal-transition-type $normal-transition-speed;
        }
    }

    .content-page__title {
        font-size: rem-calc(32);
        font-weight: 400;
        margin-bottom: rem-calc(14);
    }

    ul, ol {
        padding-left: rem-calc(10);

        &.no-padding {
            padding: 0;

            li {
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }

        li {
            padding-left: rem-calc(10);
        }
    }

    h2 {
        font-size: rem-calc(17);
        font-weight: bold;
        margin: 0;
    }

    @media only screen and (max-width: 40em) {

        //To increase image size for mobile just add this class to the hero
        .hero--bigger-for-small {
            &:after {
                padding-top: 62%;
                transition: all $normal-transition-type $normal-transition-speed;
            }
        }
    }
}

.order-delivery-service {
    ul {
        list-style: disc;
        list-style-position: outside;
        padding: 0 0 0 rem-calc(35);

        li {
            padding: 0;
        }
    }

    .indent {
        padding-left: rem-calc(35);
    }
}
