.sub-title {
    font-size: rem-calc(17);
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--color-grayscale-9);

    @include breakpoint(medium down) {
        margin-bottom: rem-calc(16)
    }
}

.light-gray-description {
    font-size: rem-calc(13);
    color: #838383;

    &.invalid {
        color: var(--color-red);
    }
}
