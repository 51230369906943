#user-pull-down {
    right: auto;
    left: 0;

    .site-header--middle--pull-down--content {
        padding: 0.5rem 0;
    }

    ul {
        @include list-reset;
    }

    a {
        display: block;
        padding: 0 1rem;
        height: rem-calc(40);
        line-height: rem-calc(40);
        color: var(--color-grayscale-7);

        &:hover {
            &:not(:focus) {
                text-decoration: none;
            }

            background-color: var(--color-grayscale-1);
            color: var(--color-grayscale-9);
        }
    }
}
