.site-footer--link-list-container {
    @include breakpoint(small only) {
        &:not(.active) {
            .site-footer--link-list {
                display: none;
            }
        }

        &.active {
            .site-footer--heading svg {
                transform: rotate(90deg);
            }
        }
    }
}

.site-footer--heading svg {
    width: rem-calc(12);
    height: rem-calc(12);
    margin-right: rem-calc(5);
    fill: white;

    @include breakpoint(medium up) {
        display: none;
    }
}

.site-footer--link-list {
    ul {
        flex-direction: column;
    }

    &--item { // <a> tags
        display: block;
        line-height: rem-calc(26);

        &:hover {
            color: var(--color-grayscale-9);
            text-decoration: underline;
        }

        @include breakpoint(small only) {
            line-height: rem-calc(32);
            margin: rem-calc(5 0);
        }

        @include absolute-block-pseudo('before') {
            width: 0;
            height: 0;
            top: 50%;
            transform: translateY(-50%);
            left: rem-calc(-15);
            pointer-events: none;
            border-top: rem-calc(6) solid transparent;
            border-bottom: rem-calc(6) solid transparent;
            border-left: rem-calc(6) solid transparent;
        }
    }
}
