.product-card {
    $card-trans-in-delay: 0;
    $card-trans-in: var(--transition-medium-timing) $card-trans-in-delay;
    $card-trans-out: var(--transition-medium-timing);
    $rating-container-height: rem-calc(30);

    @include z-index(product-card);

    @include breakpoint(large) {

        .wishlist-toggle {
            visibility: hidden;
        }

        &--rating-container {
            opacity: 0;
            pointer-events: none;
            transform: translate3d(0, 0, 0) scale(0.9);

            transition: opacity $card-trans-out,
            transform $card-trans-out;
        }

        &--price-container {
            transform: translate3d(0, -1 * $rating-container-height, 0);
            transition: transform $card-trans-out;
        }

        &--flyout-south {
            transform: translate3d(0, -1 * $rating-container-height, 0);
            transition: transform $card-trans-out;
        }

        &--flyout-container {
            opacity: 0;
            pointer-events: none;
            box-shadow: none;
        }

        &:hover {
            @include z-index(product-card-hover);

            .wishlist-toggle {
                visibility: visible;
            }

            .product-card--image-container .aux-image {
                transition: opacity 0s linear $card-trans-in-delay;
                opacity: 1;
            }

            .product-card--rating-container {
                transition: opacity $card-trans-in,
                            transform $card-trans-in;

                transform: translate3d(0, 0, 0);
                opacity: 1;
                pointer-events: all;
            }

            .product-card--price-container {
                transition: transform $card-trans-in;
                transform: translate3d(0,0,0);
            }

            .product-card--flyout-container {
                transition: opacity $card-trans-in,
                            box-shadow $card-trans-in;

                opacity: 1;
                box-shadow: 1px 2px 16px -2px rgba(black, 0.2);
            }

            .product-card--flyout-south {
                transition: transform $card-trans-in;
                transform: translate3d(0, 0, 0);
            }

        }
    }
}
