// Used on the gift-cards page
.quantity-input {
    width: rem-calc(125);
    height: rem-calc(40);
    display: inline-block;

    .reduce, .increase, input {
        height: rem-calc(40);
        float: left;
    }

    .disabled {
        background-color: #f6f6f6;
        cursor: not-allowed !important;
        box-shadow: unset !important;

        &:before {
            background: #919191!important;
        }
    }

    .reduce, .increase {
        cursor: pointer;
        width: rem-calc(40);
        position: relative;
        border: 1px solid var(--color-grayscale-3);
        border-radius: 50%;
        box-shadow: 0 1px 0 0 var(--color-grayscale-3);


        &:before, &:after {
            content: "";
            display: block;
            position: absolute;
            background: $cement;
        }

        &:before {
            height: rem-calc(2);
            width: rem-calc(16);
            top: 50%;
            left: 50%;
            margin-top: -1px;
            margin-left: rem-calc(-8);
        }

        &:not(.disabled):hover {
            background-color: #f6f6f6;
            color: var(--color-grayscale-9);
        }
    }

    .increase {
        &:after {
            width: rem-calc(2);
            height: rem-calc(16);
            left: 50%;
            top: 50%;
            margin-top: rem-calc(-8);
            margin-left: -1px;
        }
    }

    input[type=number] {
        width: rem-calc(45);
        border: 0;
        box-shadow: none;
        text-align: center;
        background: transparent;
        -moz-appearance: textfield;

        &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }

        &:hover, &:active, &:focus {
            background: transparent;
        }
    }

}
