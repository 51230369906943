.order-detail {
    h4 {
        border-bottom: rem-calc(1) solid var(--color-grayscale-3);
        padding-bottom: rem-calc(16);
        margin-bottom: rem-calc(30);
    }

    input {
        box-shadow: none;
    }

    .order-detail-head {
        margin-top: rem-calc(30);
    }

    .continue-to-payment {
        margin: rem-calc(7) 0 rem-calc(-19) 0;
        transition: all .2s ease-in-out;
    }

    .back-to-cart,
    .continue-shopping {
        svg {
            margin-right: rem-calc(16);
            fill: var(--color-grayscale-6);
        }
    }

    .continue-shopping {
        margin-top: rem-calc(7);
        display: inline-block;
    }

    .back-to-cart {
        margin-right: rem-calc(40);
    }

    .seperators {
        margin: rem-calc(18) 0;
        padding: rem-calc(18) 0;
        border-top: rem-calc(1) solid var(--color-grayscale-3);
        border-bottom: rem-calc(1) solid var(--color-grayscale-3);
    }

    .seperator-top {
        padding-top: rem-calc(18);
        border-top: rem-calc(1) solid var(--color-grayscale-3);
    }

    .seperator-bottom {
        padding: rem-calc(4) 0 rem-calc(18) 0 ;
        border-bottom: rem-calc(1) solid var(--color-grayscale-3);
        margin-bottom: rem-calc(20);
    }

    .order-details-column {
        padding-right: rem-calc(40);
    }

}
