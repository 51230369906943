.downloads {
    margin: rem-calc(40 0);

    .title {
        font-size: rem-calc(17);
        font-weight: bold;
    }

    ul {
        margin: 0;
        padding: 0;

        li {
            margin: 0;
            padding: 0;
        }
    }

    &--link {
        display: flex;

        @include sized-svg(20) {
            flex: none;
            fill: var(--color-grayscale-6);
            transition: transform .1s ease-out;
        }
        &:hover {
            svg {
                transform: translateX(3px);
            }
        }
        &:focus {
            text-decoration: none;
            background-color: var(--color-link-focus-background);

            svg {
                transform: translate(3px, 3px);
            }
        }

        .light-grey {
            color: #838383;
        }

    }
}
