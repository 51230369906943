//noinspection SassScssResolvedByNameOnly
.shopping-list-large {
    $img-square-size: rem-calc(110);
    $small-img-square-size: rem-calc(64);

    &--item {
        border-top: rem-calc(1) solid var(--color-grayscale-2);
        padding: rem-calc(24) 0;

        @include centered-flex-row(space-between, $children-display: false);

        @include breakpoint(small only) {
            align-items: flex-start;

            &:last-child {
                border-bottom: rem-calc(1) solid var(--color-grayscale-2);
            }
        }

        &--image-link {
            flex: none;
            @include centered-flex-row;

            width: $img-square-size;
            height: $img-square-size;
            margin-right: rem-calc(30);

            @include breakpoint(small only) {
                width: $small-img-square-size;
                height: $small-img-square-size;
                margin-right: rem-calc(10);
            }

            &:hover {
                @include absolute-block-pseudo('after') {
                    @include absolute-overlay();
                    background-color: rgba(black, 0.05);
                }
            }

            &:focus {
                @include focus-style-through-outline;
            }

            img {
                display: block;
                max-width: 100%;
                max-height: 100%;

                &.removed {
                    opacity: .2;
                }
            }
        }

        &--content {
            flex: 1 1 0;
        }

        // ------------------------------------------------------------------------------
        //      Top content
        // ------------------------------------------------------------------------------

        &--content-top {
            @include centered-flex-row(space-between, flex-start);
            margin-bottom: 1rem;

            &--left { flex: 1 1 0 }
            &--right { flex: none }
        }

        &--title {
            font-weight: bold;
        }

        &--icon-button {
            margin: 0;
            padding: 0;
            width: rem-calc(32);
            height: rem-calc(32);
            background-color: transparent;
            transition: background-color .1s ease-out, border-bottom-color .1s ease-out;
            border-bottom: rem-calc(2) solid transparent;

            @include centered-flex-row();

            svg {
                transition: fill .1s ease-out;
                fill: var(--color-grayscale-5);
                display: block;
                width: rem-calc(22);
                height: rem-calc(22);
            }

            &:hover {
                background-color: var(--color-grayscale-1);

                svg { fill: var(--color-grayscale-9) }
            }

            &:focus {
                border-bottom-color: var(--color-grayscale-9);

                &:not(:hover) {
                    background-color: transparent;
                }
            }
        }

        // ------------------------------------------------------------------------------
        //      Bottom content
        // ------------------------------------------------------------------------------

        &--content-bottom {
            @include centered-flex-row(space-between, $wrap: wrap);
            margin: rem-calc(-5);

            @include breakpoint(small only) {
                align-items: flex-start;
                flex-direction: column;
                gap: 4px;
            }

            &--left,
            &--right {
                margin: rem-calc(5);
                flex: none;

                @include breakpoint(small only) {
                    //margin-left: 0;
                }
            }

            &--left {
                .sub-price-notice {
                    margin-top: rem-calc(8);
                    font-size: rem-calc(13);
                    color: var(--color-grayscale-6);
                }

                @include breakpoint(small only) {
                    flex: 1 1 0;

                    .price-display {
                        line-height: rem-calc(34);
                    }
                }
            }

            &--right {
                display: flex;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;

                @include breakpoint(small only) {
                    width: 100%;
                }

                &--sold-out-actions {
                    display: flex;
                    flex-direction: column;
                    gap: 16px;

                    @include breakpoint(medium up) {
                        flex-direction: row;
                        gap: 32px;
                    }
                }
            }

            .price-display {
                justify-content: flex-start;
            }
        }

        &--label {
            margin-right: 1rem;

            @include breakpoint(small only) {
                display: none;
            }
        }

        &--quantity {
            width: rem-calc(130);
            margin-right: rem-calc(20);

            @include breakpoint(small only) {
                margin-right: auto;
            }
        }

        &--total-price {
            @include centered-flex-row(flex-end);
            min-width: rem-calc(80);
            font-weight: 600;

            @include breakpoint(small only) {
                min-width: 0;
                margin-right: 16px;
            }
        }

        @include breakpoint(large) {
            .on-hover-only {

                transform: scale(0.9,0.9);
                opacity: 0;
                transition: opacity .1s ease-out, transform .1s ease-out;

                &:focus-within,
                &.focus-within
                {
                    transform: none;
                    opacity: 1;
                }
            }

            &:hover .on-hover-only {
                transform: none;
                opacity: 1;
            }
        }
    }
}
