@import "partials/recommendations-list";

.shopping-cart {
    margin-top: rem-calc(40);
    margin-bottom: rem-calc(40);

    // ------------------------------------------------------------------------------
    //      Blocks
    // ------------------------------------------------------------------------------

    &--block {
        &--content {
            padding: rem-calc(40);

            @include breakpoint(small only) {
                padding: 1rem;
            }

            background-color: white;
        }

        &--footer {
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding: rem-calc(24 40);

            @include breakpoint(small only) {
                padding: 1rem;
            }

            background-color: var(--color-grayscale-2);
        }
    }

    // ------------------------------------------------------------------------------
    //      Empty carts
    // ------------------------------------------------------------------------------

    &--empty {
        @include centered-flex-column;
        padding: rem-calc(80);

        @include breakpoint(small only) {
            padding: rem-calc(40);
        }

        @media screen and (max-width: 400px) {
            padding: rem-calc(40 20);
        }

        &--image-container {
            position: relative;
            transform: translateX(30px);

            &--wishlist {
                transform: unset;
            }
        }

        &--counter {
            position: absolute;
            top: rem-calc(-10);
            right: rem-calc(30);
            @include circle(34);
            line-height: rem-calc(30);
            font-weight: bold;
            color: white;
            background-color: var(--color-red);
            font-size: rem-calc(24);
            text-align: center;
        }

        &--image {
            max-width: rem-calc(220);
        }

        &--title {
            text-align: center;
            margin: 1rem 0;
            font-size: rem-calc(24);

            @include breakpoint(medium up) {
                font-size: rem-calc(34);
            }
        }

        &--description {
            text-align: center;
            max-width: rem-calc(600);

            @include sized-svg(20) {
                display: inline-block;
                fill: var(--color-grayscale-4);
                margin: 0 rem-calc(5);
            }
        }
    }

    // ------------------------------------------------------------------------------
    //      Non-empty carts
    // ------------------------------------------------------------------------------

    &--nonempty {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-column-gap: rem-calc(40);
        grid-template-areas:
            'list             list             summary'
            'usps             usps             summary'
            'recommendations  recommendations  summary'
            'gift-cards       gift-cards       summary'
            ;

        @include breakpoint(medium down) {
            grid-template-columns: 1fr;
            grid-template-areas:
                'list'
                'usps'
                'recommendations'
                'summary'
                'gift-cards'
                ;
        }

        .shopping-cart--list {
            grid-area: list;
        }

        .shopping-cart--recommendations {
            &:empty {
                display: none;
            }

            grid-area: recommendations;
            margin-bottom: rem-calc(40);
        }

        .shopping-cart--gift-cards {
            grid-area: gift-cards;
            @include breakpoint(medium down) {
                margin-top: rem-calc(40);
            }
        }

        .shopping-cart--summary-track {
            grid-area: summary;
        }

        .shopping-cart--pick-another-suggestion {
            font-size: rem-calc(13);
        }
    }

    &--removed-item-warning {
        background-color: var(--color-grayscale-2);
        display: flex;
        align-items: center;
        gap: 8px;
        position: relative;
        padding: 12px 16px;
        margin-top: 16px;
        margin-bottom: 16px;

        @include breakpoint(medium up) {
            padding: 16px;
            margin-top: 12px;
            margin-bottom: 24px;
        }

        &::before {
            position: absolute;
            display: block;
            content: '';
            border-left: 0.0666666667rem solid var(--color-grayscale-2);
            border-bottom: 0.0666666667rem solid var(--color-grayscale-2);
            background-color: var(--color-grayscale-2);
            width: 1.0666666667rem;
            height: 1.0666666667rem;
            top: 0;
            left: 24px;
            transform: translate(-50%, -50%) rotate(45deg);
        }

        .icon-wrapper {
            width: 16px;
            height: 18px;

            svg {
                fill: var(--color-grayscale-6);
                width: 100%;
                height: 100%;
            }
        }

        p {
            margin: 0;
            line-height: 18px;
        }
    }

    &--call-to-action {
        display: grid;
        gap: 1em;
        grid-template-columns: minmax(0, 1fr) 50%;
        margin-bottom: 15px;

        a, button {
            width: 100%;
        }

        &--mobile {
            @include breakpoint(medium up) {
                display: none;
            }

            display: flex;
            gap: rem-calc(12);
            padding-bottom: rem-calc(12);

            form, a {
                width: 100%;
            }

            @media only screen and (max-width: 340px) {
                flex-wrap: wrap;
            }
        }
    }

    .gift-card-section {
        &--intro {
            &, h2 {
                margin: 0;
            }

            img {
                max-width: rem-calc(180);
                flex: none;
            }
        }

        &--description {
            @include centered-flex-row(space-between);
            gap: rem-calc(40);
        }
    }

    &--total-price-section {
        @include breakpoint(medium only) {
            margin-top: rem-calc(40);
        }
    }

    &--kiyoh {
        margin-bottom: 1rem;
    }

    &-usps {
        grid-area: usps;
        padding: rem-calc(16) 0;

        &--list {
            display: flex;
            justify-content: space-around;
        }

        &--item {
            display: flex;
            align-items: center;

            .usp-bar--icon {
                width: rem-calc(24);
                height: auto;
                margin-right: rem-calc(8);
            }
        }

        @include breakpoint(medium down) {
            padding: rem-calc(16);

            &--list {
                flex-direction: column;
                gap: 4px;
            }
        }
    }
}
