.accordion.questions {
    margin-bottom: 2rem;

    .answer,
    .accordion .accordion-navigation > .content {
        padding: rem-calc(10 0 0 30);

        p:last-of-type {
            margin: 0;
        }
    }

    .accordion-navigation {
        padding: rem-calc(8 0);
    }

    .question {
        @include centered-flex-row(flex-start);
        position: relative;

        .title {
            display: inline-block;
            font-size: rem-calc(17);
            margin: 0;
            padding-left: rem-calc(28);
            cursor: pointer;
        }

        &:hover,
        &:focus {
            text-decoration: none;
        }

        &--bullet {
            position: absolute;
            left: 0;
            top: rem-calc(5);
            cursor: pointer;

            @include circle(18);
            background-color: var(--color-grayscale-9);

            &--plus, &--minus {
                width: 100%;
                height: 100%;
                @include centered-flex-row();
                @include sized-svg(16) {
                    fill: var(--color-white);
                }
            }

            &--minus {
                display: none;
            }
        }
    }

    details[open] {
        .question--bullet--minus {
            display: block;
        }

        .question--bullet--plus {
            display: none;
        }
    }
}

.accordion-content {
    padding-top: 15px;
}

details.after-icon {
    position: relative;

    summary {
        &::after {
            content: '▼';
            position: absolute;
            margin-left: 8px;
        }
    }

    &[open] summary::after {
        content: '▲ ';
    }
}
