.no-margin {
  margin: 0 !important;
}

.no-border {
  border: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.show-when-collapsed {
    display: var(--show-when-collapsed);
}

.hide-when-collapsed {
    display: var(--hide-when-collapsed);
}

.text-sale-red {
    color: var(--color-sale-red);
}
