.password-field-container {

    position: relative;
    padding-right: var(--input-height);
    margin-bottom: 1rem;

    &--button,
    &--counter
    {
        position: absolute;
        bottom: 0;
        right: 0;
        width: var(--input-height);
        height: var(--input-height);
        transition: none;

        background-color: transparent;
        border: 1px solid var(--color-grayscale-5);
        border-left: none;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        user-select: none;
        cursor: default;

        @include centered-flex-row();

        svg {
            display: block;
        }
    }

    &--button {
        margin: 0;
        padding: 0;
        @include sized-svg(20) {
            fill: var(--color-grayscale-6);
        }

        &:hover,
        &:focus
        {
            background-color: transparent;
            outline: none;
        }

        &:hover {
            svg {
                fill: var(--color-grayscale-9);
            }
        }
    }

    .input-wrapper {
        margin-bottom: 0;
    }

    .input-wrapper--input {
        letter-spacing: 1px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}
