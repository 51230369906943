.account-default {

    margin-bottom: 4rem;

    .hero-button {

        background-color: var(--color-grayscale-1);
        color: var(--color-grayscale-9);

        &:hover {
            background-color: white;
        }
    }

    &--title,
    &--description
    {
        max-width: rem-calc(420) !important;
        color: white;
    }

    $hero-overflow-x: 4rem;

    &--hero,
    &--hero-content
    {
        height: rem-calc(320);

        @include breakpoint(small only) {
            height: auto;
        }
    }

    &--hero {
        @include centered-cover-background;
        border-radius: rem-calc(6);
        overflow: hidden;

        width: 100%;
        padding: 0.5 * $grid-column-gutter;

        @include absolute-block-pseudo('before') {
            z-index: 0;
            background: rgba(black, 0.6);

            @include absolute-overlay();
        }

        @include breakpoint(medium up) {
            padding: rem-calc(0 40);

            &::before {
                background: linear-gradient(
                        to left,
                        rgba(black, 0.1) 40%,
                        rgba(black, 0.7) 100%
                );
            }
        }
    }

    &--hero-content {
        @include centered-flex-row(space-between);
        position: relative;
        z-index: 1;

        @include breakpoint(small only) {
            @include centered-flex-column(center, flex-start);
        }
    }

    &--content {

        @include breakpoint(medium up) {
            padding: rem-calc(0 40);
        }
    }

    &--detail-heading {
        font-size: 1rem;
        font-weight: 600;
        margin: rem-calc(24 0 0);
    }

    .content-block--title {
        border-bottom: none;

        @include centered-flex-row(flex-start);
        @include sized-svg(24) {
            flex: none;
            transform: translateY(2px); // Optical alignment tweak
            fill: var(--color-grayscale-9);
            margin-right: 1rem;
        }

        @include breakpoint(small only) {
            font-size: rem-calc(20);

            svg {
                width: rem-calc(20);
                height: rem-calc(20);
            }
        }
    }
}
