.social-media-links {
    @include centered-flex-row(flex-start);
    margin: -0.5rem;

    @include breakpoint(small only) {
        margin-bottom: 1.5rem;
    }

    .social-media-links--item {
        margin: 0.5rem;
        padding: 0;
        width: rem-calc(40);
        height: rem-calc(40);
        border-radius: 50%;
        background-color: var(--color-white);
        transition: none;

        @include sized-svg(40) {
            fill: var(--color-grayscale-9);
        }
    }
}
