.header-notification {
    background-color: #c7a75c;

    @include breakpoint(large) {
        transform: translateY(-2rem); // occupy the desktop-header bottom-margin region
    }

    @include breakpoint(medium down) {
        transform: translateY(-1rem); // occupy the mobile-header bottom-margin region
    }

    &--content {
        @include breakpoint(medium down) {
            font-size: rem-calc(13);
        }

        margin: 0;
        padding: 1rem;
        color: white;
        text-align: center;
        a {
            color: white;
            font-weight: bold;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
