.shopping-list-mini {
    $product-spacing: rem-calc(20);
    $image-container-size: rem-calc(60);
    @include list-reset;
    font-size: rem-calc(15);

    &--item {
        user-select: none;
        position: relative;
        padding: rem-calc(5 0);

        &:not(:first-child) {
            padding-top: rem-calc(20);
        }

        &:not(:last-child) {
            border-bottom: rem-calc(1) solid transparent;
        }

        transition: border-color .1s ease-out;
        display: flex;

        &--image {
            @include centered-flex-row();
            width: $image-container-size;
            height: $image-container-size;
            margin-right: $product-spacing;
            flex: none;

            img {
                display: block;
                max-width: 100%;
                max-height: 100%;
            }
        }

        &--textual {
            flex: 1 1 auto;
            margin-right: rem-calc(20);
        }

        &--title,
        &--description
        {
            line-height: rem-calc(22);
            display: block;
        }

        &--title {
            font-weight: bold;
            padding-right: rem-calc(4);
        }

        &--description {
            color: var(--color-grayscale-6);
            font-size: rem-calc(14);
        }

        &--total-price {
            flex: none;
            font-weight: bold;
            line-height: rem-calc(22);
            text-align: right;

            &.green {
                color: var(--color-green);
            }
        }

        &--actions {
            position: absolute;
            bottom: 0.5 * $product-spacing;
            right: 0;
            @include centered-flex-row;
            margin: rem-calc(-5);
        }

        &:hover {
            text-decoration: none;
        }

        &--static {
            // This modifier flags an item as 'static': without interaction styles such
            // as hover background or action buttons display.
        }

        &:not(&--static):hover,
        &:not(&--static):focus-within,
        &:not(&--static).focus-within {
            border-color: var(--color-grayscale-2);

            .shopping-list-mini--icon-button {
                opacity: 1;
                pointer-events: all;
            }
        }
    }

    &--icon-button {
        opacity: 0;
        pointer-events: none;
        transition: opacity .1s ease-out;
        margin: rem-calc(5);
        padding: 0;
        background-color: white;
        width: rem-calc(30);
        height: rem-calc(30);

        @include centered-flex-row;
        flex-direction: column;

        border-radius: 4px;
        border: 1px solid var(--color-grayscale-5);
        border-bottom-width: 2px;

        svg {
            transition: fill .1s ease-out;
            fill: var(--color-grayscale-5);
            display: block;
            width: rem-calc(18);
            height: rem-calc(18);
        }

        &:hover {
            background-color: transparent;
            border-color: var(--color-grayscale-7);

            svg {
                fill: var(--color-grayscale-7);
            }
        }

        &.delete-button:hover {
            border-color: var(--color-red);

            svg {
                fill: var(--color-red);
            }
        }

        @keyframes pulse-red-border {
            0%, 30% { border-color: var(--color-grayscale-5) }
            to { border-color: var(--color-red) }
        }

        @keyframes pulse-border {
            0%, 30% { border-color: var(--color-grayscale-5) }
            to { border-color: var(--color-grayscale-7) }
        }

        @keyframes pulse-svg {
            0%, 30% { fill: var(--color-grayscale-5) }
            to { fill: var(--color-grayscale-7) }
        }

        @keyframes pulse-red-svg {
            0%, 30% { fill: var(--color-grayscale-5) }
            to { fill: var(--color-red) }
        }

        &:focus {
            pointer-events: all;
            opacity: 1;
            background-color: transparent;

            &:not(:hover) {

                animation: pulse-border 0.6s infinite alternate;
                svg {
                    animation: pulse-svg 0.6s infinite alternate;
                }

                &.delete-button {
                    animation: pulse-red-border 0.6s infinite alternate;

                    svg {
                        animation: pulse-red-svg 0.6s infinite alternate;
                    }
                }
            }
        }
    }
}

.shopping-list-mini-scrollable {
    overflow-y: hidden;
    max-height: 14rem;
}
