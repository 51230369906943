.webatelier-configuration-block {
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 15%);
    background-color: white;

    .wcb--properties {
        display: flex;
        flex-wrap: wrap;
        padding: 1rem 2rem;
    }

    .wcb--property {
        width: 320px;
        margin: 10px;
        display: grid;
        grid-template-columns: auto 1fr;
        grid-column-gap: 1rem;
        grid-template-rows: 1fr 1fr;
        grid-template-areas: "image heading"
                             "image value";
    }

    .wcb--image {
        grid-area: image;
        border: 1px solid #eee;
        border-radius: 8px;
        padding: 5px;
        @include square(80);

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
        }
    }

    .wcb--heading {
        font-weight: 600;
        grid-area: heading;
        margin: 0;
        align-self: end;
    }

    .wcb--value {
        grid-area: value;
        white-space: nowrap;
        margin: 0;
        align-self: start;
    }

    .wcb--actions {
        border-top: 1px solid #eee;
        padding: 1rem 2rem;
        @include centered-flex-row(space-between, $children-display: false);
    }

    .price-display {
        --pd-price-font-size: 32px;
    }
}

.back-to-order-btn {
    margin-top: 1rem;
}
