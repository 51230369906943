#cart-pull-down {
    max-height: calc(100vh - #{rem-calc(140)});

    .empty-content {
        padding: 0.5rem;
        margin: 0;
        text-align: center;

        &--title {
            font-size: rem-calc(17);
        }

        &--description {
            font-size: rem-calc(15);
            color: var(--color-grayscale-6);
        }
    }

    .empty-footer {
        width: 100%;
        text-align: center;
        flex: none;
        padding: 0.5rem;

        &--title,
        &--button
        {
            display: block;
        }

        &--title {
            font-weight: 600;
            margin: 0 0 0.5rem;
            font-size: rem-calc(17);
        }

        &--button {
            margin: 0;
            border: rem-calc(1) solid var(--color-grayscale-5);

            &:hover {
                background-color: var(--color-grayscale-1);
                text-decoration: none;
            }
        }
    }

    .button {
        padding: 0 16px;
    }
}
