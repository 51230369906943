.banner-video {
    position: relative;
    display: flex;
    justify-content: center;

    &__container {
        display: flex;
        justify-content: center;
        padding-left: 20px;
        padding-right: 20px;

        .horizontal, .vertical, .square {
            min-height: rem-calc(180);
        }

        .horizontal {
            width: 100%;
            max-height: rem-calc(506);

            &:before {
                content: "";
                display: block;
                padding-top: 56.25%; // 16:9 Aspect Ratio
            }
        }

        .vertical {
            width: 50%;
            max-height: rem-calc(680);

            &:before {
                content: "";
                display: block;
                padding-top: 177.77%; // 9:16 Aspect Ratio
            }

            &--mobile {
                width: 100%;
                max-height: rem-calc(506);
            }
        }

        .square {
            width: 75%;
            max-height: rem-calc(506);

            &:before {
                content: "";
                display: block;
                padding-top: 100%; // 1:1 Aspect Ratio
            }

            &--mobile {
                width: 100%;
            }
        }
    }

    &.has-link {
        cursor: pointer;
    }

    &__content {
        @include absolute-overlay();
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: center;
        gap: 6px;
        width: 100%;
        height: 100%;
        padding: rem-calc(24);
        z-index: 1;
    }

    &__title {
        font-size: clamp(24px, 3vw, 32px);
        color: var(--color-white);
        margin: 0;
        line-height: 1.2;
        font-family: var(--font-family-primary);
        font-weight: var(--font-weight-bold);
        letter-spacing: rem-calc(1);

        @include breakpoint(medium down) {
            line-height: initial;
        }
    }

    &__description {
        color: var(--color-white);
        font-size: rem-calc(13);
        font-family: var(--font-family-primary);
    }

    .button {
        max-width: fit-content;
    }

    &__video, &__placeholder {
        display: none;
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;

        &.active {
            display: block;
        }
    }

    &__placeholder {
        display: block;
    }

    .banner-video__controls {
        display: none;

        &.active {
            display: block;
        }
    }

    &__meta {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 3;
        padding: rem-calc(64px);
        width: 80%;

        @include breakpoint(medium down) {
            padding: rem-calc(40px);
        }

        @include breakpoint(small only) {
            padding: rem-calc(24px);
        }

        h2 {
            margin: 0 0 22px;
            color: var(--color-white);
            text-shadow: 0 0 10px var(--color-black);
            font-size: 22px;

            @include breakpoint(medium up) {
                font-size: 32px;
            }
        }

        .button {
            background-color: var(--color-white) !important;
        }
    }

    &__desktop-only {
        display: none;
    }

    &__controls {
        @include absolute-overlay();

        button {
            opacity: 1;
            transition: opacity 250ms ease-in-out;
            position: absolute;
            margin-bottom: 0;
            right: rem-calc(16);
            bottom: rem-calc(16);
            padding: rem-calc(4);
            border-radius: 50%;
            width: rem-calc(48);
            height: rem-calc(48);
            z-index: 2;
            background-color: var(--color-white);
            border: 1px solid var(--color-grayscale-3);

            svg {
                fill: var(--color-grayscale-9);
                width: 100%;
                height: 100%;
            }
        }
    }

    &__overlay {
        @include absolute-overlay();

        &--darken {
            background-color: var(--color-black);
            opacity: 0.25;
        }
    }
}

@include breakpoint(medium down) {
    .banner-video {
        &__description {
            display: none;
        }
    }
}

@media only screen and (min-width: 512px) {
    .banner-video {

        &__description, &__btn {
            font-size: rem-calc(16);
        }
    }
}

@include breakpoint(medium up) {
    .banner-video__desktop-only {
        display: block;
    }
}

@include breakpoint(large) {
    .banner-video {
        &__description {
            font-size: rem-calc(20);
            margin-bottom: rem-calc(8);
        }

        &__btn {
            font-weight: bold;
            font-size: rem-calc(18);
            line-height: rem-calc(24);
            letter-spacing: rem-calc(2.5);
            margin-top: rem-calc(16);
        }

        &__content {
            padding: rem-calc(40);
        }

        &__controls button {
            opacity: 0;
            right: rem-calc(40);
            bottom: rem-calc(40);
        }

        &:hover {
            .banner-video__controls button {
                opacity: 1;
            }
        }
    }
}
