.order-detail {

    padding: rem-calc(24);

    .content-block--title {
        font-size: rem-calc(24);

        &.no-margin {
            margin-bottom: 0;
        }
    }

    .order-detail-summary {
        @include centered-flex-row(space-between, $wrap: wrap);
        border-top: 1px solid var(--color-grayscale-2);
        border-bottom: 1px solid var(--color-grayscale-2);
        margin-bottom: 2rem;
        > div {
            padding: 1rem;
        }
    }

    .order-compact {
        border: none;
        padding: 0;
        margin: rem-calc(-12); // Compensate for padding of order-extended
    }
    background-color: white;

    .address-lines {
        margin-left: rem-calc(40);
    }

    &--content {
        padding-top: 2rem;
        display: flex;
    }

    &--toggle {
        padding-left: 0 !important;
    }
}
