.b2b-request-form{
  background-color: var(--color-grayscale-1);
  border: 1px solid var(--color-grayscale-2);
  display: flex;
  flex-direction: column;
  padding: rem-calc(40);

  > .row {
    display: flex;
    align-items: flex-start;
    margin-bottom: rem-calc(10);

    label{
      font-size: rem-calc(17);
    }

    .text-area__label{
      display: flex;
      align-self: flex-start;
    }
  }

  .form-title{
    font-size: rem-calc(26);
    font-weight: 400;
    line-height: rem-calc(26);
    margin-bottom: rem-calc(30);
  }

  textarea{
    resize: none;
  }

  .business-form__send{
    display: flex;
    justify-content: flex-end;
  }
}

//Mobile Fix
//TODO replace with foundation media query
@media screen and (max-width: 640px){
  #b2b-request-form{
    >.row{
      flex-direction: column;

      .column{
        &:first-of-type{
          margin-bottom: rem-calc(5);
        }
      }
    }
  }
}

@media screen and (max-width: 480px){
  #b2b-request-form{
    padding: rem-calc(15);
  }
}


.business {
    &-content {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 20px;

        &--flow {
            margin-top: 32px;
            background-color: var(--color-grayscale-1);

            @include breakpoint(small only) {
                margin-top: 24px;
            }

            @include breakpoint(large) {
                padding-top: 40px;
            }
        }
    }

    &-usps {
        margin-bottom: 20px;

        h6 {
            font-weight: bold;
        }
    }
}

.business {
    &-content {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 20px;

        &--flow {
            margin-top: 32px;
            background-color: var(--color-grayscale-1);

            @include breakpoint(small only) {
                margin-top: 24px;
            }

            @include breakpoint(large) {
                padding-top: 40px;
            }
        }
    }

    &-usps {
        margin-bottom: 20px;

        h6 {
            font-weight: bold;
        }
    }
}
