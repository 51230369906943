.product-point-popover {
    padding: 2rem;
    width: 100% !important;
    max-width: rem-calc(288) !important;
    min-width: rem-calc(270) !important;
    margin: 0 auto;
    right: 0;
    left: 0;

    .cover {
        position: relative;
        margin-bottom: rem-calc(16);
        @include centered-contain-background;
        @include centered-flex-row;
        height: rem-calc(160);
        @include sized-svg(16) {
            fill: #D9D9D9;
            z-index: -1;
        }
    }

    .title-section {
        margin-bottom: rem-calc(16);
    }

    .title {
        font-size: rem-calc(15);
        margin: 0;
    }

    .price-display {
        justify-content: flex-start;
        font-size: rem-calc(20);
        margin-bottom: rem-calc(25);

        > * {
            margin: 0;
        }
    }

    .unit-quantity-notification {
        transform: translateY(-1rem);
        display: block;
        font-size: rem-calc(13);
        color: var(--color-grayscale-6);
    }

    .variants {
        ul {
            display: flex;
            flex-wrap: wrap;
            margin: rem-calc(0 -2 20);

            li {
                border: 1px solid var(--color-grayscale-2);
                padding: rem-calc(2);
                margin: rem-calc(2);
            }

            a {
                @include centered-contain-background;
                @include square(40);
                @include centered-flex-row;
                @include sized-svg(16) {
                    fill: var(--color-grayscale-6);
                    z-index: -1;
                }
            }
        }
    }

    .sold-out {
        margin-bottom: rem-calc(4);
        font-size: rem-calc(13);
    }

    .actions {
        @include centered-flex-column(flex-start);
        gap: rem-calc(8);
        width: 100%;

        a, button  {
            padding: 0 8px !important;
            width: 100%;
        }

        form {
            padding: 0;
            width: 100%;
        }

    }
}
