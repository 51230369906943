#notify-me-modal {
    width: 29%;
    min-width: 374px;
    text-align: center;
    max-width: 493px;

    & > .row {
        margin-top: 32px !important;
    }

    .check-icon {
        position: absolute;
        top: -8px;
        left: 0;
        right: 0;

        border-radius: 50%;
        border: #30a980 solid 3px;
        height: 32px;
        width: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        margin-top: 32px;
        margin-bottom: 8px;

        svg {
            display: inline-block;
            width: rem-calc(16px);
            height: rem-calc(20px);
            fill: var(--color-green);
        }
    }

    h3 {
        margin-bottom: 0;
    }

    button {
        width: 100%;
        margin-top: 16px !important;
        display: flex !important;
        justify-content: center;
        align-items: center;
        gap: 8px;

        svg {
            width: 18px;
        }
    }
}
