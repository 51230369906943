button {
    @extend .button;
}

.button-container {
    margin: rem-calc(-10);
    display: flex;
    flex-wrap: wrap;

    > .button.button,
    // Button substitute explicitly indicates the intent to have any element behave like a button
    // in the context of a button-container
    > .button-substitute {
        margin: rem-calc(10);
    }

    &.center {
        justify-content: center;
    }
}

// Nice, more specificity bumps ... because foundation
.button.button {
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
    font-family: inherit;
    text-align: center;
    white-space: nowrap;

    box-sizing: border-box;
    border: rem-calc(1) solid transparent;
    border-radius: rem-calc(4);

    transition:
        background-color $normal-transition-type $normal-transition-speed,
        border-color $normal-transition-type $normal-transition-speed,
        color $normal-transition-type $normal-transition-speed
    ;

    &:hover,
    &:focus {
        text-decoration: none;
        cursor: pointer;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }

    &--full-width {
        display: block;
        width: 100%;
    }

    &--icon {
        display: flex;
        gap: rem-calc(8);
        justify-content: center;

        svg {
            width: rem-calc(18);
            height: 100%;
        }
    }

    // ------------------------------------------------------------------------------
    //      Sizes
    // ------------------------------------------------------------------------------

    @mixin default-button-dimensions {
        font-size: 1rem;
        height: rem-calc(40);
        line-height: rem-calc(38);
    }

    &.small {
        font-size: rem-calc(13);
        padding: rem-calc(0 30);
        height: rem-calc(34);
        line-height: rem-calc(32);
    }

    &.small-height {
        height: 1.25rem !important;
    }

    &.square {
        padding: rem-calc(0 12);
        font-size: rem-calc(15);
        height: rem-calc(40);
        width: rem-calc(40);
        line-height: rem-calc(32);
    }

    &.medium, &.large {
        @include default-button-dimensions;
    }

    &.medium {
        padding: rem-calc(0 16);
    }

    &.large {
        padding: rem-calc(0 40);

        &:not(.toggle-button):not(.icon-button) {
            @include breakpoint(small only) {
                padding: rem-calc(0 20);
            }
        }
    }

    @include breakpoint(medium up) {
        &.large {
            padding: rem-calc(0 50);
            font-size: rem-calc(17);
            height: rem-calc(48);
            line-height: rem-calc(48);
        }
    }

    // ------------------------------------------------------------------------------
    //      Colored buttons
    // ------------------------------------------------------------------------------

    &--full-width {
        width: 100%;
        display: block;
    }

    &.border-button {
        background-color: transparent;
        border: 1px solid var(--color-grayscale-3);
        border-bottom-width: 2px;
        color: var(--color-grayscale-7);

        svg {
            fill: var(--color-grayscale-7);
        }

        &:not(:disabled):hover {
            border-color: var(--color-grayscale-6);
            color: var(--color-grayscale-9);

            svg {
                fill: var(--color-grayscale-9);
            }
        }
    }

    &.dark {
        background-color: var(--color-grayscale-9);
        color: var(--color-white);

        &:not(:disabled):not(.disabled):not(:disabled):hover {
            background-color: var(--color-grayscale-7);
        }

        svg {
            fill: var(--color-white);
        }
    }

    &.red {
        background-color: var(--color-red);
        color: var(--color-white);

        &:hover {
            background-color: darken($red, 5%);
        }

        svg {
            fill: var(--color-white);
        }
    }

    &.green {
        background-color: var(--color-green);
        box-shadow: inset 0 -1px 0 0 rgba(black, 0.2);
        border-bottom-color: rgba(black, 0.2);
        color: var(--color-white);

        &:not(:disabled):not(.disabled):hover {
            background-color: darken($green, 5%);
        }

        svg {
            fill: var(--color-white);
        }
    }

    &.light-disabled {
        background-color: #a7a6a7;
        box-shadow: inset 0 -1px 0 0 rgba(black, 0.1);
        border-bottom-color: rgba(black, 0.1);
        color: var(--color-white);

        svg {
            fill: var(--color-white);
        }

        cursor: not-allowed;
    }

    &.white {
        background-color: var(--color-white);
        color: var(--color-grayscale-9);

        &:hover {
            background-color: darken(#ffffff, 5%);
        }

        svg {
            fill: var(--color-grayscale-9);
        }
    }

    // ------------------------------------------------------------------------------
    //      Clear buttons
    // ------------------------------------------------------------------------------

    &.ghost-button,
    &.indent-button,
    &.clear-button,
    &.toggle-button {
        background-color: transparent;
        color: var(--color-grayscale-9);

        svg {
            fill: var(--color-grayscale-7);
        }

        &:not(:disabled):hover {
            text-decoration: underline;
        }
    }

    &.ghost-button,
    &.indent-button,
    &.clear-button {
        padding-left: 0 !important;
        padding-right: 0 !important;

        &.hover-block {
            padding-left: inherit;
            padding-right: inherit;

            &:hover {
                text-decoration: none;
                background-color: var(--color-grayscale-2);
            }
        }
    }

    &.ghost-button:hover {
        background-color: var(--color-grayscale-1);
    }

    // ------------------------------------------------------------------------------
    //      Arrow buttons
    // ------------------------------------------------------------------------------

    $arrow-button-arrow-spacing: rem-calc(14);

    &.toggle-button {

        @include absolute-block-pseudo('after') {
            top: 50%;
            right: rem-calc(12);
            width: 0;
            height: 0;

            // Arrow
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid var(--color-grayscale-6);

            // Visual weight distribution
            transform: translateY(-2px);
            transform-origin: center 2px;
        }

        &.toggled::after {
            transform: rotate(180deg);
        }
    }

    &.return-button,
    &.proceed-button
    {
        color: var(--color-grayscale-9);
        background-color: transparent;
        padding: 0;

        &:hover {
            background-color: transparent;
            text-decoration: underline;
        }

        @include absolute-block-pseudo('after') {
            top: 50%;
            transform: translateY(-50%);
            width: 0;
            height: 0;
            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;
            border-left: 5px solid var(--color-grayscale-5);
        }
    }

    &.proceed-button,
    &.return-button.reversed {
        padding-left: 0;
        padding-right: $arrow-button-arrow-spacing;

        &::after {
            right: 0;
        }
    }

    &.return-button,
    &.proceed-button.reversed {
        padding-left: $arrow-button-arrow-spacing;
        padding-right: 0;

        &::after {
            left: 0;
        }
    }

    &.return-button::after {
        transform: translateY(-50%) rotate(180deg);
    }

    &:focus,
    &:focus-visible {
        outline: none;
    }

    &:focus-visible {
        @include focus-box-shadow;
    }

    // ------------------------------------------------------------------------------
    //      SVG buttons (with an SVG + text)
    // ------------------------------------------------------------------------------

    &.svg-button {
        @include centered-flex-row($display: inline-flex);

        svg:not(:only-child) {
            &:not(:last-child) {
                margin-right: 0.5rem;
            }

            &:not(:first-child) {
                margin-left: 0.5rem;
            }
        }

        &.small svg {
            width: rem-calc(16);
            height: rem-calc(16);
        }

        &.square svg {
            width: rem-calc(16);
            height: rem-calc(16);
        }

        &.medium svg {
            width: rem-calc(20);
            height: rem-calc(20);
        }

        &.large svg {
            @include square(20);

            @include breakpoint(medium up) {
                @include square(24);
            }
        }
    }

    // ------------------------------------------------------------------------------
    //      Icon buttons (SVG only)
    // ------------------------------------------------------------------------------

    &.icon-red, &.icon-red:hover {
        svg {
            fill: var(--color-red) !important;
        }
    }

    &.icon-button {
        display: inline-flex;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        width: rem-calc(40);
        padding: rem-calc(9); // (40 - 20) / 2 - 1

        svg {
            display: block;
        }

        &.medium, &.large {
            @include square(40);

            svg {
                @include square(20);
            }
        }

        @include breakpoint(medium up) {
            &.large {
                @include square(48);

                svg {
                    @include square(24);
                }
            }
        }
    }

    &.indent-button {

        @include centered-flex-row($display: inline-flex);

        @include sized-svg(12) {
            fill: var(--color-grayscale-6);
            margin-right: rem-calc(8);
        }
    }

    //Flowbox button on home page
    &.flowbox-discover__button {
        align-self: center;
        padding: rem-calc(8) rem-calc(46) rem-calc(10) rem-calc(30);
        border-radius: unset;
        font-weight: var(--font-weight-bold);
        font-family: var(--font-family-primary);
        letter-spacing: rem-calc(2);
        border: unset;
        font-size: rem-calc(15);

        @include absolute-block-pseudo('after') {
            top: 50%;
            right: 5px;
            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;
            border-left: 5px solid var(--color-grayscale-5);
            width: rem-calc(20);
            height: rem-calc(14);
            border-radius: rem-calc(4);
            transform: translateY(-40%);
        }
    }

    &.font-page__button {
        // This code overrides the frontpage button styling, don't forget to remove this if Basiclabel decides to change all the buttons.
        border-radius: unset;
        padding: 0 rem-calc(30);
        font-weight: var(--font-weight-bold);
        font-family: var(--font-family-primary);
        letter-spacing: rem-calc(2);
        font-size: rem-calc(15);
        border: unset;
    }
}

// ------------------------------------------------------------------------------
//      Mobile breadcrumb link
// ------------------------------------------------------------------------------

.mobile-breadcrumb-link {
    width: fit-content;
    display: flex;
    align-items: center;
    gap: .5rem;
    border: none !important;

    svg {
        width: .7rem;
        height: 100%;
    }
}
