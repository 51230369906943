// ------------------------------------------------------------------------------
// Global bootstrap
// ------------------------------------------------------------------------------

@import 'variables';
@import 'foundation-settings';
@import '~@jjwesterkamp/sass-utils/sass/mixins';
@import 'mixins';
@import 'motion-ui/motion-ui';


// ------------------------------------------------------------------------------
// Foundation files
// ------------------------------------------------------------------------------
@import 'foundation-sites/scss/foundation';
@include foundation-global-styles;
@include foundation-grid;
@include foundation-typography;
@include foundation-forms;
@include foundation-button;
@include foundation-breadcrumbs;
@include foundation-tabs;
@include foundation-visibility-classes;
@include foundation-reveal;

// Overrides
.row {
    max-width: 94rem !important;
    width: auto;

    &.full-width {
        width: 100%;
    }
}


// ------------------------------------------------------------------------------
// Other JS plugins
// ------------------------------------------------------------------------------

/**
    // I've added comments to each file showing which pages and components use them
    // A ✓ checkmark means the page already has its own scss file and doesn't require the import from app.scss anymore
    // Once all pages have a checkmark the import can be removed

    pages/catalog/overview/catalog-overview | ✓
    pages/front-page/base | ✓
    all content blocks pages
 */
@import '~slick-carousel/slick/slick.scss';
/**
    pages/gift-card/index
 */
@import '~flatpickr/dist/flatpickr.min';

@import 'typography';


@import '~swiper/scss';
@import '~swiper/scss/autoplay';
@import '~swiper/scss/effect-fade';
@import '~swiper/scss/navigation';
@import '~swiper/scss/pagination';
@import '~swiper/scss/scrollbar';


// ------------------------------------------------------------------------------
// Setup global layout
// ------------------------------------------------------------------------------

@import 'layout/global'; // global
@import 'template/site-header/index'; // global
@import 'template/site-footer/index'; // global
@import 'template/shopping-list-notification'; // global
@import 'template/browser-support-modal'; // global

/**
    pages/simple-pages/base
 */
@import 'layout/simple-page';
/**
    pages/inspiration/detail
 */
@import 'layout/inspi-grids';
/**
    cms
    components/modal-component
    components/modal
    vendor/flash/modal
    vendor/laracasts/flash/src/views/modal
*/
@import 'components/modal';
@import 'components/input-wrapper'; //global
/**
    pages/webatelier/index
*/
@import 'components/stateful-button';
/**
    pages/shopping-cart/show
 */
@import 'components/quantity-control';
/**
    pages/account/template/account-default (password-change-modal)
    checkout/credentials/index (credentials-form-content)
    checkout-remote/credentials/show (credentials-form-content)
 */
@import 'components/password-fields';
/**
    pages/catalog/detail/catalog-detail | ✓
    pages/front-page/base | ✓
    pages/landing/show | ✓
    pages/inspiration/detail (product-slider)
 */
@import 'components/instant-search';
/**
    pages/front-page/grid-templates/grid (top-lists) | ✓
    pages/landing/show | ✓
    pages/search/product-results | ✓
    overview/catalog-overview | ✓
    errors/404
    pages/fabric/show
*/
@import 'components/product-list/index';
@import 'components/video';

@import 'domains/catalog/product-list-toolbar';

/**
    pages/landing/show | ✓
    pages/inspiration/detail
 */
@import 'components/content-layout';

/**
    pages/catalog/detail/partials/carousels/product-carousel
 */
@import 'components/zoomist';

@import 'components/swiper';


// ------------------------------------------------------------------------------
// Pages
// ------------------------------------------------------------------------------
@import 'pages/account/index';
@import 'pages/business/index';
@import 'pages/business/request';
@import 'pages/eekhoornexpo/index';
@import 'pages/auth/layout';
@import 'pages/return-service/index';
@import 'pages/tips/index';
@import 'pages/at-home/index';
@import 'pages/brochures/detail';
@import 'pages/brochures/index';
@import 'pages/contact/index';
@import 'pages/customer-service/index';
@import 'pages/fabric/detail';
@import 'pages/faq/index';
@import 'pages/gift-cards/digital';
@import 'pages/gift-cards/index';
@import 'pages/gift-cards/partials/gift-card-balance-modal';
@import 'pages/gift-cards/partials/gift-card-status';
@import 'pages/inspiration/detail';
@import 'pages/inspiration/index';
@import 'pages/locations/index';
@import 'pages/order/detail';
@import 'pages/profile/my-returns';
@import 'pages/shopping-cart/index';
@import 'pages/vacancies/detail';
@import 'pages/vacancies/index';
@import 'pages/errors/index';
@import 'pages/webatelier/index';
@import 'pages/newsletter-subscription/index';


// ------------------------------------------------------------------------------
// Partials
// ------------------------------------------------------------------------------

/**
    pages/catalog/detail/catalog-detail | ✓
    pages/webatelier/index
    pages/wish-list/show

    partials.quantity-select
        add-buyable-form
            sticky-right-sidebar
                pages/catalog/detail/catalog-detail
            shopping-list.wish-list-item
                pages.wish-list.partials.wish-list-content.blade.php
                    pages/wish-list/show
        sticky-add-buyable-form
            addtocart-sticky-header
                pages/catalog/detail/catalog-detail

*/
@import 'partials/buyable/buyable-quantity-select';
/**
    pages/catalog/detail/catalog-detail | ✓
    pages/wish-list/show
*/
@import 'partials/buyable/add-buyable-form';
/**
    emails/customer/confirm_order_completion/order

    partials.location-block
        pages/location/index
    dashboard.partials.credentials-block
        pages/account/orders/detail
        pages/account/orders/index
        pages/account/dashboard/show
    locations.partials.location-info-list
        pages.contact.partials.info-block
            pages/contact/layout
*/
@import 'partials/data/address-lines';
@import 'partials/accordion';
/**
    pages/catalog/detail/catalog-detail | ✓
    pages/account/template/account-default
    partials.breadcrumbs
        pages/simple-pages/base
        pages/inspiration/detail
        pages/gift-card/index
        pages/customer-service/detail

*/
@import 'partials/breadcrumbs';
/**
    pages/checkout/template/checkout-default
    pages/checkout/template/checkout-final
    PLPs
    PDPs

    partials.content-block
        --too many to count
*/
@import 'partials/content-block';
/**
    pages/catalog/detail/catalog-detail | ✓
    pages/simple-pages/base
*/
@import 'partials/downloads';

// Forms
/**
    pages/catalog/overview/catalog-overview | ✓
    auth/layout
    pages/checkout/template/checkout-default
    pages/webatelier/index

    form.custom-knob-control
        auth.content-blocks.register-form
        pages.catalog.overview.partials.facets.checkbox.checkbox-facet-entry
        pages.catalog.overview.partials.facets.availability.availability-facet
        pages.catalog.overview.partials.facets.availability.availability-facet-b-variant
        pages.checkout.partials.auth-flow-toggle
        pages.checkout.partials.form-blocks.partial-payment-setup-form-content
        partials.sample-request.modal
    form.custom-knob-control-component
        pages.checkout-remote.partials.pin-terminal-selection-form-content
        pages.checkout.partials.payment-method
        pages.webatelier.partials.square-image-radio-button
*/
@import 'partials/forms/custom-knob-control';

/**
    pages/inspiration/detail
 */
@import 'partials/frontpage-video';

/**
    partials.info-icon
        pages/gift-card/splash
        pages.webatelier.partials.price-display
            pages/webatelier/index
        catalog.detail.partials.sticky-right-sidebar
            pages/catalog/detail/catalog-detail | ✓
        pages.gift-card.partials.gift-card-balance-modal
            pages/gift-card/index
        partials.cart.checkout-expenses-table
            pages.checkout.partials.purchase-summary
                pages/checkout/template/checkout-default
*/
@import 'partials/info-icon';
/**
    cms
    pages/catalog/overview/catalog-overview | ✓
    content-layout.interactive-image
*/
@import 'partials/interactive-image';
/**
    partials.popover.product-point-popover
        partials.interactive-point
            partials.interactive-image
                pages/catalog/overview/catalog-overview | ✓
                content-layout.interactive-image
*/
@import 'partials/interactive-point';
@import 'partials/price-display'; // global
@import 'partials/usp-bar';
/**
    pages/gift-card/index
 */
@import 'partials/quantity-input';
/**
    pages.shopping-cart.partials.shopping-cart-list
        pages/shopping-cart/show
    pages.wish-list.partials.wish-list-content
        pages/wish-list/show
*/
@import 'partials/shopping-lists/shopping-list-large';
@import 'partials/shopping-lists/shopping-list-mini'; // global
/**
    partials.shopping-lists.shopping-list-grid
        pages.account.dashboard.partials.favorites-block
            pages/account/dashboard/show
 */
@import 'partials/shopping-lists/shopping-list-grid';
@import 'partials/expenses-table'; // global
/**
    pages/catalog/detail/catalog-detail | ✓
    partials.wishlist-toggle
        components.product-card
            pages/front-page/grid-templates/grid (top-lists) | ✓
            pages/landing/show | ✓
            pages/search/product-results | ✓
            overview/catalog-overview | ✓
            errors/404
            pages/fabric/show
 */
@import 'partials/wishlist-toggle';

/**
   partials/buyable/add-single-buyable-form.blade
   partials/buyable/add-buyable-form.blade
   assets/ts/algolia/connectors/product-list/product-list-widget.template
 */

 @import 'partials/tooltips/temporarily-sold-out.scss';

/**
    resources/views/pages/shopping-cart/show.blade.php
 */
@import 'partials/kiyoh-review';
@import 'partials/customer-ratings';

// ------------------------------------------------------------------------------
// Elements (todo: should be refactored into components)
// ------------------------------------------------------------------------------

@import 'elements/buttons';
@import 'elements/form';
@import 'elements/pagination';
/**
    components.read-more
    partials.contact
        pages.search.text-results
            pages/search/search-layout | ✓
    pages.catalog.detail.partials.information
    pages/brands/index
    pages/customer-service-index
        pages/catalog/detail/catalog-detail | ✓
 */
@import 'elements/read-more';
@import 'elements/slick-carousel'; // used in the same pages as ~slick-carousel/slick/slick.scss
/**
    pages/catalog/detail/catalog-detail | ✓
    partials.social-media-share
        pages/inspiration/detail
        pages.catalog.detail.partials.sticky-right-sidebar
        pages/simple-pages/brochures/show
        pages/simple-pages/vacancies/detail
        page/webatelier/index
 */
@import 'elements/social-media';
@import 'partials/social-media-links'; // global // This is the replacing partial
/**

    elements.generic-message
        pages/customer-service/index
        pages.contact.partials.info-block
            pages/contact/layout
        pages.checkout.partials.purchase-summary
            pages/checkout/template/checkout-default
        pages.shopping-cart.partials.shopping-cart-summary
            pages/shopping-cart/show
 */
@import 'elements/generic-message';

// Helpers
@import 'helpers';

// Modals (popup windows)
@import 'modals/base-modal';
@import 'modals/forward-product';
@import 'modals/newsletter-sign-up'; // global
/**
   modals.ajax.request-brochure.package
   modals.ajax.request-brochure.content-form
   pages/simple-pages/brochures/index
 */
@import 'modals/request-brochure';

/**
   auth/layout
 */
@import 'modals/reset-password';

/**
    pages/gift-card/index
 */
@import 'modals/preview-giftcard';
/**
    pages/webatelier/index
 */
@import 'modals/sample-request';

// TODO: Check if this is needed
//[class*="column"] + [class*="column"]:last-child {
//    float: right;
//}
