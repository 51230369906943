@import "bottom-bar";
@import "link-list";
@import "contact-links";
@import "newsletter-form";

.site-footer {
    background-color: var(--color-white);

    &--top {
        padding: 2rem 0;
        background-color: var(--color-grayscale-2);
    }

    &--middle {
        padding: 2rem 0;
    }

    &, * {
        font-size: rem-calc(15);
        color: var(--color-grayscale-9);
    }

    p a {
        display: inline-block;
        border-bottom: rem-calc(1) dashed #9e9e9e;
        vertical-align: baseline;
        margin: rem-calc(0 3);

        &:hover {
            text-decoration: none;
        }

        &:focus {
            text-decoration: none;
        }
    }

    &--heading {
        font-weight: 600;
        font-size: rem-calc(17);
        margin-top: 2rem;
        margin-bottom: 1rem;
    }

    &--thuiswinkel,
    &--payment-methods,
    &--carriers {
        @include centered-flex-row(flex-start);
        @include breakpoint(small only) {
            margin-bottom: 2rem;
        }
    }

    &--payment-methods {
        margin-top: -10px;
        margin-right: -10px;
        margin-left: -10px;
        flex-wrap: wrap;

        .site-footer--payment-method {
            flex: none;
            margin: 10px;

            img {
                display: inline-block;
                width: auto;
                height: 32px;
            }
        }
    }

    &--carriers {
        @include sized-svg(40, 32) {
            fill: var(--color-grayscale-9);
            margin-right: rem-calc(12);
        }
    }

    // Todo: clean this up when XHR forms are refactored
    .success-container {
        margin-top: 1rem;
        font-size: rem-calc(14);
        font-weight: 600;
    }

    @include breakpoint(small only) {
        .site-footer--link-list-container {
            h1:after {
                float: right;
                width: 20px;
                height: 20px;
                content: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA1MCA1MCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTAgNTA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHBvbHlnb24gcG9pbnRzPSI0MSwyMyA0MSwyNyAyNywyNyAyNyw0MSAyMyw0MSAyMywyNyA5LDI3IDksMjMgMjMsMjMgMjMsOSAyNyw5IDI3LDIzICIvPgo8L3N2Zz4K");
            }

            &.active {
                h1:after {
                    content: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA1MCA1MCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTAgNTA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHJlY3QgeD0iOSIgeT0iMjMiIHdpZHRoPSIzMiIgaGVpZ2h0PSI0Ii8+Cjwvc3ZnPg==");

                }
            }
        }
    }
    @include breakpoint(medium down) {
        &--top {
            padding: 2rem 0 1rem 0;
            .site-footer--heading {
                font-size: 1.1rem;
            }
        }

        &--middle {
            padding: 2rem 0 0 0;

            .column {
                margin-bottom: 7px;
            }
        }

        .row.links {
            margin: 10px 0;

            .column {
                margin-bottom: 15px;
            }

            .site-footer--link-list {
                margin: 15px 0;


                &--item {
                    margin: 0 0 3px 0;
                    line-height: 1.4rem;
                }
            }
        }

        &--heading {
            font-weight: 600;
            font-size: rem-calc(15);
            margin-top: 0;
        }

        &--bottom-bar {
            margin-top: 0.5rem;

            &--left {
                display: block;
            }
        }
        .site-footer--logo {
            margin: 10px 0 0 0;

            svg {
                width: 10.33333rem;
            }
        }
    }
}
