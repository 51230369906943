.mobile-header {
    $mobile-header-transition: 300ms ease-out;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    margin-bottom: 2rem;

    &.search-open .mobile-header--content {
        transform: translateY(-60px);
    }

    .usp-mobile {
        background-color: var(--color-grayscale-9);
        width: 100%;
        height: 30px;

        a {
            font-size: rem-calc(13);
        }

        .usp-bar {
            --usp-height: 30px;
        }
    }

    &--mask {
        @include fixed-overlay;
        background-color: #fff;
        opacity: 1;
        transition: opacity 0.15s;

        &:not(.active) {
            pointer-events: none !important;
            opacity: 0 !important;
            transition: opacity 0.15s;
        }
    }

    &--content {
        position: relative;
        background: white;
        z-index: 1;
        height: var(--mobile-header-height);
        display: grid;
        grid-template-columns: rem-calc(100) auto rem-calc(100);
    }

    .mobile-menu-toggle {

        @include button-reset {

        }
        @include centered-flex-column;
        @include sized-svg(24, 15) {
            fill: var(--color-grayscale-9);
        };

        font-size: rem-calc(9);
        line-height: rem-calc(11);
        font-weight: 600;
        text-decoration: none;
        color: var(--color-grayscale-9);

        &:hover {
            color: var(--color-grayscale-9);
            svg {
                fill: var(--color-grayscale-9);
            }
        }
    }

    #fake-focus-collapsed {
        position: absolute;
        border: none;
        left: 58px;
        top: 10px;
        width: 43px;
        z-index: 10;
        background: transparent;
        box-shadow: none;

        &:focus {
            outline: none;
        }
    }

    .search-button {
        pointer-events: none;
        opacity: 0;
    }

    .mobile-logo {
        width: 0; // Link/SVG will overflow, but now the logo does not disturb the box flow.
        transform: scale(0.8);

        svg {
            display: block;
            width: rem-calc(160);
            height: var(--mobile-header-height);
        }
    }

    .left-content,
    .center-content,
    .right-content {

        padding: rem-calc(0 20);
        display: flex;
        align-items: center;
    }

    .left-content input {
        display: none;
    }

    .center-content {
        justify-content: center;

        a {
            @include square(40);
            @include sized-svg(40);
        }
    }

    .right-content {
        justify-content: flex-end;
    }


    .menu-button {
        @include square(40);

        &--hamburger {
            svg,.menu-text  {
                align-self: flex-start;
            }
        }

        position: relative;
        flex: none;

        @include centered-flex-row();
        @include sized-svg(24) {
            fill: var(--color-grayscale-9);
        }

        .counter {
            @include absolute-overlay;
            display: block;

            &.hidden {
                display: none;
            }
        }

        .amount {
            position: absolute;
            top: 0;
            right: 0;
            @include square(18);
            background: var(--color-red);
            border-radius: 50%;
            border: 1px solid white;
            display: block;
            color: var(--color-white);
            z-index: 2;
            font-size: rem-calc(10);
            line-height: rem-calc(16);
            font-weight: bold;
        }

        &:hover svg,
        &.active svg {
            fill: var(--color-grayscale-9);
        }

        &.search-button svg {
            @include square(20);
        }
    }

    &--search-back {
        position: absolute;
        margin: rem-calc(11) 0 !important;
        z-index: 1000;
        display: none;
        align-items: center;

        .svg-classic-arrow-left {
            @include square(18);
        }
    }

    .global-search-container {
        flex: 1;
    }

    &--search {
        display: flex;
        align-items: center;
        padding: rem-calc(10 20);
        z-index: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background-color: var(--color-white);
        transform: translateY(0);

        .global-search--form {
            flex: 1;
        }

        .global-search--field-container {
            width: 100%;
        }

        .global-search--field.global-search--field {
            width: 100%;
            @include placeholder-styles;
        }
    }

    .center-content {
        opacity: 0;
        pointer-events: none;
        transform: scale(0.7);
    }

    .mobile-logo {
        transform: translateX(-80px);
    }

    .mobile-menu-toggle {
        transform: translateY(60px);
    }

    .mobile-header--search {
        top: 100%;
    }

    .mobile-header--search form {
        margin-left: rem-calc(60);
    }

    &.has-shadow:not(.defeat-shadow) &--search {
        box-shadow: rem-calc(0 0 8 0) rgba(black, 0.15);
    }
}

.header-is-collapsed {
    .left-content input#fake-focus-collapsed {
        display: block;
    }

    .center-content {
        opacity: 1;
        transform: scale(1);
        pointer-events: all;
    }

    .mobile-menu-toggle {
        transform: translateY(0);
    }

    .mobile-header--search-back {
        display: flex;
    }

    .mobile-header--search {
        display: none;
        transform: translateY(0);
    }

    .mobile-header--search form {
        margin-left: rem-calc(36);
    }

    .usp-mobile {
        opacity: 0;
        height: 0;
    }

    .mobile-logo {
        pointer-events: none;
        opacity: 0;
    }

    .search-button {
        opacity: 1;
        pointer-events: all;
    }
}

.search-is-shown {
    .mobile-header--search {
        transform: translateY(0);
        top: 0;
        display: block;
        transition: top 0.2s;
    }
}
