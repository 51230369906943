.vacancies-detail {
    .title {
        margin: rem-calc(4 0 20 0);
        line-height: 1em;
    }
    .subtitle {
        color: var(--color-grayscale-6);
        font-size: rem-calc(15);
        font-weight: 300;
        margin: 0 0 rem-calc(8);
    }

    .body {
        h2{
            font-size: rem-calc(26);
            font-weight: 400;
            line-height: rem-calc(28);
            margin-bottom: rem-calc(19);
        }

        p {
            font-size: rem-calc(17);
            line-height: rem-calc(28);
            margin: rem-calc(0 0 30 0);

            &:first-of-type{
                font-weight: 400;
            }
        }

        ul {
            margin: rem-calc(0 0 30 0);
            padding: 0;

            li {
                display: flex;
                align-items: center;
                line-height: rem-calc(32);
                margin: 0;
                padding: 0;

                &:before {
                    flex: none;
                    content: '';
                    display: inline-block;
                    border-radius: 50%;
                    background-color: $cement;
                    width: rem-calc(9);
                    height: rem-calc(9);
                    margin: rem-calc(0 24);
                }
            }
        }
    }

    .vacancy-request-container {
        border: rem-calc(1) solid var(--color-grayscale-2);
        text-align: center;
        margin: rem-calc(40 0 80);

        .title {
            margin-top: rem-calc(50);
            font-size: rem-calc(28);
            line-height: rem-calc(34);
            margin-bottom: rem-calc(10);
        }
        p {
            font-size: rem-calc(17);
        }
        .button {
            margin: rem-calc(4 0 51);
            background: var(--color-orange);
            padding: rem-calc(0 16);
            border: none;
            outline: none;
            font-size: rem-calc(15);
            line-height: rem-calc(40);
            flex-grow: 0;
            flex-shrink: 0;

            &:hover {
                background: var(--color-orange-dark);
            }
        }
    }
}

.back-to-overview{
    padding: rem-calc(20 0);

    &:before{
        width: 0;
        height: 0;
        border-top: rem-calc(6) solid transparent;
        border-right: rem-calc(5) solid var(--color-grayscale-6);
        border-bottom: rem-calc(6) solid transparent;
        content:'';
        display: inline-block;
        margin-right: rem-calc(5);
    }
}
