#sample-request-modal {

    .modal--content {
        width: rem-calc(900);
    }

    h3 {
        margin-bottom: 0;
    }

    .sample-previews {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: rem-calc(9);
        margin: rem-calc(32 0 32 0);

        svg {
            width: rem-calc(12);
            height: rem-calc(12);
        }
    }

    .knobs {
        display: flex;
        align-items: center;
    }

    .select-country {
        @include breakpoint(large) {
            padding-left: rem-calc(12);
        }
    }

    .heading {
        font-size: rem-calc(17);
    }

    .request-button {
        @include centered-flex-row(flex-end);
        margin-top: rem-calc(30);

        @include breakpoint(large) {
            margin: unset;
        }
    }

    .sample-request-form {
        &--success-container {
            @include centered-flex-column();
            @include sized-svg(32) {
                fill: var(--color-green);
                margin: 2rem;
            }
        }

        &--success-message {
            text-align: center;
        }
    }

}
