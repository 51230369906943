.interactive-image {
    overflow: hidden;
    border-radius: 3px;
    position: relative;

    &--image {
        z-index: 1;
    }

    &--mask {
        @include absolute-overlay();

        z-index: 2;
        opacity: 0;
        background-color: rgba(black, 0.3);
        pointer-events: none;
        transition: opacity var(--transition-medium-timing);
    }

    button.interactive-point {
        box-sizing: content-box;
        z-index: 3;
        margin: 0;
        padding: 0;
        position: absolute;
        border-radius: 50%;
        background-color: var(--color-cement-dark);
        border: rem-calc(1) solid white;
        width: rem-calc(20);
        height: rem-calc(20);
        transform: translate(-50%, -50%);
        transition: background-color var(--transition-medium-timing),
                    border-color var(--transition-medium-timing),
                    border-width var(--transition-medium-timing);
        cursor: pointer;

        &:hover {
            box-shadow: rem-calc(0 0 18 -1) #ffeca7;
        }

        &:focus {
            border-color: rgba(white, 0.8);
            border-width: 4px;
        }
    }

    @keyframes pulsing-points {
        0%, 40% {
            box-shadow: rem-calc(0 0 18 -1) transparent;
        }

        to {
            box-shadow: rem-calc(0 0 18 -1) #ffeca7;
        }
    }

    &:hover, &.clicked {
        .interactive-image--mask {
            opacity: 1;
        }

        .interactive-point {
            background-color: var(--color-orange);

            &:not(:hover) {
                animation: pulsing-points 1s linear alternate infinite;
            }
        }
    }

    @include breakpoint(small only) {
        button.interactive-point {
            width: rem-calc(12);
            height: rem-calc(12);
        }
    }
}
