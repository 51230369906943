@import 'detail/partials/configuration-block';
@import 'order-detail/webatelier-order-detail';
@import 'partials/carousel';
@import 'partials/help-modal';
@import 'partials/product-detail-modal';
@import 'partials/webatelier-top';
@import 'components';
@import 'typography';

$border-gray: solid 1px var(--color-grayscale-2);

.webatelier-content {
    @include breakpoint(large) {
        margin-top: -2rem; // nullifies the default bottom-margin of the site header
    }

    .row {
        margin-bottom: rem-calc(30);

        &--no-margin {
            margin: 0;
        }

        &--no-top-margin {
            margin-top: 0;
        }

        &--no-bottom-margin {
            margin-bottom: 0;
        }
    }

    &--header {
        @include breakpoint(medium down) {
            margin-bottom: 0 !important;
        }
    }

    .column {
        &--heading {
            @include breakpoint(large) {
                padding-top: 2rem;
            }

        }
    }

    .hide-visually {
        visibility: hidden;
    }

    .sharing-facilities {

        font-weight: bold;
        display: flex;
        align-items: center;
        gap: rem-calc(24);
        border-bottom: $border-gray;
        padding: rem-calc(24 0 24 0);

        @include breakpoint(large) {
            margin-left: 1rem;
            border-bottom: unset;
        }

        .forward-product-link {
            @include square(32);
            @include centered-flex-row();
            padding: 0;
            border-radius: 4px;
            border: 1px solid var(--color-grayscale-3);
            border-bottom-width: 2px;
            cursor: pointer;

            @include sized-svg(20) {
                fill: var(--color-grayscale-9);
            }

            &:hover {
                border-color: var(--color-grayscale-6);
            }
        }

        .social-buttons {
            display: flex;
            gap: rem-calc(8);
            margin-right: rem-calc(10);

            .social-medium svg {
                max-width: rem-calc(28);
                max-height: rem-calc(28);
                border-radius: rem-calc(4);
                fill: var(--color-black);
            }
        }
    }

    .desktop-configurator {
        position: relative;
        z-index: 10;
        margin: 0 auto;
        max-width: $row-width - $grid-column-gutter;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
        background-color: var(--color-white);
        padding-bottom: rem-calc(40);

        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-areas:
            "nav  nav  nav  nav"
            "main main main aside"
            "main main main aside";

        &__nav {
            grid-area: nav;
        }

        &__main {
            grid-area: main;
            border-right: $border-gray;
            padding: 0 rem-calc(80);
            margin-top: rem-calc(40);
            display: grid;
            grid-template-rows: 1fr auto;
            min-height: 510px; // eye-balling: taken the largest in height step, to keep other steps the same height.s
            position: relative;
        }


        &__aside {
            grid-area: aside;
            padding-right: rem-calc(29);
            padding-left: rem-calc(29);
            margin-top: rem-calc(40);

            display: grid;
            grid-template-rows: 1fr auto;

            .form-to-be {
                margin-top: rem-calc(24);
                border-top: 1px solid var(--color-grayscale-2);
                padding-top: rem-calc(24);
                @include centered-flex-row;

                .buyable-quantity-select {
                    flex: none;
                    width: rem-calc(72);
                }

                button {
                    margin-left: 1rem;
                    flex: auto;
                    padding-left: 2rem;
                    padding-right: 2rem;
                }
            }
        }
    }


    .configurator {
        z-index: 10;
        @include breakpoint(large) {
            margin-top: rem-calc(-60);
            box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
            background-color: var(--color-white);
            padding-bottom: rem-calc(40);
            position: relative;

            &__nav {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 3rem;
                border-bottom: $border-gray;
                height: rem-calc(60);

                &__item {
                    height: 100%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    column-gap: rem-calc(8);
                    border-bottom: 3px solid transparent;
                    cursor: pointer;

                    &.active {
                        border-bottom: 3px solid var(--color-black);
                    }

                    h2 {
                        font-weight: 600;
                        font-size: 1rem;
                        margin: 0;
                    }

                    h3 {
                        font-size: rem-calc(13);
                        color: var(--color-grayscale-6);
                        opacity: 0;
                        pointer-events: none;
                        margin: 0;

                        &.active {
                            pointer-events: none;
                            opacity: 1;
                        }
                    }
                }
            }
        }

        @include breakpoint(medium down) {
            .column:first-child {
                padding-left: rem-calc(10);
                padding-right: rem-calc(10);
            }
        }
        &__header {
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;
            width: 100%;
            margin-bottom: 1rem;
        }

        &__help.button.button {
            height: 21px;

            @include breakpoint(medium down) {
                span {
                    display: none;
                }
            }
        }

        &__content {

            &__items {
                width: 100%;
                overflow: hidden;
            }

            &__item {
                height: 100%;
                display: none;

                @include breakpoint(medium down) {
                    /* To override the padding:0 from Foundation v5 */
                    padding: rem-calc(24 15 16 15) !important;
                }

                &.active {
                    display: flex;
                    flex-flow: column;
                    height: 100%;
                }
            }
        }

        &__bottom-navigation {
            border-top: $border-gray;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: rem-calc(24);
            margin-top: rem-calc(20);

            .button.button {
                pointer-events: unset;

                &.white {
                    color: var(--color-grayscale-9);
                    border: $border-gray;
                }

                &.disabled {
                    cursor: not-allowed;

                    svg {
                        fill: var(--color-white);
                    }
                }
            }
        }
    }

    .delivery-and-wish-list {
        margin-bottom: rem-calc(15);
    }

    .add-buyable-form {
        justify-content: flex-start;

        @include breakpoint(large) {
            border-top: solid 1px var(--color-grayscale-2);
            padding-top: rem-calc(24);
        }

        .buyable-quantity-select {
            margin: rem-calc(0 15 0 0);
        }
    }

    /* Sample request view */
    .sample-request {
        padding: rem-calc(16);
        border-radius: rem-calc(8);
        box-shadow: inset 0 -2px 0 0 var(--color-grayscale-3);
        border: solid 1px var(--color-grayscale-3);
        margin-bottom: 2rem;

        h2 {
            font-weight: 600;
            font-size: 1rem;
            margin-bottom: 0.25rem;
        }

        p {
            font-size: rem-calc(13);
        }

        .container {
            cursor: pointer;

            @include breakpoint(medium down) {
                display: grid;
                grid-template-columns: 65px 1fr;
                grid-template-areas: "image title"
                                     "image subtitle";

                grid-column-gap: rem-calc(24);

                h2 {
                    grid-area: title;
                }

                p {
                    grid-area: subtitle;
                }

                img {
                    grid-area: image;
                }

                button {
                    display: none;
                }
            }
        }

        .flex-container {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
        }
    }

    .configurator-usps {
        border-top: $border-gray;
        border-bottom: $border-gray;
        padding: rem-calc(24 0 24 0);

        .right-border {
            display: none;
        }

        @include breakpoint(large) {
            border-top: unset;
            border-bottom: unset;
            position: relative;
            padding: rem-calc(24 15 24 15);

            .right-border {
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                border-right: $border-gray;
                padding-top: rem-calc(24);
                padding-right: rem-calc(24);
                width: 1px;
                border-right: solid 1px #ebebeb;
                margin-top: 1.6rem;
                height: calc(100% - 3.2rem);
            }

            li {
                display: inline-block;
            }
        }

        .bold {
            font-weight: 600;
        }

        svg {
            fill: var(--color-grayscale-9);
            width: rem-calc(24);
            height: rem-calc(24);
        }

        &__item {
            margin-bottom: rem-calc(10);

            @include breakpoint(large) {
                margin-bottom: unset;
                margin-right: rem-calc(18);
            }

            display: flex;
            justify-content: flex-start;

            > div {
                margin-left: rem-calc(11);
            }
        }

    }


    /* Mobile Accordion Order flow */
    .order-flow-accordion {
        &__container {
            border-radius: rem-calc(12);
            border: solid 1px var(--color-grayscale-3);

            .accordion-navigation {
                &.active {
                    .accordion-header {
                        border-bottom: solid 3px var(--color-grayscale-9);
                    }
                }

                a:first-of-type {
                    &:hover, &:active {
                        text-decoration: none;
                    }
                }

                .accordion-header {
                    border-bottom: solid 1px var(--color-grayscale-3);
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    column-gap: rem-calc(8);
                    padding: rem-calc(15 15 15 15);
                    pointer-events: none;

                    &--no-border {
                        border-bottom: unset;
                    }

                    h2 {
                        font-weight: 600;
                        font-size: 1rem;
                        margin-bottom: 0;
                    }

                    h3 {
                        font-size: rem-calc(13);
                        color: #838383;
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                }

                .content {
                    border-bottom: solid 1px var(--color-grayscale-3);

                    &--no-border {
                        border-bottom: unset;
                    }

                    &.active {
                        .configurator {
                            &__content {
                                &__item {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    /* ----- STEP 1 - Fabric selection ----- */
    .step-1-selection {

        &__content {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: rem-calc(30);
            height: 100%;

            .button-container {
                @include breakpoint(medium down) {
                    display: none;
                }

                display: flex;
                align-items: center;
                background-color: var(--color-white);
                z-index: 10;
                height: 100%;

                button {
                    border: $border-gray;
                    border-radius: 50%;
                }

                .button.button.disabled {
                    pointer-events: none;

                    svg {
                        fill: var(--color-grayscale-4);
                    }
                }
            }

            .fabric-title {
                font-size: rem-calc(13);
                margin-top: rem-calc(8);
                line-height: 1.25;

                .selected {
                    font-weight: 600;
                }

                @include breakpoint(small only) {
                    margin-top: unset;
                }
            }

            .fabric-price-per-meter {
                font-size: rem-calc(13);
                margin-bottom: rem-calc(4);

                .price-display--strike-through {
                    --pd-text-color: var(--color-grayscale-9);
                    --pd-strike-through-weight: 1px;
                    display: inline-block;
                }

                .price-display--sale {
                    display: inline-block;
                }
            }

            .square-fabric-preview {
                position: relative;
                /* Let the image take up the remaining height of the parent (flex) container */
                width: 100%;
                /* Due this element not having a set height (only 100% bottom padding), this borders mimics a 2px padding/inset on the image background */
                border: 4px solid var(--color-white);
                /* The actual border is created by a 'hard' box-shadow, for which the border-radius */
                box-shadow: 0 0 0 1px var(--color-grayscale-3);
                border-radius: 4px;

                &.active {
                    box-shadow: 0 0 0 1px var(--color-grayscale-9);
                }

                &--discount {
                    position: absolute;
                    bottom: -4px;
                    left: -4px;
                    z-index: 1;

                    > .product-discount-badge {
                        width: 2.75rem;
                        height: 2.75rem;
                    }

                    @include breakpoint(small only) {
                        display: none;
                    }
                }
            }
        }
    }

    .location-section {
        display: flex;

        .measure-instructions {
            text-align: center;
            border-bottom: 1px solid red;
            padding-bottom: 16px;

            a {
                color: red;
            }
        }
    }

    /* ----- STEP 2, 3A, 3B - Option selection ----- */
    .steps-input-selection {
        &__content {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: rem-calc(30);

            @include breakpoint(medium down) {
                gap: rem-calc(15);
            }

            &__item {
                display: flex;
                flex-direction: column;
                gap: rem-calc(8);
            }
        }
    }

    /* ---- STEP 5 - Size selection ----- */
    .image-input {
        display: flex;
        gap: rem-calc(16);

        > div:first-of-type {
            flex: 0 0 auto;
        }

        .input-wrapper {
            margin-bottom: 0;

            .row {
                margin-bottom: 0;
            }
        }
    }

    .stateful-button:disabled {
        opacity: 1 !important;
    }

    @include breakpoint(small only) {
        .stateful-button {
            width: 100%;
        }
    }
}

.measure-instructions {
    margin-bottom: 8px;

    a:hover {
        text-decoration: none;
    }

    &--container {
        border: solid 1px #ebebeb;
        background-color: #fff;
        display: grid;
        grid-template-columns: 128px auto;
    }

    &--image img {
        height: 100%;
        object-fit: cover;
    }

    &--text {
        padding: 24px 32px;
        color: var(--color-grayscale-9);
    }

    &--title {
        font-size: rem-calc(17);
        font-weight: bold;
        margin-bottom: 8px;
    }

    &--subtitle {
        margin-bottom: 8px;
    }

    &--subtitle, &--cta {
        font-size: rem-calc(15);
    }

    &--icon svg {
        @include square(10);
        margin-right: 6px;
    }
}
