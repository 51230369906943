.brochures {
    &__grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: rem-calc(15);
    }

    &__request-container {
        border: rem-calc(1) solid var(--color-grayscale-2);
        padding: 2rem;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: center;
        text-align: center;
        grid-column: 1 / -1;

        h2 {
            margin-bottom: rem-calc(20);
            font-size: rem-calc(22);
            font-weight: 400;
            line-height: rem-calc(28);
        }

        p {
            font-size: rem-calc(17);
            margin-bottom: 12px;

            &:last-of-type {
                margin-bottom: 24px;
            }
        }

        & .small {
            font-size: 80%;
        }
    }

    &__request-btn {
        margin: 0;
        background: var(--color-orange);
        padding: rem-calc(0 16);
        border: none;
        outline: none;
        font-size: rem-calc(15);
        line-height: rem-calc(40);
        flex-grow: 0;
        flex-shrink: 0;

        &:hover {
            background: var(--color-orange-dark);
        }
    }

    & &__title {
        grid-column: 1 / -1;
        margin-bottom: rem-calc(20);
        font-size: rem-calc(28);
        font-weight: normal;
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        p {
            text-align: center;
            min-height: 48px;
        }

        &:hover a img {
            transform: scale(1.05);
        }
    }

    &__item-image {
        flex: 1;
        display: flex;
        align-items: center;
        margin-bottom: rem-calc(16);

        img {
            justify-self: center;
            object-fit: cover;
            display: block;
            width: 100%;
            max-height: 230px;
            max-width: 230px;
            box-shadow: 0 1px 5px 0 rgb(0 0 0 / 25%);
            background-color: #EBEBEB;
            transition: transform 0.3s ease-in-out;
            transform-origin: center;
        }
    }
}

@include breakpoint(medium up) {
    .brochures {
        &__grid {
            grid-template-columns: repeat(3, 1fr);
        }

        &__request-container {
            grid-column: 2 / -1;

            &:last-child {
                grid-column: 1 / -1;
            }
        }

        .dropdown-sidebar-navigation {
            margin-bottom: rem-calc(10);
        }
    }
}

@include breakpoint(large) {
    .brochures {
        &__grid {
            grid-template-columns: repeat(4, 1fr);
            grid-gap: rem-calc(30);
        }
    }
}
