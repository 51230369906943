$line-height-factor: 1.6;

@font-face {
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 300;
    src: url('/fonts/oxygen/OxygenLightLatinExt.woff2') format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

@font-face {
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 300;
    src: url('/fonts/oxygen/OxygenLightLatin.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

@font-face {
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/oxygen/OxygenNormalLatinExt.woff2') format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

@font-face {
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/oxygen/OxygenNormalLatin.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/oxygen/OxygenBoldLatinExt.woff2') format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

@font-face {
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/oxygen/OxygenBoldLatin.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

@font-face {
    font-family: 'Libre Baskerville';
    font-style: italic;
    font-weight: 400;
    src: url('/fonts/libre-baskerville/LibreBaskervilleNormalItalicLatinExt.woff2') format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}

@font-face {
    font-family: 'Libre Baskerville';
    font-style: italic;
    font-weight: 400;
    src: url('/fonts/libre-baskerville/LibreBaskervilleNormalItalicLatin.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

html, body {
    font-size: $rem-base;
    font-weight: 400;
    line-height: 1.6em;
    color: var(--color-grayscale-8);

    ::selection {
        background-color: var(--color-text-selection-background);
    }
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
}

@include breakpoint(medium up) {
    h1 { font-size: 32px }
    h2 { font-size: 26px; }
    h4 { font-size: 22px; }
    h5 { font-size: 20px; }
}

p {
    margin-bottom: $line-height-factor * $rem-base;
}

p, .cms-markup {
    a:not(.no-defaults) {
        display: inline;
        vertical-align: baseline;
        text-decoration: none;
        padding-bottom: rem-calc(2); // Push down the border
        border-bottom: 1px dashed var(--color-grayscale-5);

        &:hover, &:focus {
            text-decoration: none;
            border-bottom: 1px solid var(--color-grayscale-6);
        }

        &:focus {
            background-color: var(--color-link-focus-background);
        }
    }

    ul {
        list-style: disc;
    }
}
