// ----------------------------------------------------------------------------------------------------------------------
// Custom radio buttons and checkboxes
// ----------------------------------------------------------------------------------------------------------------------

.custom-knob-control {

    ///// Options, global changes should be made here /////
    $knob-size: rem-calc(22);
    $knob-border-width: rem-calc(1);
    $knob-border-color: var(--color-grayscale-3);
    $knob-background-color: white;

    $checked-fill-margin: rem-calc(4);
    $checked-fill-color: var(--color-grayscale-9);
    $checked-border-color: var(--color-grayscale-9);

    $label-padding: rem-calc(10);
    $label-font-size: rem-calc(14);
    $label-line-height: 1.8 * $label-font-size;
    $label-color: var(--color-grayscale-9);
    $label-color--hover: var(--color-grayscale-9);

    $knob-control-transition-params: 0.1s ease-out;

    ///// Calculations /////

    $fill-margin: $knob-border-width + $checked-fill-margin;
    $fill-radius: $knob-size - ( 2 * $fill-margin );

    ///// Application /////

    line-height: $label-line-height;

    position: relative;

    .custom-knob-control--label {
        display: block;
        cursor: default;
        user-select: none;
        margin: 0;
        padding: 0 0 0 $label-padding + $knob-size;

        line-height: $label-line-height;
        color: $label-color;
        position: relative;
        transition: background-color $knob-control-transition-params,
        color $knob-control-transition-params;

        &::before, // The outer circle
        &::after // Fill, when checked
        {
            position: absolute;
            top: 0.5 * $label-line-height;
            left: 0;
            content: '';
            display: block;
            transform: translateY(-50%);
        }

        // Border
        &::before {
            background-color: $knob-background-color;
            width: $knob-size;
            height: $knob-size;
            box-sizing: border-box;
            border: $knob-border-width solid $knob-border-color;
            box-shadow: 0 1px 0 0 $knob-border-color;
            transition: border-color $knob-control-transition-params;
        }

        // Fill
        &::after {
            width: $fill-radius;
            height: $fill-radius;
            background-color: transparent;
            margin-left: $fill-margin;
            margin-right: $fill-margin;
            transition: background-color $knob-control-transition-params;
        }
    }

    &.reversed &--label {

        padding-left: 0;
        padding-right: $label-padding + $knob-size;

        &::before,
        &::after {
            left: auto;
            right: 0;
        }
    }

    &--input {
        position: absolute;
        padding: 0;
        overflow: hidden;
        clip: rect(0 0 0 0);
        height: rem-calc(1);
        width: rem-calc(1);
        margin: rem-calc(-1);
        border: 0;

        &:focus + .custom-knob-control--label {

            &::before {
                @include focus-style-through-border;
            }

            &::after {
                background-color: $focus-fill-color;
            }
        }

        // ------------------------------------------------------------------------------
        //      Specific to radio button variants:
        // ------------------------------------------------------------------------------

        &[type="radio"] + .custom-knob-control--label::before,
        &[type="radio"] + .custom-knob-control--label::after
        {
            border-radius: 50%;
        }


        &[type="checkbox"] + .custom-knob-control--label::before {
            border-radius: 4px;
        }

        // ------------------------------------------------------------------------------
        //      Checked / Focus states
        // ------------------------------------------------------------------------------

        &:checked + .custom-knob-control--label {

            font-weight: 600;
            color: var(--color-grayscale-9);

            &::before {
                border-color: $checked-border-color;
                box-shadow: 0 1px 0 0 $checked-border-color;
            }

            &::after {
                background-color: $checked-fill-color;
            }
        }

        &:disabled + .custom-knob-control--label {
            cursor: not-allowed !important;
            opacity: 0.4;
        }

        &:not(:disabled) + .custom-knob-control--label:hover {
            background-color: var(--color-grayscale-1);
            color: $label-color--hover;
            cursor: pointer;
        }
    }

    &.never-bold .custom-knob-control--label {
        font-weight: 400 !important;
    }
}
