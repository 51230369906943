$border-light-gray: solid 1px var(--color-grayscale-2);

.product-detail-modal {
    .modal--content {
        max-width: 970px;
        width: 100%;
    }

    &__content {
        display: none;
        grid-template-columns: 1fr 3fr;
        grid-template-rows: 1fr;
        grid-column-gap: rem-calc(40);

        @include breakpoint(medium down) {
            grid-template-columns: 1fr;
        }

        &.active {
            display: grid;
        }
    }

    .image-preview {

        @include breakpoint(large) {
            grid-area: 1 / 1 / 2 / 2;
        }

        @include square(240);

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            border: $border-light-gray;
            border-radius: rem-calc(4);
            padding: rem-calc(16);
        }
    }

    .product-details {
        @include breakpoint(large) {
            grid-area: 1 / 2 / 2 / 3;
        }

        section {
            margin-top: 1rem;
        }

        .body {

            &, p {
                color: var(--color-grayscale-9);
            }

            li {
                position: relative;
                padding-left: 1rem;
                margin-bottom: 5px;

                @include absolute-block-pseudo(before) {
                    top: 9px;
                    left: 0;
                    @include square(6);
                    background-color: var(--color-grayscale-6);
                    border-radius: 50%;
                }
            }

            strong, strong a {
                text-decoration: underline;
                text-decoration-color: #9E9E9E;
            }
        }


        /* Section -- Specifications */
        .specifications {
            .swatch {
                width: rem-calc(15);
                height: rem-calc(15);
                border-radius: 100%;
                display: inline-block;
                margin-right: rem-calc(10);
            }

            .colors {
                display: flex;
                align-items: center;
            }

            table {
                border-collapse: collapse;
                width: 100%;

                tr:nth-child(odd) {
                    background-color: #F6F6F6;
                }

                td, th {
                    vertical-align: top;
                    white-space: pre-line;
                    padding: rem-calc(10 15);
                }

                th {
                    font-weight: bold;
                    text-align: left;
                    min-width: rem-calc(100);
                    width: 20%;
                }
            }
        }

        .download {
            margin-bottom: 2rem;

            .title {
                font-size: rem-calc(17);
                font-weight: bold;
            }

            &--title {
                @include sized-svg(20) {
                    flex: none;
                    fill: var(--color-grayscale-6);
                    transform: translateY(3px);
                    transition: transform .1s ease-out;
                }

                &:hover {
                    svg {
                        transform: translate(3px, 3px);
                    }
                }

                &:focus {
                    text-decoration: none;
                    background-color: var(--color-link-focus-background);

                    svg {
                        transform: translate(3px, 3px);
                    }
                }

            }

            .light-grey {
                color: #838383;
            }
        }
    }

    .previous-icon {
        &:before {
            content: '';
            display: inline-block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 5px 5px 5px 0;
            border-color: transparent var(--color-grayscale-6) transparent transparent;
            margin-right: rem-calc(10);
        }
    }

}
