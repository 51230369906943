.webatelier-top {
    position: relative;
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 1fr 520px 1fr;
    grid-template-rows: auto 1fr;
    grid-column-gap: 2rem;

    min-height: rem-calc(440);
    max-height: rem-calc(520);

    grid-template-areas:
        'title  carousel actions'
        'thumbs carousel actions';

    @include breakpoint(medium down) {
        margin-top: 0;
        grid-template-columns: 1fr;
        grid-template-areas:
            'actions'
            'carousel'
            'thumbs'
            'title';
        height: unset;
    }

    &--title {
        grid-area: title;
    }

    &--thumbs {
        grid-area: thumbs;
    }

    &--carousel {
        grid-area: carousel;
    }

    &--actions {
        grid-area: actions;
    }

    &--title {
        display: flex;
        @include breakpoint(large) {
            margin-bottom: 2rem;
        }

        @include breakpoint(medium down) {
            font-size: rem-calc(26);
        }

        .wa-back-button {
            margin-right: 2rem !important;
        }
    }

    &--thumbs {
        @include breakpoint(medium down) {
            display: none;
        }

        .swiper-wrapper {
            flex-direction: column;
        }
    }

    &--carousel {
        display: flex;
        flex-direction: column;
        border-radius: 6px;
        overflow: hidden;
        position: relative;

        .swiper-container,
        .swiper-wrapper,
        .swiper-slide {
            height: 100%;
        }

        .swiper-slide {
            padding: 1px;
            background-clip: content-box;
            outline: none;
            height: 100%;
            width: 100%;
            background-color: white;
            @include centered-contain-background;
        }

        .image-rounded-corners {
            background-position: center center;
            background-size: cover;
            border-radius: 4px;
            background-repeat: no-repeat;
        }

        &:hover,
        &:focus-within {
            opacity: 1;
        }
    }

    &--actions {
        .border-button {
            background-color: white !important;
        }

        @include breakpoint(medium down) {
            position: absolute;
            width: 100%;
            padding: 1rem 0 1rem 0;
            @include centered-flex-row(space-between);
        }
    }

    &--thumbs, &--carousel, &--actions {
        min-width: rem-calc(400);
        min-height: rem-calc(400);
        height: 100%;
        width: 100%;
        max-height: rem-calc(520);
        max-width: rem-calc(520);
    }

    .wa-back-button {
        svg {
            width: rem-calc(16) !important;
            height: rem-calc(16) !important;
        }
    }
}

/* Swiper adjustments for medium down */
@include breakpoint(medium down) {
    .webatelier-top {
        &--carousel {
            max-height: unset;
            max-width: unset;
            min-height: unset;
            min-width: unset;
        }

        &--thumbs {
            display: none;
        }

        &--actions {
            display: none;
        }

        .swiper-button-next,
        .swiper-button-prev {
            display: none;
        }

        .swiper-pagination {
            bottom: 0;
        }

        .swiper-container {
            padding-bottom: rem-calc(24);
        }
    }
}

@include breakpoint(large) {
    .webatelier-top {
        .swiper-pagination {
            display: none;
        }
    }
}
