#password-change-modal {

    .modal--title {
        display: flex;
        align-items: center;

        @include breakpoint(medium up) {
            margin-bottom: 3rem;
        }

        @include sized-svg(24) {
            fill: $cement;
            margin-right: 1rem;
        }
    }

    .modal--content {
        max-width: rem-calc(600);
    }

    .input-wrapper--column {
        padding-left: 0;
        padding-right: 0;
    }

    .password-description {
        margin: 2rem 0;
    }

    .password-change-success {
        @include centered-flex-column;

        .check-mark-container {
            width: rem-calc(90);
            height: rem-calc(90);
            border-radius: 50%;
            @include centered-flex-row;
            background-color: var(--color-green);
            margin-bottom: 3rem;
        }

        @include sized-svg(42) {
            fill: white;
        }

        text-align: center;
        margin: 4rem 0;
    }

    @media screen and (max-width: 340px) {
        button[type="submit"] {
            width: 100%;
        }
    }
}
