.kiyoh-popover {
    .modal-content {
        position: relative;
    }

    padding: rem-calc(15);
    max-width: rem-calc(550);

    $button-clearance: rem-calc(20);

    .review-button {
        background-color: white;
        padding: rem-calc(10 15);

        position: absolute;
        top: 50%;

        &.left {
            right: 100%;
            transform: translate(-$button-clearance, -50%);
        }

        &.right {
            left: 100%;
            transform: translate($button-clearance, -50%);
        }

        &:hover {
            svg {
                fill: var(--color-grayscale-6);
            }
        }

        svg {
            fill: var(--color-grayscale-2);
        }
    }

    //////// CONTENT ////////

    header {
        display: flex;
        border-bottom: 1px solid #DDDDDD;
        margin-right: rem-calc(30);
        margin-bottom: rem-calc(15);

        .rating {
            width: 35%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: rem-calc(8);
        }

        .personal {
            width: 65%;
        }

        .score {
            font-size: rem-calc(32);
            font-weight: bold;
            margin: rem-calc(0);
            text-align: center;
        }

        .person {
            .name {
                font-size: rem-calc(16);
                font-weight: 300;

            }
        }
    }

    .space {
        margin-bottom: 20px;
    }
}
