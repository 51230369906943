
.account {

}

.dashboard-orders.empty {

    @include centered-flex-column();

    .content-block--title {
        margin-bottom: 0;
        @include centered-flex-column();
        @include sized-svg(32) {
            margin-bottom: 1rem;
        }
    }

    .button-container {
        margin-top: 1.5rem;
    }
}
