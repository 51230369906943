#shopping-list-notification {
    position: fixed;
    height: 0;
    width: 100%;

    @include z-index(shopping-list-notification);

    .shopping-list-notification-column {
        position: relative;
    }

    &--inner {
        @include modal-box-shadow;
        position: absolute;
        top: $grid-column-gutter / 2;
        right: $grid-column-gutter / 2;
        padding: 1rem;
        pointer-events: none;
        background-color: var(--color-white);
        width: calc(100% - #{$grid-column-gutter});
        max-width: rem-calc(380);
        opacity: 0;
        transform: scale(0.8,0.8);
        transition: opacity .1s ease-out, transform .1s ease-out;

        &.active {
            transform: none;
            // http://cubic-bezier.com/#0,1.35,.69,1.11
            transition: opacity .2s ease-out, transform .2s cubic-bezier(0,1.35,.69,1.11);
            pointer-events: all;
            opacity: 1;
        }

        &.dragging {
            transition: none;
        }

        .close-button {
            position: absolute;
            top: rem-calc(11);
            right: rem-calc(11);
            background-color: rgba(black, 0.1);
            border: none;
            width: rem-calc(30);
            height: rem-calc(30);
            margin: 0;
            padding: 0;
            @include centered-flex-row;

            @include sized-svg(16) {
                fill: white;
            }
        }

        .notification-heading {
            @include centered-flex-row();
            font-size: rem-calc(14);
            font-weight: bold;
            padding: 1rem;
            margin: -1rem -1rem 1rem;
            color: white;

            svg {
                fill: white;
                margin-right: 1rem;
                width: rem-calc(14);
                height: rem-calc(14);
            }

            &.success {
                background-color: var(--color-green);
            }

            &.warning {
                background-color: var(--color-orange);
            }

            &.error {
                background-color: var(--color-red);
            }
        }

        .notification-message {
            font-size: rem-calc(14);
            color: var(--color-grayscale-7);
            margin-bottom: 1rem;

            b {
                color: var(--color-grayscale-9);
                font-weight: bold;
            }
        }

        .separator {
            margin: 0;
        }

        .shopping-list-link {
            margin-top: 1rem;
        }

        footer.shopping-list {
            display: flex;
            flex-direction: column;
            align-items: center;
            min-width: 100%;
            margin-left: -15px;
            margin-right: -15px;
            margin-bottom: -15px;
            padding: 15px;
            background-color: var(--color-grayscale-2);
            gap: rem-calc(12);

            .notification__button {
                width: 100%;
                height: rem-calc(40);
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .continue__button {
                background-color: var(--color-white);
                border: 1px solid var(--color-grayscale-3);
                color: var(--color-grayscale-9);
                box-shadow: inset 0 -1px 0 0 var(--color-grayscale-3);

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
