.account-credentials {

    &--gender-radio-buttons {
        @include centered-flex-row(flex-start);
        margin-bottom: 1rem;

        .custom-knob-control {
            margin-right: 1rem;
        }
    }

    .small-legend {
        float: none;
        display: block;
        margin: 0 0 1rem;
        padding: 0;

        font-size: rem-calc(24);
        line-height: rem-calc(40);
    }
}
