.info-icon {
    --ii-fill: #{var(--color-grayscale-6)};
    --ii-hover-fill: #{var(--color-grayscale-9)};
    --ii-display: inline-block;
    --ii-tooltip-min-width: 220px;

    position: relative;
    display: var(--ii-display);

    @include square(16);
    @include sized-svg(16) {
        display: block;
        fill: var(--ii-fill);
        transition: fill 150ms ease-out;
    }

    &:hover {
        > svg {
            fill: var(--ii-hover-fill);
        }

        .info-icon--tooltip {
            opacity: 1;
        }
    }

    &--tooltip {
        opacity: 0;
        pointer-events: none;
        font-size: rem-calc(13);
        line-height: 1.6;
        position: absolute;
        top: 50%;
        left: 100%;
        z-index: 1;
        transform: translateY(-50%);

        transition: opacity 150ms ease-out;
        min-width: var(--ii-tooltip-min-width);
        padding: rem-calc(10 20);
        border-radius: rem-calc(3);
        border: rem-calc(1) solid var(--color-grayscale-9);
        background-color: var(--color-grayscale-9);
        color: white;

        margin-left: 1rem;

        @include absolute-block-pseudo(before, $parent-position: false) {
            border-radius: rem-calc(3);
            border-left: rem-calc(1) solid var(--color-grayscale-9);
            border-bottom: rem-calc(1) solid var(--color-grayscale-9);
            background-color: var(--color-grayscale-9);

            @include square(16);
            top: 50%;
            left: 0;
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &, &::before {
            //box-shadow: -3px 8px 3px 0 rgba(black, 0.2);
        }
    }

    &.place-top-right &--tooltip {
        top: auto;
        bottom: 100%;
        transform: none;
        right: rem-calc(-100);
        left: auto;
        margin-left: 0;
        margin-bottom: 1rem;

        @include breakpoint(large) {
            right: rem-calc(-14);
        }

        &::before {
            transform: translateY(50%) rotate(-45deg);
            top: auto;
            left: 47%;
            bottom: 0;
            right: 12px;

            @include breakpoint(large) {
                left: auto;
            }
        }
    }

    &.align-bottom &--tooltip {
        top: auto;
        bottom: -14px;
        transform: none;

        &::before {
            top: auto;
            bottom: 5px;
        }
    }
}
