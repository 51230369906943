.eekhoornexpo-index{
  .with-quote{
    display: flex;

    div{
      width: calc(50% - 23px);
    }

    .quote{
      display: flex;
      line-height: rem-calc(34);
      margin-right: rem-calc(46);

      p{
        font-size: rem-calc(19);
      }
    }

    .quote__sign{
      font-family: serif;
      font-size: rem-calc(26);
      line-height: rem-calc(34);
    }
  }
}

@media screen and (max-width: 684px){
  .eekhoornexpo-index{
    margin-top: rem-calc(15);

    .dropdown-sidebar-navigation{
      margin-bottom: rem-calc(20);
    }

    .with-quote{
      flex-direction: column;

      div{
        width: 100%;
        margin-right: 0;
      }
    }
  }
}

