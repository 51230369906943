.recommendations-list {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: none;
    transition: transform 0.4s;
    overflow-x: hidden;

    .recommendation {
        display: grid;
        grid-template-rows: auto 1fr auto;
        grid-gap: 10px;
        grid-template-areas:
            'image'
            'name'
            'price-and-action';

        @include breakpoint(medium down) {
            grid-template-columns: auto 1fr;
            grid-template-rows: auto;
            grid-template-areas:
                'image  name'
                'image  price-and-action';
            padding: 1rem 0;
            max-width: rem-calc(600);
            grid-gap: 1rem;

            &:not(:first-child) {
                border-top: 1px solid var(--color-grayscale-2);
            }
        }


        &--container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
        }

        &--image-container {
            grid-area: image;
            width: 100%;

            @include breakpoint(large) {
                @include centered-flex-row();
            }
        }

        &--image-square {
            width: 100%;
            max-width: rem-calc(160);
            display: block;
            position: relative;

            @include breakpoint(medium down) {
                @include square(96);
            }

            &::before {
                display: block;
                content: '';
                width: 100%;
                padding-top: 100%;
            }
        }

        &--image {
            display: block;
            @include absolute-overlay;
            object-fit: contain;
            object-position: center;
        }

        &--name {
            grid-area: name;
            width: 100%;

            @include breakpoint(medium down) {
                align-self: end;
                font-size: 13px;
            }
        }

        &--price-and-action {
            grid-area: price-and-action;
            width: 100%;
            @include centered-flex-row(space-between);

            @include breakpoint(medium down) {
                align-self: start;
            }
        }

        &--price {
            --pd-price-font-size: 20px;

            .price-display, .price-display--sale {
                margin: 0;
            }

            .price-display--strike-through {
                margin: 0 rem-calc(8) 0 0
            }
        }
    }

    .price-display--default {
        margin: unset;
    }

    button[data-reveal-id="notify-me-modal"] {
        width: 80px !important;
    }
}

.swiper-container {
    .recommendations-list {
        overflow-x: unset;
    }
    .swiper-slide {
        .recommendation {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
    }
}


