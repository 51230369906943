.price-display {
    --pd-price-font-size: inherit;
    --pd-unit-font-size: inherit;
    --pd-flex-justify: center;
    --pd-text-color: #{var(--color-grayscale-9)};
    --pd-components-margin: 0.5rem;
    --pd-strike-through-weight: 1px;

    display: flex;
    justify-content: var(--pd-flex-justify);
    align-items: baseline;

    line-height: rem-calc(26);
    text-align: center;
    margin: 0 calc(-1 * var(--pd-components-margin));

    &.bold {
        font-weight: 600;
    }

    &--compound {
        @include centered-flex-row;
    }

    &.vertical .price-display--compound {
        @include centered-flex-column;
    }


    &--strike-through,
    &--sale,
    &--default,
    &--unit
    {
        display: block;
        line-height: 1em;
        text-align: inherit;
        white-space: nowrap;
        margin: 0 var(--pd-components-margin);
    }

    &--strike-through,
    &--sale,
    &--default {
        font-size: var(--pd-price-font-size);
    }

    &--unit {
        font-size: var(--pd-unit-font-size);
    }

    &--strike-through {
        color: var(--pd-text-color);

        @include absolute-block-pseudo('before') {
            top: 50%;
            left: 50%;
            transform: translateX(-50%) rotate(-25deg);
            width: 100%;
            height: var(--pd-strike-through-weight);
            background-color: var(--pd-text-color);
        }
    }

    &--sold-out {
        display: flex;
        align-items: center;
        gap: 8px;

        .icon-wrapper {
            width: 16px;
            height: 18px;

            svg {
                fill: var(--color-red);
                width: 100%;
                height: 100%;
            }
        }
    }

    &--sold-out,
    &--sale {
        font-weight: bold;
        color: var(--color-red);
    }

    &--unit {
        margin-left: 0;
        color: var(--pd-text-color);
    }
}
