/* Swiper Container */
.swiper-container {
    position: relative;
    overflow: hidden;
}

.swiper-container.fade-effect {
    &::before,
    &::after {
        content: '';
        display: block;
        position: absolute;
        z-index: 65;
        inset-block: 0;
        width: 50px;
        opacity: 1;
        transition: opacity 0.3s ease;
        will-change: opacity;
        pointer-events: none;
    }

    &::before {
        left: 0;
        background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    }

    &::after {
        right: 0;
        background: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    }

    &:has(.swiper-slide-active:first-child)::before,
    &:has(.swiper-slide-active:last-child)::after {
        opacity: 0;
    }

    &.is-beginning::before,
    &.is-end::after {
        opacity: 0;
    }
}

.swiper-wrapper {
    box-sizing: inherit;
}

/* Navigation Button Styling */
.swiper-button-next,
.swiper-button-prev {
    opacity: unset;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid #CCCCCC;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 11;

    svg {
        width: 20px;
        height: 20px;
        fill: var(--color-grayscale-9);
    }

    &::after {
        display: none;
    }

    @include breakpoint(small only) {
        display: none;
    }

    &.swiper-button-disabled {
        display: none;
    }
}

.swiper-button-next {
    right: 16px;
}

.swiper-button-prev {
    left: 16px;
}

/* Pagination Styles */
.swiper-pagination {
    display: none;

    @include breakpoint(small only) {
        display: block;
    }

    .swiper-pagination-bullet {
        background: var(--color-grayscale-2);
        opacity: 0.5;
        transition: background 0.3s, opacity 0.3s;
    }

    .swiper-pagination-bullet-active {
        background: var(--color-grayscale-9);
        opacity: 1;
    }
}

/* Thumbnails Styles */
.thumbnails-container {
    width: 100%;
    height: 100%;

    @include breakpoint(small only) {
        display: none;
    }

    .swiper-wrapper {
        padding-left: 20px;
    }

    .swiper-scrollbar {
        right: unset;
    }

    .swiper-slide {
        width: 100%;
        height: auto;
        cursor: pointer;
        border: 1px solid var(--color-grayscale-2);
        border-radius: 4px;
        padding: 4px;
        background-color: white;
        transition: border 200ms ease-out;

        &:hover {
            border-color: var(--color-grayscale-6);
        }

        &.swiper-slide-thumb-active {
            box-shadow: inset 0 -1px 0 1px var(--color-grayscale-9);
            border: solid 1px var(--color-grayscale-9);
        }

        img {
            border-radius: 2px;
            transform: translate3d(0, 0, 0);
            width: 100%;
            height: auto;
            display: block;
        }
    }
}

/* Main Slider Styles */
.swiper-with-thumbnails {
    height: 600px;
    position: relative;

    @include breakpoint(small only) {
        width: 100%;
        height: auto;
    }

    .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        img, video {
            width: 100%;
            height: auto;
            object-fit: cover;
            object-position: center;
            max-height: 600px;
        }

        .swiper-zoom-container {
            width: 100%;
            height: 100%;
        }
    }
}

/* Category Slider Specific Styles */
.category-slider {
    .swiper-slide {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .category-slider--item-image {
            margin-bottom: 10px;

            img {
                width: 100%;
                height: auto;
                border-radius: 4px;
            }
        }

        .category-slider--item-text {
            font-size: 16px;
            color: var(--color-black);
        }
    }
}

/* Swiper with Fade Effect */
.swiper-fade-effect {
    .swiper-slide {
        transition: opacity 0.5s ease;
    }
}

/* Breakpoints */
@include breakpoint(small only) {
    .swiper-pagination {
        display: block;
    }

    .thumbnails-container {
        display: none;
    }

    .swiper-with-thumbnails {
        width: 100%;
        height: auto;
    }
}