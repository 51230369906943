#newsletter-sign-up-modal {

    max-width: rem-calc(500);
    position: fixed;
    min-height: 0;
    top: rem-calc(150) !important;
    right: 0;

    legend,
    label {
        line-height: rem-calc(37);

        &.align-right {
            text-align: right;

            @include breakpoint(small only) {
                text-align: left;
            }
        }
    }

    input {
        margin: 0;
    }

    .input-wrapper {
        margin-bottom: 1rem;
    }
}
