.order-delivery-status {

    padding: rem-calc(20 50 50);
    background-color: var(--color-grayscale-1);
    border-radius: 4px;

    @include centered-flex-row;

    --light-color: #{var(--color-grayscale-3)};
    --dark-color: #{var(--color-grayscale-9)};

    .ods {

        &--line {
            width: rem-calc(100);
            height: rem-calc(2);
            background-color: var(--light-color);

            &.dark {
                background-color: var(--dark-color);
            }
        }

        &--bullet {
            flex: none;
            margin: 5px;
            box-sizing: content-box;
            @include square(40);
            @include centered-flex-row;
            @include sized-svg(20) {
                fill: var(--bullet-svg-color);
            }

            position: relative;
            border-radius: 50%;
            background-color: var(--bullet-background-color);

            --bullet-background-color: var(--light-color);
            --bullet-svg-color: var(--dark-color);

            &.dark {
                --bullet-background-color: var(--dark-color);
                --bullet-svg-color: white;
            }

            &--description {
                white-space: nowrap;
                position: absolute;
                top: calc(100% + 10px);
                font-size: rem-calc(13);
                font-weight: 600;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
}
