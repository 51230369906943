.input-wrapper {
    $input-height: rem-calc(40) !default;
    $input-background-color: var(--color-white) !default;
    $input-text-padding: rem-calc(8) !default;
    $input-bottom-margin: 1rem !default;
    $input-font-size: 1rem !default;

    position: relative;
    margin-bottom: $input-bottom-margin;

    // ------------------------------------------------------------------------------
    // Child elements
    // ------------------------------------------------------------------------------

    & &--label,
    & &--input,
    & &--select {
        display: block;
        width: 100%;
        background-color: $input-background-color;

        font-size: $input-font-size;
        font-family: inherit;
        height: $input-height;
        line-height: $input-height;
    }

    & &--label {
        user-select: none;
        line-height: $input-height;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: default;

        .required-abbreviation {
            display: inline-block;
            border: none;
            font-weight: 800;
            color: var(--color-grayscale-6);
            text-decoration: none;
        }
    }

    & &--input,
    & &--select,
    & &--text-area {
        box-shadow: none;
        border: 1px solid var(--color-grayscale-5);
        border-radius: 4px;
        background-color: var(--color-white);
        font-family: inherit;
        color: var(--color-grayscale-9);
        padding: 0 $input-text-padding;

        transition: border-color .1s ease-out,
        box-shadow .1s ease-out;

        @include placeholder-styles;

        &:focus {
            background-color: white;
            @include focus-style-through-border();
        }
    }


    & &--input {
        height: $input-height;
        line-height: $input-height - rem-calc(2);
    }

    //noinspection CssOptimizeSimilarProperties
    & &--select {
        -webkit-appearance: none !important;
        -moz-appearance: none !important;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeD0iMTJweCIgeT0iMHB4IiB3aWR0aD0iMjRweCIgaGVpZ2h0PSIzcHgiIHZpZXdCb3g9IjAgMCA2IDMiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDYgMyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBvbHlnb24gcG9pbnRzPSI1Ljk5MiwwIDIuOTkyLDMgLTAuMDA4LDAgIi8+PC9zdmc+");
        background-position: 100% center;
        background-repeat: no-repeat;
    }

    & &--text-area {
        width: 100%;
        resize: vertical;
        font-size: $input-font-size;
        padding: $input-text-padding;
        min-height: rem-calc(160);
    }

    // ------------------------------------------------------------------------------
    //      Error container
    // ------------------------------------------------------------------------------

    $svg-size: rem-calc(16);
    $svg-container-margin: 0.5 * ($input-height - $svg-size);
    $balloon-background-color: var(--color-red);
    $balloon-text-color: var(--color-white);

    & &--error {
        position: relative;
        display: block;
        width: 100%;
        padding: unset;

        &--required {
            display: none;
        }

        &--hover-area {
            @include centered-flex-row();

            width: $input-height - rem-calc(2);
            height: $input-height - rem-calc(2);
            margin: rem-calc(1);
            position: absolute;
            bottom: 100%;
            right: 0;

            svg {
                transition: transform .1s ease-out;
                display: block;
                width: $svg-size;
                height: $svg-size;
                fill: var(--color-red);
            }

            &:focus {
                outline: none;
            }
        }

        animation: pulse 2s infinite;

        &--balloon {
            position: absolute;
            bottom: calc(100% + #{$input-height});
            right: 0;
            max-width: 100%;
            margin-bottom: rem-calc(10);
            opacity: 0;
            pointer-events: none;
            transition: opacity var(--transition-medium-timing);

            @include tooltip-box-shadow;

            @include absolute-block-pseudo('before', $parent-position: false) {
                @include tooltip-box-shadow;

                z-index: 0;
                width: rem-calc(8);
                height: rem-calc(8);
                transform: translateY(#{rem-calc(-4)}) rotate(45deg);
                top: 100%;
                right: (0.5 * $input-height) - rem-calc(5);
                background-color: $balloon-background-color;
            }
        }

        &--content {
            position: relative;
            z-index: 1;
            display: block;
            padding: rem-calc(5 10);

            background-color: $balloon-background-color;
            color: $balloon-text-color;
            font-size: rem-calc(12);
            line-height: rem-calc(16);
            font-weight: 600;
        }

        &:hover {
            .input-wrapper--error--hover-area svg {
                transform: scale(1.2);
            }

            .input-wrapper--error--balloon {
                opacity: 1;
                pointer-events: all;
            }
        }
    }

    // ------------------------------------------------------------------------------
    //      State
    // ------------------------------------------------------------------------------

    &.invalid {
        .input-wrapper--error {
            display: block !important;
        }

        .input-wrapper--input,
        .input-wrapper--text-area,
        .input-wrapper--select
        {
            padding: 0 ($input-height + $input-text-padding) 0 $input-text-padding;

            &:not(:focus) {
                border-color: var(--color-red);
            }
        }
    }

    &--required  {
        .show {
            display: block !important;
        }

        .input-wrapper--error, .password-reset--error {
            padding: unset !important;
            margin-top: unset !important;
            margin-bottom: unset !important;
        }
    }
}
