.modal { // <dialog>
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include z-index(modal);
    background: none;
    border: none;

    &:not(.active) {
        display: none;
    }

    @include centered-flex-row();

    &--mask {
        @include absolute-overlay();
        background-color: var(--color-modal-mask);
        backdrop-filter: blur(3px);
        -webkit-backdrop-filter: blur(3px);
    }

    &--content {
        @include modal-box-shadow;

        width: rem-calc(700);
        max-height: calc(100% - 2rem);
        overflow-y: auto;
        padding: 2rem;
        background-color: white;
        position: relative;
    }

    $close-button-size: rem-calc(40);
    $close-button-icon-size: rem-calc(15);

    &--close {
        @include centered-flex-row();
        position: absolute;
        top: 0;
        right: 0;
        width: $close-button-size;
        height: $close-button-size;
        background-color: var(--color-grayscale-1);
        line-height: 1;
        z-index: 1;

        svg {
            width: $close-button-icon-size;
            height: $close-button-icon-size;
            fill: var(--color-grayscale-6);
        }

        &:hover,
        &:focus {
            text-decoration: none;
            background-color: var(--color-grayscale-2);

            svg {
                fill: var(--color-grayscale-9);
            }
        }
    }

    &--footer {
        @include centered-flex-row(flex-end);
        padding: 1rem 2rem;
        margin: 2rem -2rem -2rem;
        background-color: var(--color-grayscale-2);

        .button.button {
            padding-left: rem-calc(40);
            padding-right: rem-calc(40);
            margin: 0;
        }
    }
}
