.newsletter-subscription-page,
.newsletter-subscription-success-page {
    text-align: center;

    @include breakpoint(large) {
        margin-top: 20px;
    }

    .content-wrapper {
        margin: 0 auto;
    }

    @include breakpoint(medium down) {
        h1 {
            font-size: 26px !important;
        }

        h2 {
            font-size: 22px !important;
        }
    }

    .description {
        margin-bottom: 32px;

        @include breakpoint(large) {
            margin-bottom: 40px;
        }
    }
}

.newsletter-subscription-page {
    #newsletter-subscription-form {
        border: 1px solid var(--color-grayscale-2);
        padding: 24px;
        text-align: left;
        margin-bottom: 32px;

        @include breakpoint(large) {
            padding: 32px 40px;
            margin-bottom: 80px;
            width: 676px;
        }
    }

    .inputs,
    .text-inputs {
        width: 100%;
    }

    .inputs,
    .text-inputs {
        display: flex;
        flex-direction: column;
        gap: 8px;

        @include breakpoint(large) {
            flex-direction: row;
        }
    }

    input[type=email] {
        min-width: 260px;
    }
}

.newsletter-subscription-flow {
    background-color: var(--color-grayscale-1);

    @include breakpoint(large) {
        padding-top: 40px;
    }
}
