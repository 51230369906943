#site-footer-newsletter-form {
    @include centered-flex-row(space-between, flex-start);

    .input-wrapper {
        flex: 1 1 0;
        margin-bottom: 0;

        &--label {
            display: none;
        }

        &--input {
            &:focus {
                background-color: white;
                box-shadow: 0 0 0 3px var(--color-grayscale-2);
            }

            padding: 0 1rem;
            border: none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .button {
        flex: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .grecaptcha-badge {
        display: none;
    }

    @include breakpoint(medium down) {
        display: block;
        .button {
            width: 100%;
            margin-top: 10px;
            border-radius: rem-calc(4);
        }
    }
}
