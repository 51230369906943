.base-modal-container {

    // Titles
    h3 {
        margin-bottom: 2rem;
    }
}

#small-success-modal {
    max-width: rem-calc(500);
}
