#gift-card-balance-modal {
    .modal--content {
        max-width: rem-calc(440);
        width: 100%;
        overflow-y: unset;
    }

    .gbm {

        &--top {
            @include centered-flex-column();
        }

        &--heading {
            font-size: rem-calc(26);
            margin-bottom: 2rem;

            @include breakpoint(medium down) {
                font-size: rem-calc(22);

            }
        }

        &--image {
            display: block;
            margin-top: 2rem;
            margin-bottom: rem-calc(20);
            width: rem-calc(68);
            height: rem-calc(96);
            object-fit: contain;
            object-position: center;
            box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.15);
        }

        &--current-balance-heading {
            text-align: center;
            font-weight: bold;
            color: var(--color-grayscale-9);
            font-size: rem-calc(13);
            margin-top: 1rem;
        }

         &--current-balance {
            font-size: rem-calc(26);
            font-weight: bold;
            color: var(--color-grayscale-9);
        }

        &--code {
            color: var(--color-grayscale-9);
            margin-top: rem-calc(8);
        }

        &--transactions {
            &--heading {
                font-size: rem-calc(17);
                font-weight: 600;
                margin: 2rem 0 1rem;
                color: var(--color-grayscale-9);
            }

            &--list {

            }
        }

        &--transaction {

            padding-left: rem-calc(24);
            padding-bottom: rem-calc(24);
            display: grid;
            grid-template-areas: 'type amount'
                                 'date date';

            @include absolute-block-pseudo(before) {
                @include square(12);
                top: rem-calc(5);
                left: 0;
                border-radius: 50%;
                background-color: var(--color-grayscale-5);
            }

            &:not(:last-child) {
                @include absolute-block-pseudo(after) {
                    top: 19px;
                    bottom: -3px;
                    left: 5px;
                    width: 2px;
                    background-color: var(--color-grayscale-2);
                }
            }

            &:first-child::before {
                background-color: var(--color-grayscale-9);
            }

            &--date {
                grid-area: date;
                font-size: rem-calc(13);
                color: #838383;
            }

            &--type {
                grid-area: type;
                font-weight: 600;
                font-size: rem-calc(17);
                @include centered-flex-row(flex-start);
                color: var(--color-grayscale-9);

                .info-icon {
                    margin-left: rem-calc(8);
                }
            }

            &--amount {
                grid-area: amount;
                font-weight: 600;
                font-size: rem-calc(17);
                text-align: right;
                color: var(--color-grayscale-9);
            }
        }

        &--initial-state {
            width: 100%;
            display: grid;
            grid-template-areas: 'type amount'
                                 'date date';

            &--date {
                grid-area: date;
                font-size: rem-calc(13);
                color: #838383;
            }

            &--type {
                grid-area: type;
                font-weight: 600;
                font-size: rem-calc(17);
                @include centered-flex-row(flex-start);
                color: var(--color-grayscale-9);
            }

            &--amount {
                grid-area: amount;
                font-weight: 600;
                font-size: rem-calc(17);
                text-align: right;
                color: var(--color-grayscale-9);
            }
        }
    }

    .date-time-separator {
        color: var(--color-grayscale-5);
        display: inline-block;
        margin: rem-calc(0 2);
    }

    .modal--footer{
        padding-top: rem-calc(28);
        padding-bottom: rem-calc(28);
    }
}
